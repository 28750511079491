import React, { useEffect, useState } from "react";
import { AlertDialogSlide, CategorySection, ContentCompSection, Input, Layout, LayoutHeader, ReminderComp } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { optionsData } from "../utils/_dataset";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty, orderBy } from "lodash";
import YouTube from 'react-youtube';
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { Colors } from "../utils/_constant";
import { PrimaryButton } from "../components/_button";
import moment from "moment";
import { useSelector } from "react-redux";

const FeedbackSection = (props) => {

    const navigate = useNavigate();

    const [loader, setloader] = useState(false);

    const alert = useAlert()
    const [feedback, setfeedback] = useState('');

    const user = useSelector(state => state.user)


    const handleSubmit = async () => {
        if (feedback == '') {
            alert.show("Feedback should not empty", {
                type: 'error'
            })
            return;
        }
        setloader(true)


        var _dataset = {
            to_email: 'drkhalifeh@gmail.com',
            body: feedback,
            subject: `Feedback by ${user?.shape?.email}`
        }

        try {
            const composeEmail = await _call('api/v1/send-email/', 'POST', _dataset);
            setloader(false);
            alert.show("Sent Successfully", {
                type: 'success'
            });
            setfeedback('');
        } catch (err) {

            setloader(false);
            alert.show('Some problem occured', {
                type: 'error'
            });
        }
    }


    return <Layout>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 overflow-hidden px-4 lg:px-0">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                    <LayoutHeader title="Feedback" onBack={() => navigate(-1)} />

                    <div className="grid grid-cols-1">
                        <textarea className="w-full min-h-[50vh] p-3 mt-5" placeholder="Feedback" value={feedback} onChange={e => setfeedback(e.target.value)}></textarea>

                        <PrimaryButton className="mt-4" onClick={handleSubmit} loading={loader} title="Submit" />
                    </div>


                </div>
            </div>
        </div>

    </Layout>
}





export default FeedbackSection;