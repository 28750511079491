import React, { useEffect, useState } from "react";
import { CategorySection, ContentCompSection, Input, Layout, LayoutHeader } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { useFormik } from "formik";
import { _initialInformationSection, diagnosisOptionData, genderData, heightData, optionsData, weightData } from "../utils/_dataset";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty, orderBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Colors } from "../utils/_constant";
import UserSlices from '../utils/slices/_user.slices'
import DiagnosisValidation from "../utils/validator/_diagnosis";
import { PrimaryButton } from "../components/_button";
import { useAlert } from "react-alert";
import moment from 'moment'

const DiagnosisSection = (props) => {

    const dispatch = useDispatch();

    const [mode, setmode] = useState(0);
    const [loader, setloader] = useState(false);
    const [diagonsischeck, setdiagnosischeck] = useState([]);
    const [paincategory, setpaincategory] = useState([]);

    const [painId, setpainId] = useState(0);

    const [generalquestions, setgeneralquestions] = useState([]);
    const [qnadataset, setqnadataset] = useState([]);
    const [selecttoggle, setselecttoggle] = useState([]);
    const [qnainfo, setqnainfo] = useState({
        index: 0,
        length: 0
    })
    const [selectedqna, setselectedqna] = useState([]);
    const [informationsection, setinformationsection] = useState(_initialInformationSection);
    const [allowNext, setallowNext] = useState(false);
    const [result, setresult] = useState([]);

    const navigate = useNavigate();
    const user = useSelector(state => state.user);

    const alert = useAlert()

    const userState = user?.shape;

    const __action = UserSlices.action;

    useEffect(() => {
        handleData();
    }, [])

    const handleData = async () => {

        try {
            const _getpaincategory = await _call('api/v1/pain-category/', 'GET');

            const _data = _getpaincategory.data.map(item => {
                return {
                    ...item,
                    text: item.category_name,
                    value: item.category_name,
                    label: item.category_detail
                }
            })
            setpaincategory(_data);
        } catch (err) {

        }
    }


    const handleSave = (values) => {
        const { first_name, last_name, dob, gender, weight, height } = values;
        var informationsectionclone = { ...informationsection };
        informationsectionclone['first_name'] = first_name;
        informationsectionclone['last_name'] = last_name;
        informationsectionclone['date_of_birth'] = dob;
        informationsectionclone['gender'] = gender;
        informationsectionclone['weight'] = weight;
        informationsectionclone['height'] = height;

        setinformationsection(informationsectionclone);
        handleChangeMode();
    }


    const handleChangeMode = (val) => {

        if (mode == 0 && diagonsischeck?.id == 0 && userState?.clientprofile?.dob && userState?.clientprofile?.gender && userState?.clientprofile?.height && userState?.clientprofile?.weight) {
            setmode(2);
            setinformationsection({
                first_name: userState?.first_name,
                last_name: userState?.last_name,
                date_of_birth: userState?.clientprofile?.dob,
                gender: userState?.clientprofile?.gender,
                weight: userState?.clientprofile?.weight,
                height: userState?.clientprofile?.height,
            });
            return;
        }



        if (mode == 2 && !allowNext) {
            return;
        }

        if (selecttoggle?.dataset && selecttoggle?.dataset?.has_situation) {
            // console.log(informationsection)
            // return;
            navigate('/diagnosis-picture"', {
                state: {
                    data: selecttoggle?.dataset,
                    informationsection,
                    diagnosisCheckId: diagonsischeck?.id
                }
            });
            return;
        }

        setmode(mode + 1);

    }



    const handleGetQna = async (val, item = {}) => {

        try {
            setselecttoggle({
                state: false,
                data: val,
                dataset: item
            });
            if (item && item?.has_situation) {
                // console.log({
                //     data: item,
                //     informationsection,
                //     diagonsischeck
                // })
                // return;
                navigate('/diagnosis-picture', {
                    state: {
                        data: item,
                        informationsection,
                        diagnosisCheckId: diagonsischeck?.id
                    }
                });
                return
            }


            setmode(mode + 1)
            const _pid = paincategory.find(x => x.category_name == val)?.id;
            const _getqna = await _call(`api/v1/question/?pain_category=${_pid}`, 'GET');
            const _getgeneralqna = await _call(`api/v1/general-question/?pain_category=${_pid}`, 'GET');
            setpainId(_pid)
            setgeneralquestions(_getgeneralqna.data.map(item => {
                return {
                    ...item,
                    answer: ''
                }
            }));
            setqnadataset(_getqna.data);
            setqnainfo({
                index: 0,
                length: _getqna.data.length - 1
            });
            setallowNext(true);
        } catch (err) {
        }

    }

    const handleSubmit = async () => {
        const selectedpainId = painId;
        var particularshape = [];
        var generalshape = [];
        selectedqna.map(item => {
            if (item.answers.length > 0) {
                item.answers.map(data => {
                    particularshape.push({
                        question: item.question,
                        answers: data
                    })
                })
            } else {
                particularshape.push({
                    question: item.question,
                    answers: item.answers[0]
                })
            }
        });

        generalquestions.map(item => {
            generalshape.push({
                question: item.id,
                answers: item.answer
            })
        })

        const dataset = {
            diagonose_answer: particularshape,
            general_diagonose_answer: generalshape,
        }

        const _data = {
            first_name: informationsection.first_name,
            last_name: informationsection.last_name,
            dob: moment(informationsection.date_of_birth).format('YYYY-MM-DD'),
            weight: informationsection.weight,
            height: informationsection.height,
            gender: informationsection.gender != "Male" ? 0 : 1,
            diagnosis_for: diagonsischeck.id,
            pain_category: selectedpainId
        }

        const _userInfo = {
            first_name: informationsection.first_name,
            last_name: informationsection.last_name,
            clientprofile: {
                dob: moment(informationsection.date_of_birth).format('YYYY-MM-DD'),
                weight: informationsection.weight,
                height: informationsection.height,
                gender: informationsection.gender != "Male" ? 0 : 1,
            }
        }

        try {
            setloader(true);
            if (diagonsischeck?.id == 0) {
                const _updateProfileInfo = await _call('rest-auth/user/', 'PATCH', _userInfo);
                dispatch(__action.onEdittedData(_updateProfileInfo.data))
            }

            const _creatediagnosis = await _call('api/v1/diagnose/', 'POST', _data);
            const _add = await _call('api/v1/diagnose-condition/', 'POST', { ...dataset, diagnose: _creatediagnosis.data.id });
            alert.show("Diagnosis Created Successfully", {
                type: 'success'
            })
            navigate('/diagnosis-result', {
                state: {
                    condition: _add.data,
                    result: result,
                    data: _creatediagnosis.data
                }
            })
            setloader(false);
        } catch (err) {
            setloader(false);
        }
    }


    const handleSetQnaSet = (item) => {

        if (!isEmpty(qnadataset[qnainfo?.index]?.answers)) {
            var selectedqnaclone = [...selectedqna];
            var selectedresultclone = [...result];

            var answersl = selectedqnaclone[qnainfo.index]?.answers ? selectedqnaclone[qnainfo.index]?.answers : [];

            if (qnadataset[qnainfo.index].allow_multi_select && answersl) {
                if (answersl.includes(item.id)) {
                    answersl = answersl.filter(x => x != item.id)
                } else {
                    answersl.push(item.id);
                }

            } else {
                answersl = [item.id];
            }
            selectedqnaclone[qnainfo?.index] = {
                question: qnadataset[qnainfo?.index]?.id,
                answers: answersl
            }
            selectedresultclone[qnainfo?.index] = {
                question: qnadataset[qnainfo?.index]?.question,
                answers: item?.answer
            }
            setresult(selectedresultclone)
            setselectedqna(selectedqnaclone);

            if (!qnadataset[qnainfo.index].allow_multi_select) {
                setqnainfo({
                    ...qnainfo,
                    index: qnainfo?.index + 1,
                });
            }
            if (qnainfo?.index >= qnainfo?.length) {
                handleChangeMode()
            }
        }

    }


    const handleSetGeneralQna = async (val, item) => {
        var selectedgeneralqnaclone = [...generalquestions];
        var index = selectedgeneralqnaclone.findIndex(x => x.id == item.id);
        selectedgeneralqnaclone[index].answer = val;
        setgeneralquestions(selectedgeneralqnaclone);
    }

    return <Layout>
        <div className="grid grid-cols-12 gap-4 px-4 lg:px-0">
            <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                <LayoutHeader title="Diagnosis" onBack={() => {
                    if (mode === 0) {
                        navigate(-1)
                    } else {
                        if (mode === 3) {
                            if (qnainfo?.index > 0) {
                                setqnainfo({
                                    ...qnainfo,
                                    index: qnainfo?.index - 1
                                })
                            } else {
                                setmode(mode - 1);
                            }
                        } else {
                            setmode(mode - 1);
                        }

                    }
                }} />
                {mode == 0 && <InitiateEvent setmode={setmode} setinformationsection={setinformationsection} mode={mode} diagonsischeck={diagonsischeck} setdiagnosischeck={setdiagnosischeck} />}
                {mode == 1 && <RenderInformation handleSave={handleSave} diagonsischeck={diagonsischeck} />}
                {mode == 2 && <HelpSection data={paincategory} handleGetQna={handleGetQna} />}
                {mode == 3 && <QnaSection qnainfo={qnainfo} qnadataset={qnadataset} selectedqna={selectedqna} handleSetQnaSet={handleSetQnaSet} />}
                {mode == 4 && <GeneralQnaSection generalquestions={generalquestions} handleSetGeneralQna={handleSetGeneralQna} />}



                {((mode != 1 && mode != 2 && (mode == 3 && qnadataset[qnainfo.index] && !(qnainfo?.index >= qnainfo?.length) && qnadataset[qnainfo.index]?.allow_multi_select)) || mode == 4) && <PrimaryButton
                    disabled={mode == 0 && isEmpty(diagonsischeck)}
                    className="mt-10"
                    loading={loader}
                    onClick={() => {
                        if (mode >= 4) {
                            handleSubmit()
                        } else {
                            if (mode === 3) {
                                if (qnainfo?.index >= qnainfo?.length) {
                                    handleChangeMode()
                                } else {
                                    if (selectedqna[qnainfo?.index] || isEmpty(qnadataset[qnainfo?.index].answers)) {
                                        setqnainfo({
                                            ...qnainfo,
                                            index: qnainfo?.index + 1,
                                        });
                                    }
                                }
                            } else {
                                handleChangeMode()
                            }
                        }

                    }}
                    title="Next"
                    style={{ marginTop: -100 }}
                />}
            </div>
        </div>

    </Layout>
}

const GeneralQnaSection = ({ generalquestions, handleSetGeneralQna }) => {
    return <div className="mt-4">
        {generalquestions?.map((item, index) => <>
            <h4 className="text-xl font-bold">{index + 1}. {item.question}</h4>
            <Input value={item?.answer}
                onChangeText={val => handleSetGeneralQna(val, item)}
            />
        </>)}
    </div>
}

const QnaSection = ({ qnainfo, qnadataset, selectedqna, handleSetQnaSet }) => {
    return <div className="mt-4">
        <h4 className="text-xl font-bold">- {qnadataset[qnainfo?.index]?.question}</h4>
        {orderBy(qnadataset[qnainfo?.index]?.answers, ['sorting_number'], ['desc'])?.map(item => <div onClick={() => handleSetQnaSet(item)}
            className="bg-white cursor-pointer w-full py-2 lg:py-0 lg:h-16 rounded mt-4 flex flex-row items-center px-3"
            style={{
                backgroundColor: selectedqna[qnainfo?.index]?.answers.includes(item.id) ? '#029ED8' : '#fff',
                borderColor: selectedqna[qnainfo?.index]?.answers.includes(item.id) ? '#029ED8' : '#fff'
            }}
        >
            <div className="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 w-10 h-10 rounded-full flex flex-col items-center">
                <h4 className="text-white m-auto block font-bold text-xl">{item?.answer[0]}</h4>
            </div>
            <h4 className="text-lg lg:text-xl flex-1 ml-4" style={{ color: selectedqna[qnainfo?.index]?.answers.includes(item.id) ? '#fff' : '#878FAD', }}>{item?.answer}</h4>
        </div>)}
    </div>
}


const HelpSection = ({ data, handleGetQna }) => {
    return <div className="mt-4">
        <h4 className="text-xl font-bold">- How may I help you?</h4>
        {data?.map(item => <div onClick={() => handleGetQna(item.text, item)} className="bg-white cursor-pointer w-full lg:h-16 py-2 lg:py-0 rounded mt-4 flex flex-row items-center px-3">
            <div className="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 w-10 h-10 rounded-full flex flex-col items-center">
                <h4 className="text-white m-auto block font-bold text-xl">{item?.label[0]}</h4>
            </div>
            <h4 className="flex-1 text-sm lg:text-xl ml-4">{item?.label}</h4>
        </div>)}
    </div>
}


const RenderInformation = ({ handleSave, diagonsischeck }) => {

    const user = useSelector(state => state.user);

    const userState = user?.shape;

    const BringInitialInformation = () => {

        if (diagonsischeck?.id == 0) {
            return { ..._initialInformationSection, dob: new Date(), ...userState }
        }

        return { ..._initialInformationSection, dob: new Date() }
    }

    const formik = useFormik({
        initialValues: BringInitialInformation(),
        validationSchema: DiagnosisValidation.basic,
        onSubmit: values => handleSave(values)
    });



    const { values, touched, errors, setFieldValue, handleSubmit } = formik;

    return <div>
        <h4 className="mt-4 mb-[-5px]">First Name</h4>
        <Input placeholder="First Name" value={values?.first_name} onChange={e => setFieldValue('first_name', e.target.value)} error={(errors?.first_name && touched?.first_name) && errors?.first_name} />

        <h4 className="mt-4 mb-[-5px]">Last Name</h4>
        <Input placeholder="Last Name" value={values?.last_name} onChange={e => setFieldValue('last_name', e.target.value)} error={(errors?.last_name && touched?.last_name) && errors?.last_name} />

        <h4 className="mt-4 mb-[-5px]">Date of Birth</h4>
        <Input placeholder="Date of Birth" type="datepicker" value={values?.dob} onChange={e => setFieldValue('dob', e.target.value)} error={(errors?.dob && touched?.dob) && errors?.dob} />

        <h4 className="mt-4 mb-[-5px]">Gender</h4>
        <Input type="select" options={genderData?.map(item => item?.name)} value={values?.gender} onChange={e => setFieldValue('gender', e.target.value)} error={(errors?.gender && touched?.gender) && errors?.gender} />

        <h4 className="mt-4 mb-[-5px]">Weight</h4>
        <Input type="select" options={weightData} value={values?.weight} onChange={e => setFieldValue('weight', e.target.value)} error={(errors?.weight && touched?.weight) && errors?.weight} />

        <h4 className="mt-4 mb-[-5px]">Height</h4>
        <Input type="select" options={heightData} value={values?.height} onChange={e => setFieldValue('height', e.target.value)} error={(errors?.height && touched?.height) && errors?.firstheight_name} />

        <div className="mt-10" />
        <PrimaryButton type="submit" onClick={handleSubmit} title="Save" />
    </div>
}


const InitiateEvent = ({ setmode, mode, setdiagnosischeck, diagonsischeck, setinformationsection }) => {

    const user = useSelector(state => state.user);

    const userState = user?.shape;

    const handleDiagnosisCheck = (item) => {
        setdiagnosischeck(item)
        const genderr = userState?.clientprofile?.gender == 0 ? "Female" : "Male";
        if (mode == 0 && item?.id == 0 && userState?.clientprofile?.dob && genderr && userState?.clientprofile?.height && userState?.clientprofile?.weight) {
            setmode(2);
            setinformationsection({
                first_name: userState?.first_name,
                last_name: userState?.last_name,
                date_of_birth: userState?.clientprofile?.dob,
                gender: userState?.clientprofile?.gender,
                weight: userState?.clientprofile?.weight,
                height: userState?.clientprofile?.height,
            });
            return;
        } else {
            setmode(1)
        }
    }

    return <div className="mt-4">
        <h4 className="text-xl font-bold">- For whom do you want to check the diagnosis?</h4>
        <div className="flex flex-row items-center mt-4">
            {diagnosisOptionData?.map((item, index) => <div key={index} onClick={() => handleDiagnosisCheck(item)}
                className={`${item?.id == diagonsischeck?.id ? `bg-[${Colors.primary}]` : "bg-white"} cursor-pointer flex flex-col items-center justify-center h-28 w-28 rounded mr-5`}>
                {item?.id == diagonsischeck?.id ? item?.active : item?.icon}
                <h4 className="text-sm mt-2" style={{ color: item?.id == diagonsischeck?.id ? 'white' : '#afafaf' }}>{item?.name}</h4>
            </div>)}
        </div>
    </div>
}

export default DiagnosisSection;