import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { StyledEngineProvider } from '@mui/material';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistStore } from 'redux-persist';
import store from './utils/_store';
import { __alertOptions } from './utils/_dataset';

const root = ReactDOM.createRoot(document.getElementById('root'));
let persistor = persistStore(store)

// index.js or index.jsx

// // Feature detection for Firebase Messaging
// if ('Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window) {
//   // Initialize Firebase Messaging
// } else {
//   console.log('Messaging: This browser doesn\'t support the API\'s required to use the Firebase SDK.');
// }

// // Rest of the existing code...

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/firebase-messaging-sw.js')
//     .then((registration) => {
//       console.log('Service Worker registered with scope:', registration.scope);
//     })
//     .catch((error) => {
//       console.error('Service Worker registration failed:', error);
//     });
// }

root.render(
  <AlertProvider template={AlertTemplate} {...__alertOptions}>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </StyledEngineProvider>
    </Provider>
  </AlertProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
