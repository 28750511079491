import React, { useEffect, useState } from "react";
import { AlertDialogSlide, CategorySection, ContentCompSection, Input, Layout, LayoutHeader, ReminderComp } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { optionsData } from "../utils/_dataset";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty, orderBy } from "lodash";
import YouTube from 'react-youtube';
import { useNavigate } from "react-router-dom";
import { Colors } from "../utils/_constant";
import { PrimaryButton } from "../components/_button";



const Membership = (props) => {
    const navigate = useNavigate()
    const [membershipData, setmembershipData] = useState([]);
    const [loader, setloader] = useState(false)
    const [currentCard, setcurrentCard] = useState([]);
    const [selectedmembership, setselectedmembership] = useState([]);

    useEffect(() => {
        handleData()
        return () => { }
    }, []);

    const handleData = async () => {
        try {
            setloader(true)
            const _getMembership = await _call('api/v1/payment/get_subscription_price/', 'GET');
            const _getCardInfo = await _call('api/v1/payment/get_cards/', 'GET');
            setloader(false)
            setcurrentCard(_getCardInfo.data?.cards[0]);
            setmembershipData(_getMembership.data?.data[0]);
        } catch (err) {
            console.log(err);
            setloader(false)
        }
    }



    return <Layout loading={loader}>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 px-4 lg:px-0 overflow-hidden">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                    <LayoutHeader title="Membership" onBack={() => navigate(-1)} none />

                    <Logo className="mt-10 m-auto block" />

                    <div className={`bg-[${Colors.primary}] w-full h-44 mt-10 rounded flex flex-col justify-center items-center`}>
                        <h4 className="text-5xl text-white text-center">${membershipData.unit_amount / 100}</h4>
                        <h4 className="text-sm text-center">{membershipData.metadata?.additional_text}</h4>
                    </div>

                    <PrimaryButton title="Renew" disabled className="mt-10" />
                </div>
            </div>
        </div>

    </Layout>
}






export default Membership;