import moment from "moment";
import React from "react";

const ReminderComp = ({ onEdit, onDelete, data }) => {
    return <div className="bg-white p-3 pt-0 mt-4">
        <div className="border-b p-2 px-4 flex flex-col">
            <div className="flex flex-col items-center">
                <h4 className="text-xs">Reminder</h4>
                <p className="text-slate-500 text-md"> {moment(data.reminder_time).format('MM/DD/yyyy hh:mm a')}</p>            </div>
        </div>

        <div className="border-b p-2 px-4 flex flex-col items-start justify-start">
            <h4 className="text-xs">{data?.title}</h4>
            <p className="text-slate-500 text-md">{data.notes}</p>
        </div>

        <div className="flex flex-row items-center justify-between p-2">
            <button onClick={onEdit}>Edit</button>
            <button onClick={onDelete}>Delete</button>
        </div>
    </div>
}

export default ReminderComp