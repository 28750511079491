import { createSlice } from "@reduxjs/toolkit";
import { _apiStatus, _initialState } from "./_initial";


const _specialistAreaSlices = createSlice({
    name: "specialist_area",
    initialState: _initialState,
    reducers: {
        LoadData: state => {
            state.loadingStatus = _apiStatus.Loading;
            state.error = [];
        },
        LoadedData: (state, action) => {
            state.loadingStatus = _apiStatus.Loaded;
            state.shape = action.payload;
            state.error = [];
        },
        onFailed: (state, action) => {
            state.loadingStatus = _apiStatus.Failed;
            state.error = action.payload;
            state.success = _apiStatus.Failed;
        },
        onClean: state => {
            state.error = [];
            state.shape = [];
        }
    }
});

const SpecialistAreaSlices = {
    action: _specialistAreaSlices.actions,
    reducer: _specialistAreaSlices.reducer
}

export default SpecialistAreaSlices;