import React, { useState } from "react";
import Input from "../_input";
import { PrimaryButton } from "../_button";
import { useAlert } from "react-alert";
import { _call } from "../../utils/_service";


const PasswordForget = ({ token, onClick }) => {
    const [password, setpassword] = useState('')
    const [cpassword, setcpassword] = useState('')
    const [loader, setloader] = useState(false)

    const alert = useAlert();



    const handleChangePassword = async () => {

        if ((password != cpassword) && password == '' && cpassword == '') {
            alert.show('Password should match', {
                type: 'error'
            })
            return;
        }

        try {
            setloader(true);
            const _request = await _call('api/v1/password-reset/', 'POST', {
                token,
                new_password1: password,
                new_password2: password,
            }, {
                'Content-Type': 'application/json'
            })

            alert.show("Password Changed Successfully", {
                type: 'success'
            });
            setTimeout(() => {
                onClick()
            }, 1000);
            setloader(false);

        } catch (err) {
            setloader(false);
            alert.show("Sorry, Password is invalid", {
                type: 'error'
            });
        }

    }

    return <div className="bg-white w-full min-h-[100px] mt-10 py-6 px-16 flex flex-col items-center justify-center">
        <h4 className="font-bold text-xl">New Password</h4>
        <div className="mt-2" />
        <Input placeholder="Password" value={password} onChange={e => setpassword(e.target.value)} type="password" />
        <Input placeholder="Confirm Password" value={cpassword} onChange={e => setcpassword(e.target.value)} type="password" />
        <PrimaryButton loading={loader} onClick={handleChangePassword} title="Save Changes" className="mt-4" />
    </div>
}

export default PasswordForget;