import React, { useEffect, useState } from "react";
import { CategorySection, ContentCompSection, Input, Layout, LayoutHeader } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { optionsData, priortyOptions } from "../utils/_dataset";
import { WeeklyCalendar } from 'react-week-picker';
import 'react-week-picker/src/lib/calendar.css';
import { Chart } from "react-google-charts";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import moment from "moment";
import { PrimaryButton } from "../components/_button";

// const data = [
//     ["Year", "Sales",],
//     ["2014", 1000],
//     ["2015", 600]
// ];

const options = {
    chartType: 'Bar',
    width: '100%',
    height: '400px',
    vAxis: {
        viewWindow: {
            min: 0,
            max: 10,
        },
    },
};

const TrackSymptoms = (props) => {

    const navigate = useNavigate();
    const [loader, setloader] = useState(false);
    const [severity, setseverity] = useState(0);

    const [dataset, setDataset] = useState([])
    const [labels, setLabels] = useState([])

    const [viewCalander, setviewCalander] = useState(false)

    useEffect(() => {
        composeDataset(moment().startOf("isoWeek").format("YYYY-MM-DD"), moment().startOf("isoWeek").add(7, "days").format("YYYY-MM-DD"))
    }, [])

    const composeDataset = async (start, end) => {
        try {
            const _bringData = await _call(`api/v1/symptom-tracker/?start_date=${start}&end_date=${end}`, "GET")
            const data = _bringData.data

            const restructuredData = restructureData(start, data)


            setDataset(restructuredData.data)
            setLabels(restructuredData.labels)
        } catch (err) {
        }
    }


    const restructureData = (start, data) => {
        const startDate = moment(start).startOf("isoWeek");
        const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        const dataset = [0, 0, 0, 0, 0, 0, 0];

        data.forEach(item => {
            const itemDate = moment(item.created_date).local();
            const index = itemDate.diff(startDate, "days");
            if (index >= 0 && index < 7) {
                dataset[index] = item.intensity;
            }
        });

        return {
            data: dataset,
            labels
        };
    };

    const alert = useAlert();

    const handleSaveSeverity = async () => {
        if (severity == 0) {
            alert.show("Please select severity first", {
                type: 'error'
            })
            return;
        }
        try {
            setloader(true);

            const dataSet = {
                intensity: severity?.replace('Severity ', ''),
                created_date: new Date()
            }

            const _setSeverity = await _call('api/v1/symptom-tracker/', 'POST', dataSet)
            alert.show("Severity updated successfully", {
                type: 'success'
            });
            composeDataset(moment().startOf("isoWeek").format("YYYY-MM-DD"), moment().startOf("isoWeek").add(7, "days").format("YYYY-MM-DD"))

            setloader(false);
        } catch (err) {
            setloader(false);
        }

    }

    const SelectFilter = ({ end, start }) => {
        composeDataset(moment(start).format("YYYY-MM-DD"), moment(end).format("YYYY-MM-DD"))
    }


    const ___data = [labels, dataset]

    const handleJumpToCurrentWeek = (currenDate) => {
        composeDataset(moment().startOf("isoWeek").format("YYYY-MM-DD"), moment().startOf("isoWeek").add(7, "days").format("YYYY-MM-DD"))
    }

    const handleWeekPick = (startDate, endDate) => {
        composeDataset(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    }

    return <Layout>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 overflow-hidden px-4 lg:px-0">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                    <LayoutHeader onBack={() => navigate(-1)} title="Track Symptoms" calendar={true} onCalander={() => setviewCalander(!viewCalander)} />
                    {viewCalander && <WeeklyCalendar onWeekPick={handleWeekPick} jumpToCurrentWeekRequired={true} onJumpToCurrentWeek={handleJumpToCurrentWeek} />}

                    <div className="p-4 bg-white mt-4">
                        <Chart
                            chartType="Bar"
                            width="100%"
                            height="400px"
                            data={___data}
                            options={options}
                        />
                    </div>

                    <div className="mt-4">
                        <h4 className="text-lg">On scale of <span className="font-bold">0 to 10</span></h4>
                        <h4>What is your level of pain/symptoms?</h4>

                        <Input options={priortyOptions} type="select" value={severity} onChange={e => setseverity(e.target.value)} />

                        <PrimaryButton loading={loader} onClick={handleSaveSeverity} className="mt-4" title="Submit" />
                    </div>

                </div>

            </div>
        </div>

    </Layout>
}



export default TrackSymptoms;