import React from "react"
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import { IMaskMixin } from 'react-imask'
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { Colors, googleAPIKey } from "../utils/_constant";

const CFormInputWithMask = IMaskMixin(({ inputRef, ...props }) => (
    <Input {...props} ref={inputRef} />
))

const Input = (props) => {
    const { type = 'text', maxlength = 200, defaultValue = [] } = props;

    if (props?.type == 'datepicker') {
        return <div className="relative w-full">
            <input type="date"
                className={`w-full h-12 pl-3 pr-3 bg-slate-50 rounded mt-3 focus:outline-[${Colors.primary}]`} placeholder={props?.placeholder} value={props?.value} onChange={props?.onChange} />
            {props?.error && <h4 className="mt-2 text-sm text-red-400">{props?.error}</h4>}

        </div>
    }

    if (props?.type == 'google') {
        return <ReactGoogleAutocomplete
            apiKey={googleAPIKey}
            className={`w-full h-12 pl-3 pr-3 bg-slate-50 rounded mt-3 focus:outline-[${Colors.primary}]`}
            onPlaceSelected={(place) => props?.onChange(place)}
            placeholder="Address"
            options={{ fields: ["address_components", "geometry", "formatted_address"], types: ["address"] }}
        />
    }

    if (props?.type == 'phone') {
        return <CFormInputWithMask mask="+{1}(000)000-00-00" className={`w-full h-12 pl-3 bg-slate-50 rounded mt-3 focus:outline-[${Colors.primary}]`} />
    }

    if (props?.type == 'multiselect') {
        return <>
            <Select
                isMulti
                onChange={props?.onChange}
                options={props?.options}
                defaultValue={defaultValue}
                className={`w-full min-h-12 pl-3 bg-slate-50 rounded mt-3 focus:outline-[${Colors.primary}] basic-multi-select `}
            // classNamePrefix="select"
            />
            {props?.error && <h4 className="mt-2 text-sm text-red-400">{props?.error}</h4>}
        </>
    }

    if (props?.type == 'select') {
        return <div className="relative w-full">
            <select placeholder={props?.placeholder} className={`w-full h-12 pl-3 bg-slate-50 rounded mt-3 focus:outline-[${Colors.primary}]`} value={props?.value} onChange={props?.onChange}>
                <option className="text-gray-400">{props?.placeholder}</option>
                {props?.options?.map((item, index) => <option key={index} value={item}>{item}</option>)}
            </select>
            {props?.error && <h4 className="mt-2 text-sm text-red-400">{props?.error}</h4>}

        </div>
    }

    if (props?.type == 'checkbox') {
        return <div className="flex flex-row w-full mt-2 ml-[-12px] items-center">
            <Checkbox defaultChecked checked={props?.value} onClick={props?.onClick} sx={{
                '&.Mui-checked': {
                    color: Colors.primary,
                },
            }} />
            {props?.childrenMove ? props?.title : <h4>{props.title}</h4>}
        </div>
    }

    return <div className="relative w-full">
        <input type={type} maxLength={maxlength} className={`w-full h-12 pl-3 bg-slate-50 rounded focus:outline-[${Colors.primary}] mt-3`} placeholder={props?.placeholder} value={props?.value} onChange={props?.onChange} />
        {props?.error && <h4 className="mt-2 text-sm text-red-400">{props?.error}</h4>}
    </div>

}

export default Input