import React, { useEffect, useState } from "react";
import { AlertDialogSlide, CategorySection, ContentCompSection, FileUploader, Input, Layout, LayoutHeader, ReminderComp } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { optionsData } from "../utils/_dataset";
import { _call, _multipartCall } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty, orderBy } from "lodash";
import YouTube from 'react-youtube';
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { Colors } from "../utils/_constant";
import { PrimaryButton } from "../components/_button";
import moment from "moment";
import { useSelector } from "react-redux";

const SpecialistEmail = (props) => {

    const navigate = useNavigate();

    const alert = useAlert()

    const [emailshape, setemailshape] = useState({
        email: '',
        subject: '',
        message: ''
    });
    const [file, setfile] = useState([]);

    const user = useSelector(state => state.user);

    const [loader, setloader] = useState(false);

    const handleSubmit = async () => {

        const { email, subject, message } = emailshape;

        if (email == '') {
            alert.show('You cannot send this message without email', {
                type: 'error'
            });
            return;
        }

        if (subject == '') {
            alert.show('You cannot send this message without subject', {
                type: 'error'
            });
            return;
        }

        if (message == '') {
            alert.show('You cant send this message without content', {
                type: 'error'
            });
            return;
        }

        setloader(true);

        var _dataMultipart = [
            { key: 'to_email', value: email },
            { key: 'body', value: message },
            { key: 'subject', value: `Dr. ${user?.shape?.first_name} ${user?.shape?.last_name} sent you an email, ${subject}` },
        ];

        if (!isEmpty(file)) {
            _dataMultipart.push({
                key: 'file', value: file
            });
        }

        try {
            const composeEmail = await _multipartCall('api/v1/send-email/', 'POST', _dataMultipart);

            setloader(false);
            setemailshape({
                email: '',
                subject: '',
                message: ''
            });
            setfile([]);
            alert.show("Sent Successfully", {
                type: 'success',
            });
            // navigate('HomePage')
        } catch (err) {
            setloader(false);
            alert.show('Some problem occured', {
                type: 'error'
            });
        }

    }


    return <Layout>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 overflow-hidden px-4 lg:px-0">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                    <LayoutHeader title="Compose Email" onBack={() => navigate(-1)} none />

                    <div className="grid grid-cols-1">
                        <Input placeholder="Email" value={emailshape?.email} onChange={e => setemailshape({ ...emailshape, email: e.target.value })} />
                        <Input placeholder="Subject" value={emailshape?.subject} onChange={e => setemailshape({ ...emailshape, subject: e.target.value })} />
                        <textarea className="w-full min-h-[30vh] p-3 mt-5" placeholder="Message"
                            value={emailshape?.message} onChange={e => setemailshape({ ...emailshape, message: e.target.value })}></textarea>
                        <div className="mt-4" />
                        <FileUploader sendFiles={e => setfile(e[0])} />

                        <PrimaryButton className="mt-4" onClick={handleSubmit} loading={loader} title="Submit" />
                    </div>


                </div>
            </div>
        </div>

    </Layout>
}





export default SpecialistEmail;