import React, { useEffect, useState } from "react";
import { CategorySection, ContentCompSection, Layout, LayoutHeader } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { optionsData } from "../utils/_dataset";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

const TMDTreatment = (props) => {

    const navigate = useNavigate();

    const [loader, setloader] = useState(false);

    const [dataset, setdataset] = useState([]);
    const [mode, setmode] = useState(1);
    const [selectedSubInfo, setselectedSubInfo] = useState([]);
    const [selectedContent, setselectedContent] = useState([]);

    const [alldata, setalldata] = useState([]);

    useEffect(() => {
        handleData()
        return () => {

        }
    }, []);

    const handleSelection = async (item) => {

        try {
            const _getContent = await _call(`api/v1/sub-category/${item.id}/`, 'GET');
            setselectedContent(_getContent.data)
            setselectedSubInfo(item);
            setmode(2)
        } catch (err) {
        }

    }

    const handleData = async () => {

        try {

            const getSelfTMD = await _call('api/v1/sub-category/?main_category=15', 'GET');
            setdataset(getSelfTMD.data);
            setalldata(getSelfTMD.data);

        } catch (err) {

        }

    }

    const handleBack = () => {
        if (!isEmpty(selectedContent)) {
            setselectedContent([])
            setmode(1)
            return;
        }

        navigate(-1)
    }


    const handleSearch = (value) => {
        var alldataclone = [...alldata];
        alldataclone = alldataclone.filter(x => x.category_name?.toLowerCase()?.includes(value?.toLowerCase()));
        setdataset(alldataclone);
    }


    return <Layout>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 overflow-hidden px-4 lg:px-0">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                    <LayoutHeader title="TMD Self Treatment" onBack={handleBack} onSearching={handleSearch} />

                    {mode == 1 && <CategorySection category={dataset} onClick={item => {
                        handleSelection(item)
                    }} />}

                    {mode == 2 && <ContentCompSection content={selectedContent} />}




                </div>
            </div>
        </div>

    </Layout>
}

export default TMDTreatment;