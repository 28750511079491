import React, { useState } from "react";
import Input from "../_input";
import { PrimaryButton } from "../_button";
import { useAlert } from "react-alert";
import { _call } from "../../utils/_service";


const EmailForget = ({ onClick }) => {
    const [email, setemail] = useState('')
    const [loader, setloader] = useState(false)

    const alert = useAlert();

    const onSubmit = async () => {
        if (email === '') {
            alert.show("Please fill in your email address", {
                show: 'error'
            })
            return;
        }


        const _dataset = {
            email
        }

        setloader(true);
        try {
            const _forgetpass = await _call('api/v1/forgot-password/', 'POST', _dataset, {
                'Content-Type': 'application/json'
            });
            setloader(false);

            alert.show("We have sent you an email. Please create the new password", {
                show: 'success'
            })
            onClick(email)
        } catch (err) {
            setloader(false);
            alert.show("Sorry, Email is invalid", {
                show: 'error'
            })
        }
    }

    return <div className="bg-white w-full min-h-[100px] mt-10 py-6 px-16 flex flex-col items-center justify-center">
        <h4 className="font-bold text-xl">Forgot Password</h4>
        <div className="mt-2" />
        <Input placeholder="Email" value={email} onChange={e => setemail(e.target.value)} />
        <PrimaryButton loading={loader} onClick={onSubmit} title="Send Verification Code" className="mt-4" />
    </div>
}

export default EmailForget;