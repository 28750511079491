import React, { useEffect, useState } from "react";
import { CategorySection, ContentCompSection, Layout, LayoutHeader } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { optionsData } from "../utils/_dataset";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

const ContentSection = (props) => {

    const navigate = useNavigate();

    const [loader, setloader] = useState(false);
    const [mode, setmode] = useState(0);
    const [category, setcategory] = useState([]);
    const [Allcategory, setAllcategory] = useState([]);
    const [subCategory, setsubCategory] = useState([]);
    const [AllsubCategory, setAllsubCategory] = useState([]);

    const [contentItem, setcontentItem] = useState([]);
    const [content, setcontent] = useState([]);

    useEffect(() => {
        handleData();
    }, [])

    const handleData = async () => {
        try {
            setloader(true);
            const _getdata = await _call('api/v1/main-category/', 'GET');
            const data = _getdata.data.filter(x => ![19, 17, 15, 21]?.includes(x.id));
            setcategory(data);
            setAllcategory(data);
            setloader(false);

        } catch (err) {

            setloader(false);
        }

    }


    const onBringSubCategory = async (item) => {

        try {

            const _getSubCategoryInfo = await _call(`api/v1/sub-category/?main_category=${item.id}`, 'GET');
            setsubCategory(_getSubCategoryInfo.data)
            setAllsubCategory(_getSubCategoryInfo.data)
            setmode(1)

        } catch (err) {

        }
    }


    const onBringContent = async (item) => {
        try {

            const _getContent = await _call(`api/v1/sub-category/${item.id}/`, 'GET');
            setcontent(_getContent.data)
            setmode(2)

        } catch (err) {

        }

    }

    const handleBack = () => {
        if (!isEmpty(content)) {
            setcontent([]);
            setmode(1)
            return;
        }

        if (!isEmpty(subCategory)) {
            setsubCategory([]);
            setAllsubCategory([]);
            setmode(0)
            return;
        }

        if (!isEmpty(category)) {
            navigate(-1)
            return;
        }

    }

    const handleSearch = (value) => {
        if (mode == 0) {
            var alldataclone = [...Allcategory];
            alldataclone = alldataclone.filter(x => x.category_name.toLowerCase().includes(value.toLowerCase()));
            setcategory(alldataclone);
        } else {
            var alldataclone = [...AllsubCategory];
            alldataclone = alldataclone.filter(x => x.category_name.toLowerCase().includes(value.toLowerCase()));
            setsubCategory(alldataclone);
        }
    }

    return <Layout activeId={1}>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 overflow-hidden px-4 lg:px-0">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                    <LayoutHeader title="Content Library" onBack={handleBack} onSearching={handleSearch} />
                    {mode == 0 && <CategorySection category={category} onClick={item => {
                        setsubCategory(item);
                        onBringSubCategory(item)
                    }} />}

                    {mode == 1 && <CategorySection category={subCategory} onClick={item => {
                        setcontentItem(item)
                        onBringContent(item)
                    }} />}

                    {mode == 2 && <ContentCompSection content={content} />}




                </div>
            </div>
        </div>

    </Layout>
}

export default ContentSection;