import React from "react";
import { handleDays } from "../utils/_function";
import { Close } from "@mui/icons-material";

const MessageComp = ({ type = "left", data }) => {

    const handleBringTime = (time) => {
        return handleDays(new Date(
            time?.seconds * 1000 + time?.nanoseconds / 1000000,
        ))
    }


    const handleDownload = () => {
        const url = data.text_content?.split('?')[0];
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '__blank';
        anchor.download = data?.file;
        anchor.click();
    };

    return <div className="flex flex-row" style={{ justifyContent: type == 'left' ? 'flex-start' : 'flex-end' }}>
        <div className="w-[250px] min-h-[60px] rounded-xl mt-2 px-3 py-3" style={{ background: type == 'left' ? '#F5F7FB' : 'linear-gradient(90deg, #2D388A 0%, #00AEEF 100%)' }}>
            <div onClick={handleDownload}>
                {data?.type == 1 && <>
                    {data?.mode == 'image' ?
                        <>
                            <img src={data.text_content?.split('?')[0]} className="mt-2 w-full rounded" />
                        </>
                        : <h4 style={{ color: type == 'left' ? 'black' : 'white' }}>Document</h4>}
                </>}
            </div>
            {data?.type != 1 && <h4 style={{ color: type == 'left' ? 'black' : 'white' }}>{data?.text_content}</h4>}

            <h4 style={{ color: type == 'left' ? 'black' : 'white' }} className="text-xs text-slate-200 mt-2">{handleBringTime(data?.created_at)}</h4>

            <Close
                style={{ right: 5, position: 'absolute', top: 8, padding: 5, zIndex: 10, borderRadius: 100 }}
            />

        </div>
    </div>
}

export default MessageComp;