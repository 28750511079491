import React, { useEffect } from "react";
import { Layout } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { specialistOptionsData } from "../utils/_dataset";
import { SpecialistHeader } from "../assets/svgs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserSlices from "../utils/slices/_user.slices";

const CustDashboard = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const user = useSelector(state => state.user.shape)

    // const Action = UserSlices.action;

    // useEffect(() => {
    //     dispatch(Action.LoadData())
    // }, [])

    return <Layout activeId={0}>

        <div className="grid grid-cols-12 gap-4 px-4 lg:px-0">
            <div className="lg:col-start-5 col-span-12 lg:col-span-4 pt-10">
                <img src={MainLogo} className="w-36 m-auto" />
                <div className="mt-10 p-10 bg-white flex flex-col items-center justify-center rounded-lg shadow-lg">
                    <SpecialistHeader />
                </div>

                <div className="grid lg:grid-cols-2 gap-x-20">
                    {specialistOptionsData?.map((item, index) => <div onClick={() => navigate(item?.route)} key={index} className="bg-white p-4 h-44 justify-center flex flex-col rounded-lg shadow-lg items-center mt-10">
                        <img src={item?.icon} className="w-20 h-20" />
                        <h3 className="text-center">{item?.name}</h3>
                    </div>)}
                </div>

                <button onClick={() => navigate('/tutorial-specialist')} className="flex flex-col items-center w-full justify-center mt-10">
                    <img src={Guide} className="w-36" />
                </button>
            </div>
        </div>

    </Layout>
}

export default CustDashboard;