import React from "react";
import { Layout } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { optionsData } from "../utils/_dataset";
import { useNavigate } from "react-router-dom";

const CustDashboard = (props) => {
    const navigate = useNavigate();

    return <Layout activeId={0}>

        <div className="grid grid-cols-12 gap-4">
            <div className="lg:col-start-5 col-span-12 lg:col-span-4 pt-10 px-10 lg:px-0">
                <img src={MainLogo} className="w-36 m-auto" />
                <div className="mt-10 lg:p-10 bg-white shadow-lg rounded-lg">
                    <img src={CustomerHeadText} className="w-full h-48 lg:h-32 object-cover" />
                </div>

                <div className="grid lg:grid-cols-2 gap-x-20">
                    {optionsData?.map((item, index) => <div onClick={() => navigate(item?.route)} key={index}
                        className="bg-white p-4 h-44 shadow-lg rounded-lg justify-center flex flex-col items-center mt-10">
                        <img src={item?.icon} className="w-20 h-20" />
                        <h3 className="text-center">{item?.name}</h3>
                    </div>)}
                </div>

                <button onClick={() => navigate('/tutorial-customer')} className="flex flex-col items-center w-full justify-center mt-10">
                    <img src={Guide} className="w-36" />
                </button>
            </div>
        </div>

    </Layout>
}

export default CustDashboard;