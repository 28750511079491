import * as yup from 'yup';


const registerValidationSchema = yup.object().shape({
    email: yup
        .string()
        .email("Please enter valid email")
        .required('Please fill in your Email address'),
    first_name: yup
        .string()
        .required('First Name is Required'),
    last_name: yup
        .string()
        .required('Last Name is Required'),
    dob: yup
        .date()
        .test('dob', 'Should be greater than 10 years', function (value, ctx) {
            const dob = new Date(value);
            const validDate = new Date();
            const valid = validDate.getFullYear() - dob.getFullYear() >= 10;
            return !valid ? ctx.createError() : valid;
        })
        .required('Date of Birth is Required'),
    pass: yup
        .string()
        .required('Password is Required')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
    cpass: yup
        .string()
        .oneOf([yup.ref('pass'), null], 'Passwords must match')
        .required("Confirm Password is Required")
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )
});


const specialistregisterValidationSchema = yup.object().shape({
    email: yup
        .string()
        .email("Please enter valid email")
        .required('Please fill in your Email address'),
    first_name: yup
        .string()
        .required('First Name is Required'),
    last_name: yup
        .string()
        .required('Last Name is Required'),
    license_state: yup
        .string()
        .required('License State is Required'),
    pass: yup
        .string()
        .required('Password is Required')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
    cpass: yup
        .string()
        .oneOf([yup.ref('pass'), null], 'Passwords must match')
        .required("Confirm Password is Required")
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )
});



const additionalInfoValidationSchema = yup.object().shape({
    npi_number: yup
        .string()
        .min(10, "Should be 10 digits")
        .max(10, "Should be 10 digits")
        .required('NPI Number is Required'),
    practice_name: yup
        .string()
        .required('Practice Name is Required'),
    address: yup
        .string()
        .required('Address is Required'),
    city: yup
        .string()
        .required('City is Required'),
    state: yup
        .string()
        .required('State is Required'),
    zip_code: yup
        .string()
        .required('Zip Code is Required'),
    phone: yup
        .string()
        .required('Phone is Required'),
    email_practice: yup
        .string()
        .email("Please enter valid email")
        .required('Email of Practice is Required'),
});


const loginValidationSchema = yup.object().shape({
    email: yup
        .string()
        .email("Please enter valid email")
        .required('Please fill in your Email address'),
    password: yup
        .string()
        .required('Password is Required'),
});

const changePasswordValidationSchema = yup.object().shape({
    pass: yup
        .string()
        .required('Password is Required')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
    cpass: yup
        .string()
        .oneOf([yup.ref('pass'), null], 'Passwords must match')
        .required("Confirm Password is Required")
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )
});

const RegisterValidation = {
    register: registerValidationSchema,
    login: loginValidationSchema,
    specialistregister: specialistregisterValidationSchema,
    specialistadditional: additionalInfoValidationSchema,
    changePassword: changePasswordValidationSchema
}

export default RegisterValidation;