import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, Outlet, Navigate } from 'react-router-dom'
import { ClientRegister, InformationPage, WelcomePage, Login, ForgotScene, SpecialistRegister, SpecDashboard, CustDashboard, ContentSection, FindSpecialist, DiagnosisSection, DiagnosisResult, MyDiagnosis, CustomerProfile, RequestSpecialist, RequestIndividual, SpecialistProfile, TMDTreatment, TrackSymptoms, ReminderSection, InboxCustomer, TutorialSection, VideoSection, MoreSection, DocumentSpecialist, FAQSection, FeedbackSection, AboutSection, ContactSection, SpecialistTerms, Privacy, Terms, SpecialistEmail, DiagnosisPicture, DownloadPDFLink } from '../scenes';
import Membership from '../scenes/_membership';

const isAuthenticated = () => {
    const auth = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    return auth && role;
}

const PrivateCustomerRoutes = () => {
    const navigate = useNavigate();
    if (!isAuthenticated()) {
        navigate('/login', { replace: true });
        return null;
    }
    if (localStorage.getItem('role') !== 'Customer') {
        navigate('/login', { replace: true });
        return null;
    }
    return <Outlet />;
}

const PrivateSpecialistRoutes = () => {
    const navigate = useNavigate();
    if (!isAuthenticated()) {
        navigate('/login', { replace: true });
        return null;
    }
    if (localStorage.getItem('role') !== 'Specialist') {
        navigate('/login', { replace: true });
        return null;
    }
    return <Outlet />;
}


const PublicRoutes = () => {
    const navigate = useNavigate();

    if (isAuthenticated()) {
        navigate(localStorage.getItem('role') !== 'Specialist' ? '/customer' : '/specialist')
        return null;
    }
    return <Outlet />;
}

const AppRoutes = () => {


    return (
        <Router>
            <Routes>

                <Route element={<WelcomePage />} path="/welcome" />
                <Route element={<InformationPage />} path="/" />
                <Route element={<ClientRegister />} path="/client-register" />
                <Route element={<Login />} path="/login" />
                <Route element={<ForgotScene />} path="/forgot-password" />
                <Route element={<SpecialistRegister />} path="/specialist-register" />
                <Route element={<DownloadPDFLink />} path="/download-pdf-link" />
                <Route element={<Privacy />} path="/privacy-policy" />
                <Route element={<Terms />} path="/terms-condition" />
                <Route element={<SpecialistTerms />} path="/specialist-terms-condition" />
                <Route element={<PrivateCustomerRoutes />}>
                    <Route element={<CustDashboard />} path="/customer" />
                    <Route element={<ContentSection />} path="/content-section" />
                    <Route element={<FindSpecialist />} path="/find-specialist" />
                    <Route element={<DiagnosisSection />} path="/diagnosis" />
                    <Route element={<DiagnosisResult />} path="/diagnosis-result" />
                    <Route element={<MyDiagnosis />} path="/my-diagnosis" />
                    <Route element={<CustomerProfile />} path="/customer-profile" />
                    <Route element={<TMDTreatment />} path="/self-treatment" />
                    <Route element={<TrackSymptoms />} path="/track-symptoms" />
                    <Route element={<ReminderSection />} path="/reminder" />
                    <Route element={<InboxCustomer />} path="/inbox-customer" />
                    <Route element={<TutorialSection />} path="/tutorial-customer" />
                    <Route element={<VideoSection />} path="/video-customer" />
                    <Route element={<FAQSection />} path="/faq" />
                    <Route element={<AboutSection />} path="/about" />
                    <Route element={<FeedbackSection />} path="/feedback" />
                    <Route element={<ContactSection />} path="/contact" />
                    <Route element={<Privacy />} path="/customer-privacy" />
                    <Route element={<Terms />} path="/customer-terms" />
                    <Route element={<ContactSection />} path="/contact" />
                    <Route element={<MoreSection />} path="/more-customer" />
                    <Route element={<DiagnosisPicture />} path="/diagnosis-picture" />
                </Route>
                <Route element={<PrivateSpecialistRoutes />}>
                    <Route element={<SpecDashboard />} path="/specialist" />
                    <Route element={<RequestSpecialist />} path="/request" />
                    <Route element={<RequestIndividual />} path="/request-individual" />
                    <Route element={<SpecialistProfile />} path="/specialist-profile" />
                    <Route element={<DocumentSpecialist />} path="/specialist-documents" />
                    <Route element={<InboxCustomer />} path="/specialist-inbox" />
                    <Route element={<TutorialSection />} path="/tutorial-specialist" />
                    <Route element={<ContentSection />} path="/specialist-content-section" />
                    <Route element={<MoreSection />} path="/more-specialist" />
                    <Route element={<Privacy />} path="/specialist-privacy" />
                    <Route element={<Terms />} path="/specialist-terms" />
                    <Route element={<Membership />} path="/membership" />
                    <Route element={<SpecialistTerms />} path="/specialist-tac" />
                    <Route element={<FAQSection />} path="/specialist-faq" />
                    <Route element={<AboutSection />} path="/specialist-about" />
                    <Route element={<FeedbackSection />} path="/specialist-feedback" />
                    <Route element={<ContactSection />} path="/specialist-contact" />
                    <Route element={<SpecialistEmail />} path="/specialist-email" />
                </Route>
                {/* Catch-all route for redirection */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
};

export default AppRoutes;
