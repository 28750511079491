import React, { useEffect, useState } from "react";
import { Logo } from "../assets/icons";
import { Input } from "../components";
import { Colors } from "../utils/_constant";
import { PrimaryButton } from "../components/_button";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { useAlert } from 'react-alert'
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { __initialClientRegisteration } from "../utils/_dataset";
import RegisterValidation from "../utils/validator/_auth";
import ClientSlices from "../utils/slices/_client.slices";
import { isEmpty } from "lodash";


const ClientRegister = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const alert = useAlert()

    const __clientState = useSelector(state => state.client_register);
    const __clientAction = ClientSlices.action;

    const [checked, setchecked] = useState(true);

    const formik = useFormik({
        initialValues: __initialClientRegisteration,
        validationSchema: RegisterValidation.register,
        onSubmit: values => handleRegistration(values)
    });

    const { errors, values, touched, handleSubmit, handleChange, setFieldValue, resetForm } = formik;


    const handleRegistration = async (values) => {
        if (!checked) {
            alert.show("Please accept the terms and conditions", {
                type: 'error'
            })
            return;
        }

        const _dataset = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            password: values.pass,
            clientprofile: {
                dob: moment(values.dob).format('YYYY-MM-DD')
            }
        }

        dispatch(__clientAction.createData(_dataset))

        setTimeout(() => {
            alert.show("Congratulations, Your account is created.", {
                type: 'success'
            })
            resetForm();
            navigate('/login')
        }, 3000)
    }

    // useEffect(() => {
    //     if (!__clientState?.loadingStatus && !isEmpty(__clientState?.current)) {
    //         alert.show("Congratulations, Your account is created.", {
    //             type: 'success'
    //         })
    //     }
    // }, [__clientState])

    return <div className="w-full h-[100vh] bg-slate-100 px-4 lg:px-0">
        <div className="pt-20 lg:pt-[120px] lg:w-[500px] m-auto overflow-hidden">
            <div className="w-full flex flex-col items-center">
                <Logo />
                <div className="bg-white w-full min-h-[300px] mt-10 py-6 px-4 lg:px-16 flex flex-col items-center justify-center">
                    <h4 className="font-bold text-xl">Create Account</h4>
                    <div className="mt-2" />
                    <Input placeholder="First Name" value={values?.first_name} onChange={e => setFieldValue('first_name', e.target.value)} error={(errors?.first_name && touched?.first_name) && errors?.first_name} />
                    <Input placeholder="Last Name" value={values?.last_name} onChange={e => setFieldValue('last_name', e.target.value)} error={(errors?.last_name && touched?.last_name) && errors?.last_name} />
                    <Input placeholder="Email" type="email" value={values?.email} onChange={e => setFieldValue('email', e.target.value)} error={(errors?.email && touched?.email) && errors?.email} />
                    <Input placeholder="Date of Birth" type="datepicker" value={values?.dob} onChange={e => setFieldValue('dob', e.target.value)} error={(errors?.dob && touched?.dob) && errors?.dob} />
                    <Input placeholder="Password" type="password" value={values?.pass} onChange={e => setFieldValue('pass', e.target.value)} error={(errors?.pass && touched?.pass) && errors?.pass} />
                    <Input placeholder="Confirm Password" type="password" value={values?.cpass} onChange={e => setFieldValue('cpass', e.target.value)} error={(errors?.cpass && touched?.cpass) && errors?.cpass} />
                    <Input type="checkbox" value={checked} onClick={() => setchecked(!checked)} childrenMove
                        title={<h4>By creating an account, you agree to <a href="/privacy-policy" target='__blank' className="underline underline-offset-4">Privacy Policy</a> and <a href="/terms-condition" className="underline underline-offset-4" target='__blank'>Terms of Use.</a></h4>} />
                    <PrimaryButton loading={__clientState?.loadingStatus} type="submit" onClick={handleSubmit} title="Sign up" className="mt-4" />
                    <div className="mt-2">
                        <h4 onClick={() => navigate('/login')} className="cursor-pointer">Already have an account? <span style={{ color: Colors.primary }}>Log in</span></h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ClientRegister;