import "./App.css";
import AppRoutes from "./routes";
import { requestPermission } from "./utils/_function";

function App() {
  return (
    <AppRoutes />
  );
}

export default App;
