import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk';
import epicMiddleware, { rootEpic } from './epics';
import ClientSlices from './slices/_client.slices';
import SpecialistAreaSlices from './slices/_specialistarea.slices';
import SpecialistSlices from './slices/_specialist.slices';
import UserSlices from './slices/_user.slices';


const persistConfig = {
    key: 'root',
    version: 1,
    storage,
}

const rootReducer = combineReducers({
    user: UserSlices.reducer,
    specialist_area: SpecialistAreaSlices.reducer,
    client_register: ClientSlices.reducer,
    specialist_register: SpecialistSlices.reducer
});


const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [
        ...getDefaultMiddleware({
            thunk: false,
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            }
        }),
        thunk,
        epicMiddleware
    ]
});

epicMiddleware.run(rootEpic);

export default store;