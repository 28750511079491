import React, { useEffect, useState } from "react";
import { AlertDialogSlide, CategorySection, ContentCompSection, Input, Layout, LayoutHeader, ReminderComp } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { optionsData } from "../utils/_dataset";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty, orderBy } from "lodash";
import YouTube from 'react-youtube';
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { Colors } from "../utils/_constant";
import { PrimaryButton } from "../components/_button";
import moment from "moment";

const VideoSection = (props) => {

    const navigate = useNavigate();

    const [loader, setloader] = useState(false);

    const alert = useAlert()

    const [dataset, setdataset] = useState([]);
    const [mode, setmode] = useState(0)
    const [alldataset, setalldataset] = useState([]);
    const [selected, setselected] = useState([]);

    useEffect(() => {
        handleData()
        return () => { }
    }, []);

    const handleData = async () => {

        try {

            const _getData = await _call('api/v1/video-category/', 'GET');

            setdataset(orderBy(_getData.data, 'sorting_order', 'asc'));
            setalldataset(orderBy(_getData.data, 'sorting_order', 'asc'))
        } catch (err) {

        }

    }

    const handleGetVideos = async (item) => {

        try {

            const _getData = await _call(`api/v1/video/?video_category=${item.id}`, 'GET');
            setselected(_getData.data);
            setmode(1)

        } catch (err) {

        }
    }

    const handleSearch = (value) => {
        var alldataclone = [...alldataset];
        alldataclone = alldataclone.filter(x => x.title.toLowerCase().includes(value.toLowerCase()));
        setdataset(alldataclone);
    }


    const handleBack = () => {

        if (!isEmpty(selected)) {
            setselected([])
            handleData()
            return;
        }

        navigate(-1)
    }

    return <Layout activeId={2}>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 overflow-hidden px-4 lg:px-0">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                    <LayoutHeader title="Videos" onSearching={handleSearch} onBack={handleBack} />

                    {mode == 0 && <CategorySection category={dataset} onClick={item => {
                        handleGetVideos(item);
                    }} />}

                    <div className="grid grid-cols-1 gap-10">
                        {selected?.map(item => <PlayingVideoComp url={item?.url} />)}
                    </div>


                </div>
            </div>
        </div>

    </Layout>
}



const PlayingVideoComp = ({ url }) => {
    const [playing, setPlaying] = useState(false);
    const regex = /v=([^&]+)/;
    const match = url.match(regex);
    const contentId = match[0];

    const opts = {
        width: '100%',
        height: 400,
    };

    return (
        <div className="mt-4 w-full">
            <YouTube
                opts={opts}
                play={playing}
                videoId={contentId?.split('v=')[1]}
                onChangeState={state => {
                    if (state == "ended") {
                        setPlaying(false)
                    }
                }}
                contentScale={1.0}
            />
        </div>
    );
}





export default VideoSection;