import React from "react";
import Input from "../_input";
import { PrimaryButton } from "../_button";
import { Colors } from "../../utils/_constant";
import { __initialSpecialistRegisteration, states } from "../../utils/_dataset";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import RegisterValidation from "../../utils/validator/_auth";


const SpecialistRegisterSection = ({ onRegister }) => {
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: __initialSpecialistRegisteration,
        validationSchema: RegisterValidation.specialistregister,
        onSubmit: values => onRegister(values)
    });

    const { errors, values, touched, handleSubmit, handleChange, setFieldValue, resetForm } = formik;

    return <div className="bg-white w-full min-h-[300px] mt-10 py-6 px-4 lg:px-16 flex flex-col items-center justify-center">
        <h4 className="font-bold text-xl">Create Account</h4>
        <div className="mt-2" />
        <Input placeholder="First Name" value={values?.first_name} onChange={e => setFieldValue('first_name', e.target.value)} error={(errors?.first_name && touched?.first_name) && errors?.first_name} />
        <Input placeholder="Last Name" value={values?.last_name} onChange={e => setFieldValue('last_name', e.target.value)} error={(errors?.last_name && touched?.last_name) && errors?.last_name} />
        <Input placeholder="Email" type="email" value={values?.email} onChange={e => setFieldValue('email', e.target.value)} error={(errors?.email && touched?.email) && errors?.email} />
        <Input type="select" value={values?.license_state} onChange={e => setFieldValue('license_state', e.target.value)} error={(errors?.license_state && touched?.license_state) && errors?.license_state} placeholder="License State" options={states} />
        <Input placeholder="Password" type="password" value={values?.pass} onChange={e => setFieldValue('pass', e.target.value)} error={(errors?.pass && touched?.pass) && errors?.pass} />
        <Input placeholder="Confirm Password" type="password" value={values?.cpass} onChange={e => setFieldValue('cpass', e.target.value)} error={(errors?.cpass && touched?.cpass) && errors?.cpass} />
        <PrimaryButton type="submit" onClick={handleSubmit} title="Sign up" className="mt-4" />
        <div className="mt-2">
            <h4 className="cursor-pointer" onClick={() => navigate('/login')}>Already have an account? <span style={{ color: Colors.primary }}>Log in</span></h4>
        </div>
    </div>
}

export default SpecialistRegisterSection;