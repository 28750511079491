import React from "react";
import { Colors } from "../utils/_constant";
import { CircularProgress } from "@mui/material";

const PrimaryButton = ({ onClick, loading = false, title, className, type = 'button', disabled = false }) => <button
    onClick={onClick}
    type={type}
    disabled={disabled}
    style={{ background: 'linear-gradient(90deg, #2D388A 0%, #00AEEF 100%)' }}
    className={`w-full h-12 rounded-full flex flex-row items-center text-white justify-center text-lg ${className}`}
>
    {loading ? <CircularProgress className="text-white" size={16} /> : title}
</button>


const CustomButton = ({ onClick, loading = false, title, className }) => <button
    onClick={onClick}
    className={`w-full h-12 rounded-full bg-white flex flex-row items-center justify-center text-[#009DD8] text-lg ${className}`}
>
    {title}
</button>


const ColoredButton = ({ onClick, loading = false, title, className }) => <button
    onClick={onClick}
    className={`w-full h-12 rounded-full text-white flex flex-row items-center justify-center bg-[#009DD8] text-lg ${className}`}
>
    {title}
</button>

export {
    PrimaryButton, CustomButton, ColoredButton
}