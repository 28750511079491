import React from "react";
import { Colors } from "../utils/_constant";
import { useSelector } from "react-redux";
import moment from "moment";
import { handleDays } from "../utils/_function";

const ChatComp = ({ data, onClick, selected }) => {
    const user = useSelector(state => state.user);

    const currentId = user?.shape?.id;

    const composeCurrentUser = () => {

        if (data?.chat_user_details?.id == currentId) {
            return data?.main_user_details;
        } else {
            return data?.chat_user_details;
        }
    }
    return <div onClick={onClick} className="cursor-pointer p-4 flex flex-row relative border-b pb-4"
        style={{ background: selected?.id == data?.id ? Colors.primary : '#fff' }}
    >
        <img className="bg-slate-50 rounded-md w-10 h-10"
            src={composeCurrentUser()?.profile_picture?.split('?')[0]} />
        <div className="flex flex-col ml-2">
            <h4 className="text-sm text-left w-full font-bold"
                style={{ color: selected?.id != data?.id ? '#000' : '#fff' }}
            >{composeCurrentUser()?.first_name} {composeCurrentUser()?.last_name}</h4>
            <p className="text-slate-500 text-left w-full text-[10px]"
                style={{ color: selected?.id != data?.id ? '#000' : '#fff' }}
            >{composeCurrentUser()?.email}</p>
        </div>
        <h4 className={`text-[${Colors.primary}] text-[10px] absolute top-2 right-2`}
            style={{ color: selected?.id != data?.id ? Colors.primary : '#fff' }}
        >{handleDays(data.onCreated)}</h4>
    </div>
}

export default ChatComp;