import React, { useEffect, useState } from "react";
import { CurrentRequest, Input, Layout, LayoutHeader } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { Duration, MeetingData, __initialMeeting, specialistOptionsData } from "../utils/_dataset";
import { SpecialistHeader } from "../assets/svgs";
import { _call } from "../utils/_service";
import { useLocation, useNavigate } from "react-router-dom";
import { PrimaryButton } from "../components/_button";
import moment from "moment";
import { Colors } from "../utils/_constant";
import { useFormik } from "formik";
import DiagnosisValidation from "../utils/validator/_diagnosis";
import { useAlert } from "react-alert";

const RequestIndividual = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [loader, setloader] = useState(false);
    const [onView, setonView] = useState(false);
    const data = location.state?.data || [];
    const painInfo = location.state?.painInfo || [];

    const alert = useAlert();

    const { clientprofile, email, first_name, last_name, profile_picture } = data?.client_details;

    const formik = useFormik({
        initialValues: __initialMeeting,
        validationSchema: DiagnosisValidation.meeting,
        onSubmit: values => CreateMeeting(values)
    })


    const CreateMeeting = async (values) => {

        try {

            const _dataset = {
                to_email: data?.specialist_details.email,
                body: `
                ${values?.message}\n
                Date: ${moment(values?.date).format('YYYY-MM-DD')}\n
                Time: ${moment(values?.time).format('YYYY-MM-DD')}\n
                Price: ${values?.hours?.split(' ')[0]}\n
                Minutes: ${values?.price}\n
                Your specialist would reach you with ${values?.method}\n
                Best,
                `,
                subject: "Request Accepted"
            }

            setloader(true)
            const _data = {
                "date": moment(values?.date).format('YYYY-MM-DD'),
                "time": values?.time,
                "minutes": values?.hours?.split(' ')[0],
                "price": values?.price,
                "approve": true,
                "meeting_status": 1,
            }
            const composeEmail = await _call('api/v1/send-email/', 'POST', _dataset);
            const _setmeeting = await _call(`api/v1/meeting-request/${data.id}/`, 'PATCH', _data);
            setloader(false)
            alert.show('Meeting schedule is set.', {
                type: 'success'
            });

            navigate(-1)

        } catch (err) {
            setloader(false);

            alert.show('Something went wrong', {
                type: 'error'
            });
        }
    }

    const DeclineRequest = async () => {

        try {

            const declineRequest = await _call(`api/v1/meeting-request/${data.id}/`, 'PATCH', {
                meeting_status: 2
            });
            alert?.show('Declined Successfully!', {
                type: 'success'
            })
            navigate(-1)

        } catch (err) {

            alert.show('Something went wrong', {
                type: 'error'
            });
        }
    }

    const { values, errors, touched, setFieldValue, handleSubmit } = formik;

    return <Layout>

        <div className="grid grid-cols-12 gap-4 px-4 lg:px-0">
            <div className="lg:col-start-4 col-span-12 lg:col-span-6 pt-10">
                <LayoutHeader title="Current Requests" onBack={() => navigate(-1)} />
                <div className="grid lg:grid-cols-6 gap-4 my-5">
                    <div className="p-4 col-span-6 lg:col-span-2 bg-white max-h-[400px]">
                        <div className="flex flex-row items-center pb-4 border-b justify-between cursor-pointer">
                            <div className="flex flex-row items-center">
                                <img src={profile_picture?.split('?')[0]} className="w-10 h-10 rounded-full bg-slate-50" />
                                <div className="ml-3">
                                    <h4 className="font-bold text-md">{first_name} {last_name}</h4>
                                    <p className="text-xs text-slate-400">{email}</p>
                                </div>
                            </div>
                        </div>
                        <div className="border-b p-2 flex flex-col w-full">
                            <div className="flex flex-row items-center w-full">

                                <div className="ml-0 w-full">
                                    <h4 className="text-xs">Date of Birth</h4>
                                    <p className="text-slate-500 text-md">{moment(data?.client_details?.clientprofile?.dob).format('DD MM YYYY')}</p>
                                </div>
                            </div>
                        </div>

                        <div className="border-b p-2 flex flex-col w-full">
                            <div className="flex flex-row items-center w-full">

                                <div className="ml-0 w-full">
                                    <h4 className="text-xs">Height</h4>
                                    <p className="text-slate-500 text-md">{data?.client_details?.clientprofile?.height}</p>
                                </div>
                            </div>
                        </div>

                        <div className="border-b p-2 flex flex-col w-full">
                            <div className="flex flex-row items-center w-full">

                                <div className="ml-0 w-full">
                                    <h4 className="text-xs">Weight</h4>
                                    <p className="text-slate-500 text-md">{data?.client_details?.clientprofile?.weight}</p>
                                </div>
                            </div>
                        </div>


                        <PrimaryButton onClick={() => setonView(true)} title="Accept Request" className="w-[90%] m-auto mt-4 mb-1" />
                        <button onClick={DeclineRequest} className="text-center m-auto block">Decline</button>
                    </div>

                    {onView && <div className="p-4 min-h-[80vh] bg-white col-span-6 lg:col-span-4">
                        <h4 className="text-xl font-bold text-left">Set Meeting</h4>
                        <hr className="mt-4 mb-4" />

                        <h4 className="text-sm mb-[-5px]">Meeting Date</h4>
                        <Input type="datepicker" value={values?.date} onChange={e => setFieldValue('date', e.target.value)} error={(errors?.date && touched?.date) && errors?.date} />

                        <h4 className="text-sm mb-[-5px] mt-4">Meeting Time</h4>
                        <Input type="time" value={values?.time} onChange={e => setFieldValue('time', e.target.value)} error={(errors?.time && touched?.time) && errors?.time} />

                        <h4 className="text-sm mb-[-5px] mt-4">Meeting Minutes</h4>
                        <Input type="select" options={Duration} value={values?.hours} onChange={e => setFieldValue('hours', e.target.value)} error={(errors?.hours && touched?.hours) && errors?.hours} />

                        <h4 className="text-sm mb-[-5px] mt-4">Consultation Fees</h4>
                        <Input type="text" value={values?.price} onChange={e => setFieldValue('price', e.target.value)} error={(errors?.price && touched?.price) && errors?.price} />

                        <h4 className="text-sm mb-[-5px] mt-4">Meeting Method</h4>
                        <Input type="select" options={MeetingData} value={values?.method} onChange={e => setFieldValue('method', e.target.value)} error={(errors?.method && touched?.method) && errors?.method} />

                        <h4 className="text-sm mb-[-5px] mt-4">Message</h4>
                        <textarea className={`w-full min-h-[20vh] pl-3 bg-slate-50 rounded mt-3 focus:outline-[${Colors.primary}]`} value={values?.message} onChange={e => setFieldValue('message', e.target.value)} error={(errors?.message && touched?.message) && errors?.message}  ></textarea>

                        <PrimaryButton onClick={handleSubmit} loading={loader} title="Create Meeting" className="m-auto mt-4 mb-1" />
                    </div>}
                </div>
            </div>
        </div>

    </Layout>
}

export default RequestIndividual;