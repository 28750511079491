import React, { useEffect, useState } from "react";
import { AlertDialogSlide, CategorySection, ContentCompSection, Input, Layout, LayoutHeader, ReminderComp } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { optionsData } from "../utils/_dataset";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty, orderBy } from "lodash";
import YouTube from 'react-youtube';
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { Colors } from "../utils/_constant";
import { PrimaryButton } from "../components/_button";
import moment from "moment";

const TutorialSection = (props) => {

    const navigate = useNavigate();

    const [loader, setloader] = useState(false);

    const alert = useAlert()

    const [dataset, setdataset] = useState([]);
    const [mode, setmode] = useState(0)
    const [alldataset, setalldataset] = useState([]);
    const [selected, setselected] = useState([]);

    useEffect(() => {
        handleData()
        return () => { }
    }, []);

    const handleData = async () => {

        try {
            const _getData = await _call('api/v1/tutorial/', 'GET');
            setdataset(_getData.data);
        } catch (err) {
        }

    }

    return <Layout>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 overflow-hidden px-4 lg:px-0">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                    <LayoutHeader title="Tutorials" onBack={() => navigate(-1)} />

                    <div className="grid grid-cols-1 gap-10">
                        {dataset?.map(item => <PlayingVideoComp url={item?.url} />)}
                    </div>


                </div>
            </div>
        </div>

    </Layout>
}



const PlayingVideoComp = ({ url }) => {
    const [playing, setPlaying] = useState(false);
    const regex = /v=([^&]+)/;
    const match = url.match(regex);
    const contentId = match[0];

    const opts = {
        width: '100%',
        height: 400,
    };

    return (
        <div className="mt-4 w-full">
            <YouTube
                opts={opts}
                play={playing}
                videoId={contentId?.split('v=')[1]}
                onChangeState={state => {
                    if (state == "ended") {
                        setPlaying(false)
                    }
                }}
                contentScale={1.0}
            />
        </div>
    );
}





export default TutorialSection;