export const _apiStatus = {
    initalLoading: false,
    Loading: true,
    Loaded: false,
    Failed: false,
    Success: true,
}

export const _userInitialState = {
    initialLoadingStatus: _apiStatus.initalLoading,
    loadingStatus: _apiStatus.initalLoading,
    fetchedStatus: _apiStatus.Loaded,
    data: [],
    error: [],
    success: _apiStatus.Failed,
    message: '',
    current: []
};

export const _initialState = {
    loadingStatus: _apiStatus.initalLoading,
    shape: [],
    error: [],
    success: _apiStatus.Failed,
    current: []
}
