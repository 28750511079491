import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../_input";
import { PrimaryButton } from "../_button";
import { Colors, googleAPIKey } from "../../utils/_constant";
import { isEmpty } from "lodash";
import Geocode from 'react-geocode';
import { useAlert } from 'react-alert'
import SpecialistAreaSlices from "../../utils/slices/_specialistarea.slices";
import { useFormik } from "formik";
import { __initialSpecialistRegisterationDetail, websiteRegex } from "../../utils/_dataset";
import RegisterValidation from "../../utils/validator/_auth";
import { getAddressInfo } from "../../utils/_function";
import { useNavigate } from "react-router-dom";


const SpecialistRegisterDetail = ({ onRegister, loader }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [onmessaged, setonmesseged] = useState(true);
    const [terms, setterms] = useState(true);

    const specialistState = useSelector(state => state.specialist_register);
    const specialistArea = useSelector(state => state.specialist_area);
    const __specialistAction = SpecialistAreaSlices.action;

    const alert = useAlert()

    useEffect(() => {
        if (isEmpty(specialistArea?.shape)) {
            dispatch(__specialistAction.LoadData())
        }
    }, [])

    Geocode.setApiKey(googleAPIKey);

    const handleGeoCode = async (destination) => new Promise(async (resolve, reject) => {
        Geocode.fromAddress(destination).then((response) => {
            const { lat, lng } = response.results[0].geometry.location;
            resolve({ lat, lng })
        },
            (error) => {
                reject('false')
            }
        );
    });


    const formik = useFormik({
        initialValues: __initialSpecialistRegisterationDetail,
        validationSchema: RegisterValidation.specialistadditional,
        onSubmit: values => handleRegister(values)
    });

    const handleRegister = (values) => {
        if (!terms) {
            alert.show("Please accept the terms and conditions", {
                type: 'error'
            })
            return;
        }


        if (!websiteRegex?.test(values.website)) {
            alert.show("Please enter a valid url", {
                type: 'error'
            })
            return
        }

        const _dataset = {
            latitude: values.latitude,
            longitude: values.longitude,
            specialistprofile: {
                open_to_message: onmessaged,
                license_number: values.license_number,
                npi: values.npi_number,
                practice_name: values.practice_name,
                phone_number: values.phone,
                street_number: (values.address + ' ' + values.address2).trim(),
                town: values.city,
                state: values.state,
                website_url: values.website,
                speciality_area: values?.speciality?.map(item => item.id)
            }
        }


        onRegister(_dataset)

    }

    const { values, errors, handleSubmit, touched, setFieldValue } = formik;


    const onGoogleAutoComplete = async (detail) => {
        try {
            const __get = await getAddressInfo(detail?.formatted_address);
            const __getLatLng = await handleGeoCode(detail?.formatted_address);

            setFieldValue('address', detail?.formatted_address);
            setFieldValue('state', __get.state);
            setFieldValue('city', __get.city);
            setFieldValue('country', __get.country);
            setFieldValue('zip_code', __get.zip_code);

            setFieldValue('latitude', __getLatLng?.lat);
            setFieldValue('longitude', __getLatLng?.lng);

        } catch (err) {
            console.log(err);
        }
    }

    return <div className="bg-white w-full min-h-[300px] mt-10 py-6 px-4 lg:px-16 flex flex-col items-center justify-center">
        <h4 className="font-bold text-xl">Create Account</h4>
        <div className="mt-2" />
        <Input maxlength={10} placeholder="NPI Number" value={values?.npi_number} onChange={e => setFieldValue('npi_number', e.target.value)} error={(errors?.npi_number && touched?.npi_number) && errors?.npi_number} />
        <Input placeholder="Practice Name" value={values?.practice_name} onChange={e => setFieldValue('practice_name', e.target.value)} error={(errors?.practice_name && touched?.practice_name) && errors?.practice_name} />
        <Input type="multiselect" placeholder="Specialist Areas"
            onChange={e => setFieldValue('speciality', e)} error={(errors?.speciality && touched?.speciality) && errors?.speciality}
            options={specialistArea?.shape?.map(item => {
                return { label: item?.speciality_name, value: item?.speciality_name, id: item?.id }
            })} />

        <Input placeholder="License Number" value={values?.license_number} onChange={e => setFieldValue('license_number', e.target.value)} error={(errors?.license_number && touched?.license_number) && errors?.license_number} />
        <Input placeholder="Website: https://example.com/" value={values?.website} onChange={e => setFieldValue('website', e.target.value)} error={(errors?.website && touched?.website) && errors?.website} />

        <Input placeholder="Address" type="google" onChange={onGoogleAutoComplete} />
        <Input placeholder="Address 2" value={values?.address2} onChange={e => setFieldValue('address2', e.target.value)} error={(errors?.address2 && touched?.address2) && errors?.address2} />

        <Input placeholder="State" value={values?.state} onChange={e => setFieldValue('state', e.target.value)} error={(errors?.state && touched?.state) && errors?.state} />
        <Input placeholder="Town/City" value={values?.city} onChange={e => setFieldValue('city', e.target.value)} error={(errors?.city && touched?.city) && errors?.city} />
        <Input placeholder="Zip Code" value={values?.zip_code} onChange={e => setFieldValue('zip_code', e.target.value)} error={(errors?.zip_code && touched?.zip_code) && errors?.zip_code} />

        <Input placeholder="Phone Number" value={values?.phone} onChange={e => setFieldValue('phone', e.target.value)} error={(errors?.phone && touched?.phone) && errors?.phone} />
        <Input placeholder="Email of Practice" value={values?.email_practice} onChange={e => setFieldValue('email_practice', e.target.value)} error={(errors?.email_practice && touched?.email_practice) && errors?.email_practice} />

        <Input value={onmessaged} onClick={() => setonmesseged(!onmessaged)} type="checkbox" title={`Are you open to being messaged?`} />
        <Input value={terms} onClick={() => setterms(!terms)} type="checkbox" childrenMove title={
            <h4>By creating an account, you agree to myTMJ Specialist <a href="/privacy-policy" className="underline underline-offset-4" target='__blank'>Privacy Policy</a> and <a href="/specialist-terms-condition" className="underline underline-offset-4" target='__blank'>Terms of Use.</a></h4>
        } />
        <PrimaryButton loading={loader} type="submit" onClick={handleSubmit} title="Sign up" className="mt-4" />
        <div className="mt-2">
            <h4 className="cursor-pointer" onClick={() => navigate('/login')}>Already have an account? <span style={{ color: Colors.primary }}>Log in</span></h4>
        </div>
    </div>
}

export default SpecialistRegisterDetail;

// doctormufaz@mailinator.com