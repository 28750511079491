import React, { useEffect, useState } from "react";
import { AlertDialogSlide, CategorySection, ContentCompSection, Input, Layout, LayoutHeader, ReminderComp } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { optionsData } from "../utils/_dataset";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { Colors } from "../utils/_constant";
import { PrimaryButton } from "../components/_button";
import moment from "moment";


const _initialReminder = {
    topic: '',
    date: new Date(),
    notes: '',
    frequency: ''
}

// options={["Hourly", "Once", "2 Hours", "6 Hours", "12 Hours", "Daily"]}
const ReminderArr = [
    { id: 0, name: 'Hourly' },
    { id: 1, name: 'Once' },
    { id: 2, name: '2 Hours' },
    { id: 3, name: '6 Hours' },
    { id: 4, name: '12 Hours' },
    { id: 5, name: 'Daily' },
]


const ReminderSection = (props) => {

    const navigate = useNavigate();

    const [loader, setloader] = useState(false);
    const [viewReminder, setviewReminder] = useState(false)
    const [reminder, setreminder] = useState(_initialReminder);
    const [dataset, setdataset] = useState([]);
    const [editMode, seteditMode] = useState(false);

    const alert = useAlert()

    useEffect(() => {
        handleData()
        return () => { }
    }, []);

    const handleData = async () => {

        try {
            setloader(true);
            const _getData = await _call('api/v1/diagnose-reminder/', 'GET');
            setdataset(_getData.data.sort((a, b) => b.id - a.id))
            setloader(false);
        } catch (err) {
            setloader(false);
        }

    }

    const handleChange = (key, value) => {
        var reminderc = { ...reminder };
        reminderc[key] = value;
        setreminder(reminderc);
    }

    const handleReturnFrequency = (value) => {
        switch (value) {
            case 'Hourly':
                return 0;
            case '2 Hours':
                return 4;
            case 'Once':
                return 5;
            case '6 Hours':
                return 1
            case '12 Hours':
                return 2
            case 'Daily':
                return 3
        }
    }

    const handleGetFrequency = (value) => {
        switch (value) {
            case 0:
                return 'Hourly';
            case 4:
                return '2 Hours';
            case 5:
                return 'Once';
            case ``:
                return '6 Hours'
            case 2:
                return '12 Hours'
            case 3:
                return 'Daily'
        }
    }

    const handleSubmit = async () => {
        const { topic, date, frequency, notes } = reminder;

        if (topic == '' || notes == '') {
            alert.show('Please complete all required informations', {
                type: 'error'
            })
            return;
        }

        try {
            setloader(true);

            const _dataset = {
                "reminder_time": moment(date).format('YYYY-MM-DDTHH:mm:ssZ'),
                "diagnose": 1,
                "title": topic,
                "notes": notes,
                "frequency": handleReturnFrequency(frequency)
            }
            var url = 'api/v1/diagnose-reminder/';
            if (editMode) {
                url = `${url}${reminder.id}/`
            }

            const _createReminder = await _call(url, editMode ? 'PUT' : 'POST', _dataset);

            seteditMode(false);
            alert.show("Reminder set successfully", {
                type: 'success'
            });
            setviewReminder(false)
            setloader(false);
            handleData();
        } catch (err) {
            alert.show("Some problem occured", {
                type: "error"
            })
            setloader(false);
        }


    }

    const handleDelete = async (item) => {
        var creminder = [...dataset];
        creminder = creminder.filter(x => x != item);
        setdataset(creminder);
        try {
            setloader(true);
            const _removeData = await _call(`api/v1/diagnose-reminder/${item.id}/`, 'DELETE');
            alert.show("Removed Successfully", {
                type: 'success'
            })
            setloader(false);
        } catch (err) {
            alert.show("Some problem occured", {
                type: 'error'
            })
            setloader(false);
        }

    }

    const handleEdit = async (item) => {
        setviewReminder(true);
        var creminder = {
            topic: item.title,
            notes: item.notes,
            date: item.reminder_time,
            ...item
        }
        setreminder(creminder);
    }


    const RenderReminderView = () => {
        return <div className="w-full min-h-[200px]">
            <Input placeholder="Reminder Title" options={["Meet with the Specialist", "Jaw Muscle Relaxation", "Stretching Excercises"]}
                type="select" value={reminder?.topic} onChange={e => handleChange('topic', e.target.value)}
            />

            <Input placeholder="Reminder Date" type="datetime-local"
                value={reminder?.date} onChange={e => handleChange('date', e.target.value)} />

            <textarea
                placeholder="Notes"
                value={reminder?.notes} onChange={e => handleChange('notes', e.target.value)}
                className={`w-full min-h-[100px] pt-3 pl-3 pr-3 bg-slate-50 rounded mt-3 focus:outline-[${Colors.primary}]`} >

            </textarea>
            <Input placeholder="Reminder Frequency"
                options={["Hourly", "Once", "2 Hours", "6 Hours", "12 Hours", "Daily"]}
                type="select" value={handleGetFrequency(reminder?.frequency)}
                onChange={e => handleChange('frequency', e.target.value)}
            />

            <PrimaryButton className="mt-4" title="Submit" onClick={handleSubmit} loading={loader} />

        </div>
    }


    return <Layout>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 overflow-hidden px-4 lg:px-0">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                    <LayoutHeader onBack={() => navigate(-1)} title="Reminder Section" reminder onReminder={() => setviewReminder(!viewReminder)} />

                    <div className="grid lg:grid-cols-3 gap-4">
                        {dataset.map(item => <ReminderComp onEdit={() => handleEdit(item)} onDelete={() => handleDelete(item)} data={item} />)}
                    </div>


                    <AlertDialogSlide
                        open={viewReminder}
                        handleClose={() => {
                            setviewReminder(false)
                            setreminder(_initialReminder)
                        }}
                        children={RenderReminderView()}
                        title={reminder?.topic ? `Edit Reminder ${moment(reminder?.date)?.format('DD-MM-YYYY hh:mm a')}` : "Create Reminder"}
                    />

                </div>
            </div>
        </div>

    </Layout>
}

export default ReminderSection;