import React, { useState } from "react";
import OtpInput from 'react-otp-input';
import { PrimaryButton } from "../_button";
import { Colors } from "../../utils/_constant";
import { useAlert } from "react-alert";
import { _call } from "../../utils/_service";


const OTPForget = ({ email, onClick, onBack }) => {
    const [otp, setotp] = useState('')
    const [loader, setloader] = useState(false)

    const alert = useAlert();



    const handleOTPVerify = async () => {

        try {
            setloader(true);
            const _request = await _call('api/v1/forgot-password/verify-code/', 'POST', {
                email,
                code: otp
            }, {
                'Content-Type': 'application/json'
            })
            setloader(false);
            onClick(_request.data?.token)
        } catch (err) {
            setloader(false);

            alert.show("Sorry, OTP is invalid", {
                show: 'error'
            })
        }

    }

    return <div className="bg-white w-full min-h-[100px] mt-10 py-6 px-16 flex flex-col items-center justify-center">
        <h4 className="font-bold text-xl">Got our text yet?</h4>
        <p className="text-slate-400 text-center text-sm mt-2">We've sent an email with 6 digit code to your email address</p>
        <div className="mt-4" />
        <OtpInput
            value={otp}
            onChange={setotp}
            numInputs={6}
            renderSeparator={<span>&nbsp;&nbsp;</span>}
            renderInput={(props) => <input {...props} className="bg-slate-100" />}
            inputStyle={{ width: 40, height: 40 }}
        />
        <div className="flex flex-row justify-end w-full mt-2 mr-12">
            <button onClick={onBack} style={{ color: Colors.primary }}>Wrong Code! Try Again</button>
        </div>
        <PrimaryButton onClick={handleOTPVerify} loading={loader} title="Reset Password" className="mt-4" />
    </div>
}

export default OTPForget;