import React, { useEffect, useState } from "react";
import { AlertDialogSlide, CategorySection, ContentCompSection, Input, Layout, LayoutHeader, ReminderComp } from "../components";
import { D1, Guide, Logo, Logout } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { imageTypes, optionsData } from "../utils/_dataset";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty, orderBy } from "lodash";
import YouTube from 'react-youtube';
import { useNavigate } from "react-router-dom";

const AboutSection = (props) => {

    const navigate = useNavigate();

    const [dataset, setdataset] = useState([]);
    const [images, setimages] = useState([]);
    const [video, setvideo] = useState([]);
    const [loader, setloader] = useState(false)
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);


    useEffect(() => {
        handleData()
        return () => {

        }
    }, []);

    const handleData = async () => {
        try {
            setloader(true)
            const _getdata = await _call('api/v1/main-category/', 'GET');
            const getCategoryId = _getdata.data?.find(x => x.category_name?.toLowerCase() == "about us")?.id;

            const aboutData = await _call('api/v1/sub-category/?main_category=' + getCategoryId, 'GET');
            const content = aboutData.data[0];
            const _getContent = await _call(`api/v1/sub-category/${content?.id}/`, 'GET');
            handleRenderer(_getContent.data)
            setloader(false)
            setdataset(_getContent.data);

        } catch (err) {
            setloader(false)
        }

    }



    function getFileExtension(file) {
        var regexp = /\.([0-9a-z]+)(?:[\?#]|$)/i;
        var extension = file.match(regexp);
        return extension && extension[1];
    }

    const handleRenderer = (data) => {

        const files = data?.content_media;
        var imagesclone = [];
        var videoclone = [];

        if (!isEmpty(files) && files) {
            files?.map(item => {
                const splitFile = item?.file?.split('?');
                if (splitFile[0]) {
                    const extension = getFileExtension(splitFile[0]);

                    if (imageTypes.includes(extension)) {
                        imagesclone.push({
                            ...item,
                            image: splitFile[0],
                        });
                    } else {
                        videoclone.push({
                            ...item,
                            url: splitFile[0],
                        })
                    }
                }

            });
            setimages(imagesclone);
            setvideo(videoclone);
        }

    }

    return <Layout activeId={3} loading={false}>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 overflow-hidden px-4 lg:px-0">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 overflow-auto">
                    <div className="flex flex-col items-center justify-center mt-10">
                        <Logo />

                        <div className="mt-7 bg-white p-4">
                            <h4 className="font-bold text-xl pb-3 border-b">About Us</h4>
                            <div className="mt-3" dangerouslySetInnerHTML={{ __html: dataset?.content?.replaceAll('\n', '') }} />

                            <div className="w-full mt-4">
                                {images?.map((item, index) => (
                                    <div key={index} className="bg-white p-8 border-b">
                                        <div className="w-16 h-16">
                                            <img src={item.image} alt={`Slide ${index + 1}`} className="w-18 h-18 rounded-full object-contain" />
                                        </div>
                                        <div className="mt-4" dangerouslySetInnerHTML={{ __html: item?.description }} />
                                    </div>
                                ))}

                            </div>

                            {dataset?.url && dataset?.url?.split(',')?.map(item =>
                                <div className="w-full mt-4">
                                    <PlayingVideoComp
                                        url={item} />
                                </div>
                            )}
                        </div>





                    </div>


                </div>
            </div>
        </div>

    </Layout>
}



const PlayingVideoComp = ({ url }) => {
    const [playing, setPlaying] = useState(false);
    const regex = /v=([^&]+)/;
    const match = url.match(regex);
    const contentId = match[0];

    const opts = {
        width: '100%',
        height: 400,
    };

    return (
        <div className="mt-4 w-full">
            <YouTube
                opts={opts}
                play={playing}
                videoId={contentId?.split('v=')[1]}
                onChangeState={state => {
                    if (state == "ended") {
                        setPlaying(false)
                    }
                }}
                contentScale={1.0}
            />
        </div>
    );
}


export default AboutSection;