import React, { useEffect, useState } from "react";
import { AlertDialogSlide, CategorySection, ContentCompSection, Input, Layout, LayoutHeader, ReminderComp } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { optionsData } from "../utils/_dataset";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty, orderBy } from "lodash";
import YouTube from 'react-youtube';
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { Colors } from "../utils/_constant";
import { PrimaryButton } from "../components/_button";
import moment from "moment";

const FAQSection = (props) => {

    const navigate = useNavigate();

    const [loader, setloader] = useState(false);

    const alert = useAlert()

    const [dataset, setdataset] = useState([]);
    const [selected, setselected] = useState([]);

    useEffect(() => {
        handleData()
        return () => { }
    }, []);

    const handleData = async () => {

        try {

            const _getData = await _call('api/v1/faqs/', 'GET');
            setdataset(orderBy(_getData.data, 'sorting_order', 'asc')?.map(item => {
                return { ...item, title: item?.question }
            }));

        } catch (err) {

        }

    }

    return <Layout>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 overflow-hidden px-4 lg:px-0">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                    <LayoutHeader title="FAQ Section" onBack={() => {
                        if (isEmpty(selected)) {
                            navigate(-1)
                            return;
                        }

                        setselected([])
                    }} none />

                    <div className="grid grid-cols-1">
                        {isEmpty(selected) ? dataset?.map((item, index) => <div key={index} onClick={() => setselected(item)} className="bg-white cursor-pointer px-6 flex flex-row items-center justify-between w-full min-h-[8vh] mt-5">
                            <div className="flex flex-row items-center">
                                <div className="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 w-10 h-10 rounded-full flex flex-col items-center">
                                    <h4 className="text-white m-auto block font-bold text-xl">{item?.question[0]}</h4>
                                </div>
                                <h4 className="font-bold text-xl ml-4">{item?.question}</h4>
                            </div>
                            <Arrow />
                        </div>) : <div className="bg-white cursor-pointer p-6 flex flex-col w-full min-h-[8vh] mt-5">
                            <div className="flex flex-row items-center w-full border-b pb-5">
                                <div className="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 w-10 h-10 rounded-full flex flex-col items-center">
                                    <h4 className="text-white m-auto block font-bold text-xl">{selected?.question[0]}</h4>
                                </div>
                                <h4 className="font-bold text-xl ml-4">{selected?.question}</h4>
                            </div>
                            <h4 className="text-black m-auto block text-lg mt-6">{selected?.answer}</h4>
                        </div>}
                    </div>


                </div>
            </div>
        </div>

    </Layout>
}



const PlayingVideoComp = ({ url }) => {
    const [playing, setPlaying] = useState(false);
    const regex = /v=([^&]+)/;
    const match = url.match(regex);
    const contentId = match[0];

    const opts = {
        width: '100%',
        height: 400,
    };

    return (
        <div className="mt-4 w-full">
            <YouTube
                opts={opts}
                play={playing}
                videoId={contentId?.split('v=')[1]}
                onChangeState={state => {
                    if (state == "ended") {
                        setPlaying(false)
                    }
                }}
                contentScale={1.0}
            />
        </div>
    );
}





export default FAQSection;