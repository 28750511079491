import React, { useEffect, useState } from "react";
import { AlertDialogSlide, CategorySection, ChatComp, ContentCompSection, Input, Layout, LayoutHeader, MessageComp, ReminderComp } from "../components";
import { D1, File, Guide, Logo, Send } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { firebaseConfig, optionsData } from "../utils/_dataset";
import { _call, _multipartCall } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty } from "lodash";
import { addDoc, collection, getDocs, limit, onSnapshot, orderBy, query, serverTimestamp, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { getApps, initializeApp } from 'firebase/app';
import moment from "moment";
import { useSelector } from "react-redux";
import { db } from "../utils/_firestore.service";

const InboxCustomer = (props) => {

    const navigate = useNavigate();

    const [loader, setloader] = useState(false);
    const [dataset, setdataset] = useState([]);
    const [selected, setselected] = useState([]);

    const user = useSelector(state => state.user);
    const userState = user.shape;

    useEffect(() => {
        if (getApps().length == 0) {
            initializeApp(firebaseConfig)
        }
        handleData();
    }, []);

    const handleGetLatestMessages = (data) => new Promise(async (resolve, reject) => {
        const collectionRef = collection(db, `chat/${data.id}/messages`);
        var datsa;
        try {
            const q = query(
                collectionRef,
                where('created_at', '<=', new Date()),
                orderBy('created_at', 'desc'),
                limit(1)
            );

            const querySnapshot = await getDocs(q);

            querySnapshot.forEach(documentSnapshot => {
                datsa = { ...documentSnapshot.data() };
                return documentSnapshot.data();
            });

            resolve(datsa);
        } catch (error) {
            console.error(error);
            reject(error)
        }



    });


    const handleData = async () => {
        const role = localStorage.getItem('role')
        try {
            setloader(true)
            const _getmainUserChatInfo = await _call(`api/v1/chat-token/?${role == 'Customer' ? `main_user=${userState.id}` : `chat_user=${userState.id}`}`, 'GET');

            var _shape = [];
            const _dataset = await Promise.all(_getmainUserChatInfo.data.map(async item => {
                try {
                    // console.log(item)
                    const _data = await handleGetLatestMessages(item);

                    if (_data?.created_at) {
                        item.onCreated = new Date(_data.created_at?.seconds * 1000 + _data.created_at?.nanoseconds / 1000000);
                    } else {
                        item.onCreated = new Date()
                    }


                } catch (err) {
                }
                _shape.push({
                    ...item,
                })
            }));

            await Promise.all(_dataset);

            _shape = _shape.sort((a, b) => {
                if (a.onCreated && b.onCreated) {
                    return b.onCreated - a.onCreated;
                } else if (a.onCreated) {
                    return -1;
                } else if (b.onCreated) {
                    return 1;
                } else {
                    return 0;
                }
            });
            setloader(false)
            setdataset(_shape);


        } catch (err) {
            setloader(false)
        }
    }


    const handleOpenChat = async (item, user) => {

        const _data = {
            chatId: item.id,
            mainUser: {
                id: item.main_user_details.id,
                name: item.main_user_details.first_name,
                image: item.main_user_details.profile_picture,
            },
            chatUser: {
                id: item.chat_user_details.id,
                name: item.chat_user_details.first_name,
                image: item.chat_user_details.profile_picture,
            },
            user,
            id: item?.id
        }

        setselected(_data)
    }

    const alert = useAlert()

    return <Layout loading={loader}>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 px-4 lg:px-0 min-h-[100vh] overflow-auto pb-10">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 ">
                    <LayoutHeader onBack={() => navigate(-1)} title="Chat Lists" none />

                    <div className="mt-4 grid lg:grid-cols-6 gap-4">
                        <div className="lg:col-span-2 bg-white">
                            {dataset?.map(item => <ChatComp data={item} selected={selected} onClick={() => {
                                handleOpenChat(item)
                            }} />)}
                        </div>
                        <div className="lg:col-span-4 bg-white h-[80vh]">
                            {!isEmpty(selected) && <Chatting data={selected} />}
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </Layout>
}


const Chatting = ({ data }) => {
    const [dataset, setdataset] = useState([]);
    const [message, setmessage] = useState('');

    const collectionRef = collection(db, `chat/${data.chatId}/messages`);
    const alert = useAlert();

    const user = useSelector(state => state.user);
    const userState = user.shape;

    useEffect(() => {
        handleData();
    }, [data])


    const handleData = async () => {

        const unsubscribe = onSnapshot(
            query(collectionRef, orderBy('created_at', 'asc')),
            (querySnapshot) => {
                const updatedMessages = querySnapshot.docs.map((doc) => ({
                    _id: doc.id,
                    ...doc.data(),
                }));
                setdataset(updatedMessages);
            }
        );

        // Return a cleanup function to unsubscribe when the component unmounts
        return () => unsubscribe();
    }

    const onSend = async () => {
        if (message !== '') {
            setmessage('');
            const newMessage = {
                text_content: message,
                from_user: userState.id,
                created_at: serverTimestamp(),
                currentTime: new Date(),
                deleted: false,
                deletedBy: [],
                type: 0, // 0-> Message 1-> Media 2-> IP
            };

            addDoc(collectionRef, newMessage)
                .then((docRef) => {
                    setmessage('');
                })
                .catch((error) => {
                });

            const _createNotification = await _call('api/v1/send-notification/', 'POST', {
                user_id: data?.mainUser.id == userState.id ? data?.chatUser?.id : data?.mainUser.id,
                title: 'Message Received',
                body: 'You have received a message'
            });
        } else {
            alert.show("Please type some message", { type: 'error' })
        }
    }


    const fileInputRef = React.createRef();


    const handleSuccessImage = async (file, fileName) => new Promise(async (resolve, reject) => {

        var _dataset = [
            { key: 'file_name', value: fileName }
        ];

        _dataset.push({
            key: 'file', value: file
        })

        try {

            const _uploadFile = await _multipartCall('api/v1/chat-documents/', 'POST', _dataset);
            resolve(_uploadFile.data);

        } catch (err) {
        }
    });

    const handleFileSelect = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileName = moment().unix + file.name;
            const fileUpload = await handleSuccessImage(file, fileName)

            const syncFirebase = await handleImageUpload(fileUpload.file, 1, fileUpload.file_name, file?.type?.includes('image') ? 'image' : '')
        }
    };


    const handleImageUpload = (image, type = 1, file = '', mode = '') => new Promise(async (resolve, reject) => {

        const newMessage = {
            text_content: image,
            from_user: userState.id,
            fileName: file,
            created_at: serverTimestamp(),
            mode,
            currentTime: new Date(),
            deleted: false,
            deletedBy: [],
            type: 1
        };

        addDoc(collectionRef, newMessage)
            .then((docRef) => {
                setmessage('');
            })
            .catch((error) => {
            });
    });



    return <>
        <div className="w-full h-[70vh] overflow-auto ">
            <div className="h-16 bg-slate-50 w-full flex flex-row items-center justify-center">
                <img className="w-10 h-10 rounded" src={userState.id == data?.mainUser?.id ? data?.chatUser?.image?.split('?')[0] : data?.mainUser?.image?.split('?')[0]} />
                <h4 className="ml-2">{userState.id == data?.mainUser?.id ? data?.chatUser?.name : data?.mainUser?.name}</h4>
            </div>
            <div className="p-4">
                {dataset?.map(item => {
                    if (item?.deletedBy?.includes(userState.id)) return <></>
                    return <MessageComp type={item?.from_user === userState?.id ? 'right' : 'left'} data={item} />
                })}
            </div>
        </div>
        <div className="mt-2 relative flex flex-row items-center p-4">
            <input value={message} onChange={e => setmessage(e.target.value)} className="rounded-full bg-slate-100 w-full h-14 text-md pl-4" placeholder="Type a message here" />

            <button onClick={() => fileInputRef.current.click()}>
                <img className="absolute right-16 top-8 w-6 h-6" src={File} />
            </button>
            <input
                type="file"
                accept="*"
                ref={fileInputRef}
                onChange={handleFileSelect}
                style={{ display: 'none' }}
            />

            <img onClick={onSend} className="ml-2 w-8 h-8" src={Send} />
        </div>
    </>
}

export default InboxCustomer;