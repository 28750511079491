import React from "react";
import { Logo } from "../assets/icons";
import { ColoredButton, CustomButton, PrimaryButton } from "../components/_button";
import { useNavigate } from "react-router-dom";


const WelcomePage = () => {
    const navigate = useNavigate();

    return <div className="w-full h-[100vh] bg-image">
        <div className="pt-[120px] lg:w-[500px] m-auto overflow-hidden">
            <div className="w-full flex flex-col items-center">
                <Logo />
                <p className="w-[350px] text-center mt-3">Striving to Prevent Obesity, Diabetes and by
                    Promoting Healthy Lifestyles</p>
                <div className="mt-10 bg-gray-100 w-full lg:h-[52vh] rounded px-10 py-5 lg:p-20 shadow-md">
                    <PrimaryButton onClick={() => navigate('/client-register')} title="Sign up" className="" />
                    <CustomButton onClick={() => navigate('/login')} title="Log In" className="mt-4" />
                    <p className="mt-5 text-center">
                        By using myTMJ, you agree to myTMJ <a target="__blank" href='/privacy-policy' className="underline text-[#009DD8]">Privacy Policy</a> and <a target="__blank" href='/terms-condition' className="underline text-[#009DD8]">Terms of Use</a>.
                    </p>
                    <ColoredButton onClick={() => navigate('/specialist-register')} title="Sign Up as specialist" className="mt-10 lg:mt-20
                     bg-[#009DD8] text-white" />
                </div>
            </div>
        </div>
    </div>
}

export default WelcomePage;