import React, { useState } from "react";
import { ContentCompSection, Layout } from "../components";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Calendar } from "../assets/svgs";
import { _call } from "../utils/_service";

const DiagnosisResult = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [selectedCondition, setselectedCondition] = useState([]);

    const getcondition = location.state?.condition || [];
    const data = location.state?.data || [];
    const result = location.state?.result || [];
    const condition = location.state?.conditions || [];


    const handleGetConditionContent = async (item) => {

        try {

            const _getContent = await _call(`api/v1/sub-category/${item.library_sub_category_id}/`, 'GET');

            setselectedCondition({
                data: item,
                content: _getContent.data,
                id: item.library_sub_category_id
            })

        } catch (err) {
        }

    }


    return <Layout>
        <div className="grid grid-cols-12 gap-4 px-4 lg:px-0">
            <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                <div className="w-full bg-orange-400 h-48 rounded flex flex-col items-center justify-center">
                    <Calendar />
                </div>
                <div className="my-10">
                    <h4 className="text-3xl text-red-500 font-bold text-center w-full">Individuals who have these symptoms are likely to have one or more of the following condition(s)</h4>
                </div>

                {getcondition?.conditions?.map((item, index) => {
                    return <div key={index} onClick={() => handleGetConditionContent(item)} className="w-full cursor-pointer bg-white rounded mt-4 p-4"
                        style={{ borderWidth: 1, borderColor: selectedCondition?.id == item?.library_sub_category_id ? '#FF9900' : '#fff' }}
                    >
                        <h4>Condition</h4>
                        <h4 className="text-xl font-bold">{item?.condition_name || item?.name}</h4>
                    </div>
                })}

                {selectedCondition?.content && <ContentCompSection content={selectedCondition?.content} />}

                {condition && <div className="mt-4">
                    <h4>Condition</h4>
                    <h4 className="text-xl font-bold">{condition}</h4>
                </div>}

            </div>
        </div>
    </Layout>
}

export default DiagnosisResult;