import React, { useState } from "react";
import { Back, Calendar, Edit, Search } from "../assets/svgs";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';


const LayoutHeader = ({ title, onBack, onSearching, edit = false, onEdit, calendar, onCalander, reminder, onReminder, none = false }) => {
    const [onSearch, setonSearch] = useState(false);
    const [search, setsearch] = useState('');

    return <div className="flex flex-row items-center border-b pb-2 w-full h-16">
        <Back onClick={onBack} />
        <h4 className="text-[#009DD8] text-xl flex-1 text-center font-medium">{title}</h4>
        {onSearch && <div className="relative">
            <input value={search} onChange={e => setsearch(e.target.value)} className="bg-white rounded-full pl-3 p-2 border focus:outline-none mr-1" placeholder="Search here" />
            <Search onClick={() => onSearching(search)} className="absolute right-8 top-[10px] w-8 h-8" />
            <CloseOutlinedIcon onClick={() => {
                setsearch('')
                onSearching('')
                setonSearch(false)

            }} />
        </div>}
        {!none && <>
            {reminder && <DateRangeIcon className="w-8 h-8 mt-4" onClick={onReminder} />}
            {calendar && !reminder && <AccessTimeIcon className="w-8 h-8 mt-4" onClick={onCalander} />}
            {edit && !calendar && !reminder && <Edit className="w-10 h-10 mt-4" onClick={onEdit} />}
            {!edit && !calendar && !onSearch && !reminder && <Search className="w-10 h-10 mt-4" onClick={() => setonSearch(true)} />}
        </>}
    </div>
}

export default LayoutHeader;