import { combineEpics, Epic } from "redux-observable";
import { switchMap, map, startWith, catchError, filter, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { auth } from "../_urlroutes";
import ClientSlices from "../slices/_client.slices";
import { _call, _multipartCall, _retaincall, _Unauthcall } from "../_service";
import UserSlices from "../slices/_user.slices";

const AuthUrl = auth.USER;
const Action = UserSlices.action;


const CreateEvent = (action$, state$) =>
    action$.pipe(filter(Action.createData.match),
        mergeMap(({ payload }) => from(_Unauthcall(AuthUrl, 'POST', payload)).pipe(
            map(response => {
                return Action.createdData(response.data)
            }),
            startWith(Action.createData),
            catchError(e => {
                return of(Action.onFailed({ error: "Some problem occured" }))
            })
        )));

const GetEvent = (action$, state$) =>
    action$.pipe(filter(Action.LoadData.match),
        mergeMap(({ payload }) => from(_call(AuthUrl, 'GET')).pipe(
            map(response => {
                return Action.LoadedData(response.data.results)
            }),
            startWith(Action.LoadData),
            catchError(e => {

                return of(Action.onFailed({ error: "Some problem occured" }))
            })
        )));

const DeleteEvent = (action$, state$) =>
    action$.pipe(filter(Action.deleteData.match),
        mergeMap(({ payload }) => from(_retaincall(AuthUrl, 'DELETE', payload, true)).pipe(
            map(response => Action.deletedData(response)),
            startWith(Action.deleteData),
            catchError(e => {

                return of(Action.onFailed({ error: "Some problem occured" }))
            })
        )));

const EditEvent = (action$, state$) =>
    action$.pipe(filter(Action.onEditData.match),
        mergeMap(({ payload }) => from(_multipartCall(AuthUrl, 'PATCH', payload)).pipe(
            map(response => Action.onEdittedData(response.data)),
            startWith(Action.onEditData),
            catchError(e => {

                return of(Action.onFailed({ error: "Some problem occured" }))
            })
        )));

export default combineEpics(CreateEvent, EditEvent, DeleteEvent, GetEvent);
