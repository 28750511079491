import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, Outlet, Navigate } from 'react-router-dom'
import { Logo } from "../assets/icons";
import { ColoredButton, CustomButton, PrimaryButton } from "../components/_button";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { _call } from "../utils/_service";
import { isEmpty } from "lodash";

const InformationPage = () => {
    const [dataset, setdataset] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        handleData();
    }, [])

    const handleData = async () => {

        try {
            const _getInformation = await _call('api/v1/information-page/', 'GET', {}, {
                Authorization: 'Token e2830c1766eddb1cd6b412029fc07658aaab2f48'
            });

            setdataset(_getInformation.data);

        } catch (err) {
        }

    }

    return <div className="w-full h-[100vh] bg-slate-100 px-4 lg:px-0">
        <div className="pt-20 lg:pt-[120px] lg:w-[500px] m-auto overflow-hidden">
            <div className="w-full flex flex-col items-center">
                <Logo />
                <div className="mt-4 lg:mt-28" />
                <div className="w-72 lg:!w-full">
                    <Carousel showStatus={false}>
                        {dataset?.map(item => <div className="flex w-full items-center flex-col h-[50vh]">
                            <div className="w-32 h-32">
                                <img src={!isEmpty(item?.information_page_files) && item.information_page_files[0].file?.split('?')[0]} className="rounded-full" />
                            </div>
                            <h4 className="text-xl font-bold mt-4">{item?.title}</h4>
                            <p className="mt-4 text-sm text-gray-400 w-[300px]">{item?.description}</p>
                        </div>)}
                    </Carousel>
                </div>
                <PrimaryButton title="Skip" onClick={() => navigate('/welcome')} />
            </div>
        </div>
    </div>
}

export default InformationPage;