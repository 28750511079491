import React, { useEffect, useState } from "react";
import { CurrentRequest, Layout, LayoutHeader } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { specialistOptionsData } from "../utils/_dataset";
import { SpecialistHeader } from "../assets/svgs";
import { _call } from "../utils/_service";
import { useNavigate } from "react-router-dom";

const RequestSpecialist = (props) => {
    const [dataset, setdataset] = useState([]);
    const [painInfo, setpainInfo] = useState([]);
    const [loader, setloader] = useState(false);

    useEffect(() => {
        handleData();
    }, [])

    const handleData = async () => {

        setloader(true);

        try {
            const _getdata = await _call('api/v1/meeting-request/', 'GET');
            const _getpainInfo = await _call('api/v1/pain-category/', 'GET');
            const _data = _getpainInfo.data;
            setpainInfo(_data);
            const data = _getdata.data;
            setdataset(data);
            setloader(false)

        } catch (err) {
            setloader(false)
        }

    }
    const navigate = useNavigate();
    return <Layout activeId={2} loading={loader}>

        <div className="grid grid-cols-12 gap-4 px-4 lg:px-0">
            <div className="lg:col-start-4 col-span-12 lg:col-span-6 pt-10">
                <LayoutHeader title="Current Requests" none onBack={() => navigate(-1)} />
                <div className="">
                    <div className="grid lg:grid-cols-3 gap-4 mt-3">
                        {dataset?.map((item, index) => <CurrentRequest data={item} key={index} painInfo={painInfo} />)}
                    </div>
                </div>
            </div>
        </div>

    </Layout>
}

export default RequestSpecialist;