import React, { useEffect, useState } from "react";
import { imageTypes } from "../utils/_dataset";
import { isEmpty } from "lodash";

import ReactSimplyCarousel from 'react-simply-carousel';
import VideoSectionComp from "./_video.section";


const ContentCompSection = ({ content }) => {

    const [images, setimages] = useState([]);
    const [video, setvideo] = useState([]);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    useEffect(() => {
        handleRenderer();
    }, [content])

    function getFileExtension(file) {
        var regexp = /\.([0-9a-z]+)(?:[\?#]|$)/i;
        var extension = file.match(regexp);
        return extension && extension[1];
    }

    const handleRenderer = () => {

        const files = content?.content_media;
        var imagesclone = [];
        var videoclone = [];

        if (!isEmpty(files) && files) {
            files?.map(item => {
                const splitFile = item?.file?.split('?');
                if (splitFile[0]) {
                    const extension = getFileExtension(splitFile[0]);

                    if (imageTypes.includes(extension)) {
                        imagesclone.push(splitFile[0]);
                    } else {
                        videoclone.push({
                            url: splitFile[0],
                            ...item
                        })
                    }
                }

            });
            setimages(imagesclone);
            setvideo(videoclone);
        }

    }

    return <div className="py-5 px-5 lg:px-0">
        <ReactSimplyCarousel
            activeSlideIndex={activeSlideIndex}
            onRequestChange={setActiveSlideIndex}
            itemsToShow={1}
            itemsToScroll={1}

            responsiveProps={[
                {
                    itemsToShow: 1,
                    itemsToScroll: 1,
                    minWidth: 768,
                },
            ]}
            speed={400}
            easing="linear"
        >
            {images?.map(item => {
                return <div className="w-[500px] h-[350px] lg:w-[900px] lg:h-[600px]">
                    <img src={item} className="w-full object-contain" />
                </div>
            })}
        </ReactSimplyCarousel>

        <div className="mt-5" dangerouslySetInnerHTML={{ __html: content?.content }} />

        {content?.url?.split(',')?.map(item => <VideoSectionComp
            url={item} />)}
    </div>
}

export default ContentCompSection;