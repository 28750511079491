import React, { useState } from "react";
import { Logo } from "../assets/icons";
import { Input } from "../components";
import { PrimaryButton } from "../components/_button";
import { EmailForget, OTPForget, PasswordForget } from "../components/forget";
import { useNavigate } from "react-router-dom";


const ForgotScene = () => {
    const navigate = useNavigate();

    const [email, setemail] = useState('')
    const [token, settoken] = useState('')
    const [mode, setmode] = useState(0);

    return <div className="w-full h-[100vh] bg-slate-100 px-4 lg:px-0">
        <div className="pt-20 lg:pt-[120px] lg:w-[500px] m-auto overflow-hidden">
            <div className="w-full flex flex-col items-center">
                <Logo />
                {mode == 0 && <EmailForget onClick={e => {
                    setmode(1)
                    setemail(e)
                }} />}
                {mode == 1 && <OTPForget email={email} onClick={tok => {
                    setmode(2)
                    settoken(tok)
                }} onBack={() => setmode(1)} />}
                {mode == 2 && <PasswordForget token={token} email={email} onClick={() => navigate('/login')} />}
            </div>
        </div>
    </div>
}

export default ForgotScene;