import React, { useEffect, useState } from "react";
import { AlertDialogSlide, CategorySection, ChatComp, ContentCompSection, Input, Layout, LayoutHeader, ReminderComp } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { optionsData } from "../utils/_dataset";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty, orderBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { Colors } from "../utils/_constant";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { PrimaryButton } from "../components/_button";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import moment from "moment";
import { useSelector } from "react-redux";

const DocumentSpecialist = (props) => {

    const navigate = useNavigate();
    const [dataset, setdataset] = useState([])
    const [selected, setselected] = useState([])

    const [loader, setloader] = useState(false);

    const user = useSelector(state => state.user);

    const alert = useAlert()

    useEffect(() => {
        handleData();
    }, [])

    const handleData = async () => {

        try {
            setloader(true)
            const sortedData = await composeDocData(user?.shape.id);
            setdataset(sortedData);
            setloader(false)
        } catch (err) {
            setloader(false)
        }
    }


    const composeDocData = async (id) => new Promise(async (resolve, reject) => {

        try {
            const _getmainUserChatInfo = await _call(`api/v1/chat-token/?chat_user=${id}`, 'GET');
            var _shape = [];

            const onPromise = await Promise.all(_getmainUserChatInfo.data?.map(async (item, index) => {
                const childChatInfo = await _call(`api/v1/chat-documents/?user=${item?.main_user_details.id}`, 'GET');
                const recentOne = getRecentOne(childChatInfo.data)
                _shape.push({ ...item, documents: childChatInfo.data, onCreated: recentOne?.created_at });
            }));


            await Promise.resolve(onPromise);
            resolve(orderBy(_shape, 'onCreated', 'desc'));

        } catch (err) {
            reject(err);
        }
    })

    const getRecentOne = (dataSet) => {
        if (isEmpty(dataSet)) return {};
        const mostRecentItem = dataSet.reduce((prev, current) => {
            const prevDate = new Date(prev.created_at);
            const currentDate = new Date(current.created_at);
            return currentDate > prevDate ? current : prev;
        });

        return mostRecentItem;
    }


    const handleDownload = (item) => {
        const url = item?.file?.split('?')[0]; // Replace with the URL of your file
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '__blank';
        anchor.download = item?.file_name; // Specify the desired file name
        anchor.click();
    };


    return <Layout loading={loader}>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 px-4 lg:px-0">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                    <LayoutHeader title="Document Lists" onBack={() => navigate(-1)} none />

                    <div className="mt-4 grid grid-cols-6 gap-4">
                        <div className="col-span-6 lg:col-span-2 bg-white min-h-[80vh]">
                            {dataset?.map(item => <ChatComp selected={selected} data={item} onClick={() => setselected(item)} />)}
                        </div>
                        {!isEmpty(selected) && <div className="col-span-6 lg:col-span-4 p-4 bg-white min-h-[80vh]">
                            <h4>Documents</h4>
                            <hr className="my-4" />
                            {selected?.documents?.map((item, index) => {
                                return <div onClick={() => handleDownload(item)} className="bg-slate-50 border p-3 flex flex-row mt-4 rounded cursor-pointer">
                                    <AttachFileIcon className="text-orange-500" />
                                    <h4 className="text-md flex-1 mx-2">{item?.file_name}</h4>
                                    <FileDownloadIcon />
                                </div>
                            })}

                        </div>}
                    </div>

                </div>
            </div>
        </div>

    </Layout>
}

export default DocumentSpecialist;