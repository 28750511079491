import React, { useState } from "react";
import { Colors } from "../utils/_constant";
import { Filter, Location } from "../assets/svgs";
import InMenu from "./_inmenu";

const FilterSpecialistElement = ({ options = [], onChange, locationState, onLocationClick }) => {
    const [specialistType, setspecialistType] = useState({
        name: 'All Specialist'
    })


    return <div className="flex flex-row items-center">
        <div className="bg-white flex flex-row px-4 h-14 items-center justify-between flex-1">
            <div className="flex flex-row items-center">
                <h4>Filter by: </h4>
                <h4 className={`ml-2 text-[${Colors.primary}]`}>{specialistType?.name}</h4>
            </div>
            <InMenu onChange={data => {
                onChange(data);
                setspecialistType(data)
            }} children={<Filter className="mr-[-15px] w-6 h-6" />} options={options} />
        </div>
        <div onClick={onLocationClick} className={`h-14 cursor-pointer w-14 ml-5 flex flex-col items-center justify-center rounded ${locationState ? `bg-[${Colors.primary}]` : 'bg-black'}`}>
            <Location />
        </div>
    </div>
}

export default FilterSpecialistElement;