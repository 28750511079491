import React from "react";
import ReactLoading from 'react-loading'
import Header from "./_header";
import { Colors } from "../utils/_constant";
import Notification from "../utils/_notification";

const Layout = ({ children, activeId, loading }) => {
    const token = localStorage.getItem('token');
    return <div className="w-full min-h-[100vh] bg-[#F5F7FB]">
        {token && <Header activeId={activeId} />}
        {loading ?
            <div className="w-full min-h-[100vh] flex flex-col items-center justify-center">
                <ReactLoading type={'cubes'} color={Colors.primary} height={80} width={100} />
            </div>
            : <>
                <Notification />
                {children}
            </>}
    </div>
}

export default Layout;