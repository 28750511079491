import axios from 'axios';
import _, { isEmpty } from 'lodash';
import { url } from './_constant';

axios.defaults.baseURL = url;

const getToken = async () => {
    return await localStorage.getItem('token');
}

const _call = (url, method, data = {}, headers = null) => new Promise(async (resolve, reject) => {

    const token = await getToken();

    var headerObj = {
        Authorization: 'Token ' + token,
    }

    if (headers) {
        headerObj = headers;
    }

    var params = {
        method,
        url,
        headers: { ...headerObj, "x-forwarded-proto": "https" }
    };

    if (!isEmpty(data)) {
        params.data = data;
    }

    axios(params).then(res => resolve(res)).catch(err => reject(err));
});


const _Unauthcall = (url, method, data = {}, headers = null) => new Promise(async (resolve, reject) => {
    // "x-forwarded-proto": "https" 
    var params = {
        method,
        url,
        headers: { ...headers, "x-forwarded-proto": "https" }
    };

    if (!isEmpty(data)) {
        params.data = data;
    }

    axios(params).then(res => resolve(res)).catch(err => reject(err));
});


const _multipartCall = (url, method, data) => new Promise(async (resolve, reject) => {

    const token = await getToken();

    const fd = new FormData();

    data.map((item, index) => {
        fd.append(item.key, item.value);
    });

    var params = {
        method,
        url,
        headers: {
            Authorization: 'Token ' + token,
            "Content-Type": 'multipart/form-data',
            "x-forwarded-proto": "https"
        },
        data: fd
    }


    axios(params).then(res => resolve(res)).catch(err => reject(err));
});



const _retaincall = (url, method, data = {}, exceptional = false) => new Promise(async (resolve, reject) => {

    const token = await getToken();

    var headerObj = {
        Authorization: 'Token ' + token,
    }

    // "x-forwarded-proto": "https"
    var params = {
        method,
        url,
        headers: { ...headerObj, "x-forwarded-proto": "https" },
        withCredentials: false
    };

    params['data'] = { ...data?.data };

    axios(params).then(res => {
        if (exceptional) {
            resolve({
                data: res.data,
                params: data
            })
        } else {
            resolve(res);
        }
    }).catch(err => reject(err));
});


export { _call, _multipartCall, _retaincall, _Unauthcall };