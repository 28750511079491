import React, { useEffect, useState } from "react";
import { ContentCompSection, DiagnosisComp, Input, Layout, LayoutHeader, Tabs } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ImagePicker } from 'react-file-picker'
import { _call, _multipartCall } from "../utils/_service";
import { Colors, googleAPIKey } from "../utils/_constant";
import SpecialistAreaSlices from "../utils/slices/_specialistarea.slices";
import { useAlert } from "react-alert";
import { isEmpty, omit } from "lodash";
import moment from "moment";
import { __customerProfileTabs, genderData, heightData, weightData } from "../utils/_dataset";
import UserSlices from "../utils/slices/_user.slices";
import { getAddressInfo } from "../utils/_function";
import Geocode from 'react-geocode'
import { CheckBox } from "@mui/icons-material";
import axios from "axios";

const CustomerProfile = (props) => {


    const dispatch = useDispatch();
    const location = useLocation();

    const [dataset, setdataset] = useState([])
    const [loader, setloader] = useState(false);
    const [toggle, settoggle] = useState(false);
    const [image, setimage] = useState();
    const [userInfo, setuserInfo] = useState([]);
    const userState = useSelector(state => state.user);

    const profileActions = UserSlices.action;
    const alert = useAlert()

    useEffect(() => {
        const user = userState?.shape;
        setuserInfo({ ...user, clientprofile: { ...user.clientprofile, gender: user.clientprofile?.gender == 1 ? 'Male' : user.clientprofile?.gender == 0 ? 'Female' : 'Other' } });
        getUserLocation();
    }, [])


    async function success(pos) {
        var crd = pos.coords;
        // handleChange(e.target.value, 'height', 2)
        const { latitude, longitude } = crd;
        try {
            const getUserAddress = await axios.get('https://maps.googleapis.com/maps/api/geocode/json?key=' + googleAPIKey + '&latlng=' + `${latitude},${longitude}`);
            handleChange(getUserAddress?.data?.results[0]?.formatted_address, 'location', 2)
        } catch (err) {
        }
    }

    const getUserLocation = () => {
        if (navigator.geolocation && !userState?.shape?.clientprofile?.location) {
            navigator.permissions
                .query({ name: "geolocation" })
                .then(function (result) {
                    if (result.state === "granted") {
                        navigator.geolocation.getCurrentPosition(success);
                    } else if (result.state === "prompt") {
                    } else if (result.state === "denied") {
                    }
                    result.onchange = function () { };
                });
        }
    }


    useEffect(() => {
    }, [userState])


    const handleChange = (value, field, type) => {
        var __userInfo = { ...userInfo };


        if (type == 1) {
            __userInfo[field] = value
        } else {
            __userInfo = { ...__userInfo, clientprofile: { ...__userInfo?.clientprofile, [field]: value } }
        }

        setuserInfo(__userInfo)
    }

    const handleEdit = async () => {

        try {
            var lat, lng;
            if (userInfo?.clientprofile?.location) {
                const getLatLng = await handleGeoCode(userInfo?.clientprofile?.location);
                lat = getLatLng.lat;
                lng = getLatLng.lng;
            }


            var _dataMultipart = [
                { key: 'first_name', value: userInfo.first_name },
                { key: 'last_name', value: userInfo.last_name },
                { key: 'clientprofile.dob', value: moment(userInfo.clientprofile.dob).format('YYYY-MM-DD') },
                { key: 'clientprofile.weight', value: userInfo?.clientprofile.weight },
                { key: 'clientprofile.height', value: userInfo?.clientprofile.height },
                { key: 'clientprofile.gender', value: userInfo?.clientprofile.gender === 'Male' ? 1 : 0 },
                { key: 'clientprofile.location', value: userInfo?.clientprofile?.location },
                { key: 'clientprofile.timezone', value: (new Date()).toString().match(/\(([^\)]+)\)$/)[1] },
                { key: 'latitude', value: lat },
                { key: 'longitude', value: lng },
            ];

            if (image) {
                _dataMultipart.push({
                    key: 'profile_picture', value: image
                });
            }


            const _updateProfileInfo = await _multipartCall('rest-auth/user/', 'PATCH', _dataMultipart);

            // console.log({
            //     update:  },
            //     previous: userState?.shape?.user,
            //     userInfo
            // })
            dispatch(profileActions?.onEdittedData(_updateProfileInfo.data))

            setloader(false);
            alert.show('Profile updated successfully!', {
                type: 'success'
            });

        } catch (err) {
            setloader(false)
            alert.show('Some problem occured!', {
                type: 'error'
            });
        }

        settoggle(false)
    }

    const fileInputRef = React.createRef();

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
            setimage(file);
        }
    };

    Geocode.setApiKey(googleAPIKey);

    const handleGeoCode = async (destination) => new Promise(async (resolve, reject) => {
        Geocode.fromAddress(destination).then((response) => {
            const { lat, lng } = response.results[0].geometry.location;
            resolve({ lat, lng })
        },
            (error) => {
                reject('false')
            }
        );
    });

    const onGoogleAutoComplete = async (detail) => {
        handleChange(detail?.formatted_address, 'location', 2)
        // setuserInfo({ ...userInfo, clientprofile: { ...userInfo.clientprofile, location: detail?.formatted_address } })
    }

    const navigate = useNavigate();

    const [activeTab, setactiveTab] = useState(0)

    return <Layout>
        <div className="grid grid-cols-12 gap-4 overflow-hidden px-4 lg:px-0">
            <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto ">
                <LayoutHeader title="Profile" onBack={() => navigate(-1)} edit onEdit={() => {
                    if (!toggle) {
                        settoggle(!toggle)
                    } else {
                        handleEdit()
                    }
                }} />


                <div className="mt-4 bg-white w-full min-h-[70vh] p-2 lg:p-10">
                    <div className="p-10 bg-slate-100">
                        <button onClick={() => fileInputRef.current.click()} className="w-44 h-44 rounded-full m-auto block bg-slate-50">
                            <img src={image ? URL?.createObjectURL(image) : userInfo?.profile_picture?.split('?')[0]} className="w-44 h-44 rounded-full" />
                        </button>
                        <input
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            onChange={handleFileSelect}
                            style={{ display: 'none' }}
                        />
                        <div className="mt-10" />
                        <Tabs data={__customerProfileTabs} currentTab={activeTab} setCurrentTab={setactiveTab} center />
                    </div>
                    <div className="px-10 py-5">
                        {activeTab == 0 ? <>
                            <div className="border-b p-4 flex flex-row items-center justify-start">
                                <h4 className="flex-1">First Name</h4>
                                {toggle ? <div className="flex-1">
                                    <Input value={userInfo?.first_name} onChange={e => handleChange(e.target.value, 'first_name', 1)} />
                                </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.first_name}</h4>}

                            </div>
                            <div className="border-b p-4 flex flex-row items-center justify-start">
                                <h4 className="flex-1">Last Name</h4>
                                {toggle ? <div className="flex-1">
                                    <Input value={userInfo?.last_name} onChange={e => handleChange(e.target.value, 'last_name', 1)} />
                                </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.last_name}</h4>}
                            </div>

                            <div className="border-b p-4 flex flex-row items-center justify-start">
                                <h4 className="flex-1">Date of Birth</h4>
                                {toggle ? <div className="flex-1">
                                    <Input type="datepicker" value={userInfo?.clientprofile?.dob} onChange={e => handleChange(e.target.value, 'dob', 2)} />
                                </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{moment(userInfo?.clientprofile?.dob).format('DD-MM-YYYY')}</h4>}
                            </div>

                            <div className="border-b p-4 flex flex-row items-center justify-start">
                                <h4 className="flex-1">Gender</h4>
                                {toggle ? <div className="flex-1">
                                    <Input options={genderData?.map(item => item?.name)} type="select"
                                        value={userInfo?.clientprofile?.gender}
                                        onChange={e => handleChange(e.target.value, 'gender', 2)} />
                                </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.clientprofile?.gender}</h4>}
                            </div>

                            <div className="border-b p-4 flex flex-row items-center justify-start">
                                <h4 className="flex-1">Weight</h4>
                                {toggle ? <div className="flex-1">
                                    <Input options={weightData} type="select" value={userInfo?.clientprofile?.weight} onChange={e => handleChange(e.target.value, 'weight', 2)} />
                                </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.clientprofile?.weight}</h4>}
                            </div>

                            <div className="border-b p-4 flex flex-row items-center justify-start">
                                <h4 className="flex-1">Height</h4>
                                {toggle ? <div className="flex-1">
                                    <Input options={heightData} type="select" value={userInfo?.clientprofile?.height} onChange={e => handleChange(e.target.value, 'height', 2)} />
                                </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.clientprofile?.height}</h4>}
                            </div>

                        </> : <>
                            <div className="border-b p-4 flex flex-row items-center justify-start">
                                <h4 className="flex-1">Location</h4>
                                {toggle ? <div className="flex-1">
                                    <Input placeholder="Address" type="google" onChange={onGoogleAutoComplete} />
                                </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.clientprofile?.location}</h4>}
                            </div>

                            <div className="border-b p-4 flex flex-row items-center justify-start">
                                <h4 className="flex-1">Timezone</h4>
                                <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.clientprofile?.timezone || (new Date()).toString().match(/\(([^\)]+)\)$/)[1]}</h4>
                            </div>

                            {/* <div className="border-b p-4 flex flex-row items-center justify-start">
                                <h4 className="flex-1">Push Notification</h4>
                                <h4 className={`flex-1 text-[${Colors.primary}]`}>
                                    <Input type="checkbox" value={userInfo?.clientprofile?.push_notification}
                                        onChange={() => handleChange(!userInfo?.clientprofile?.push_notification, 'push_notification', 2)}
                                        title={userInfo?.clientprofile?.push_notification ? "Enabled" : "Disabled"} />
                                </h4>
                            </div> */}



                        </>}
                    </div>

                </div>


            </div>
        </div>
    </Layout >
}

export default CustomerProfile;