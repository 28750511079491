import React, { useEffect, useState } from "react";
import { ContentCompSection, DiagnosisComp, Layout, LayoutHeader } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Calendar } from "../assets/svgs";
import { _call } from "../utils/_service";

const MyDiagnosis = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [dataset, setdataset] = useState([])
    const [alldata, setalldata] = useState([])
    const [painInfo, setpainInfo] = useState([])
    const [loader, setloader] = useState(false);

    const userState = useSelector(state => state.user);

    const user = userState?.shape;
    // + `?created_by=${user.id}``
    useEffect(() => {
        handleGetPainCategory();
    }, [])

    const handleGetPainCategory = async () => {
        try {
            setloader(true);
            const _getpainInfo = await _call('api/v1/pain-category/', 'GET');
            const _get = await _call(`api/v1/diagnose/`, 'GET');
            const painInfo = _getpainInfo.data;
            setpainInfo(painInfo);
            setdataset(_get?.data?.results)
            setalldata(_get?.data?.results)
            setloader(false);

        } catch (err) {
            setloader(false);
        }
    }


    const handleSearch = (value) => {
        var __data = [...alldata];
        __data = __data.filter(x => x?.first_name?.toLowerCase()?.includes(value))
        setdataset(__data)
    }

    const navigate = useNavigate();

    return <Layout loading={loader}>
        <div className="grid grid-cols-12 gap-4 overflow-hidden px-4 lg:px-0">
            <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                <LayoutHeader title="Diagnosis Section" onSearching={handleSearch} onBack={() => navigate(-1)} />

                <div className="grid lg:grid-cols-3 gap-4 my-4">
                    {dataset?.map((item, index) => <DiagnosisComp key={index} index={index} data={item} />)}
                </div>
            </div>
        </div>
    </Layout>
}

export default MyDiagnosis;