import React, { useState } from "react";
import { Logo } from "../assets/icons";
import { SpecialistRegisterDetail, SpecialistRegisterSection } from "../components/specialist";
import { omit } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SpecialistSlices from "../utils/slices/_specialist.slices";
import { useAlert } from "react-alert";
import { _call } from "../utils/_service";
import { auth } from "../utils/_urlroutes";


const SpecialistRegister = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [event, setevent] = useState(0);
    const [initData, setinitData] = useState([]);
    const [execData, setexecData] = useState([]);
    const [loader, setloader] = useState(false);

    const specialistState = useSelector(state => state.specialist_register);
    const __action = SpecialistSlices.action;

    const alert = useAlert();

    const handleRegister = async (data) => {

        const dataset = {
            first_name: initData.first_name,
            last_name: initData.last_name,
            email: initData.email,
            password: initData.pass,
            specialistprofile: {
                license_state: initData.license_state,
                ...data?.specialistprofile
            }
        }

        try {
            setloader(true)
            const __createAccount = _call(auth.SPECIALIST_SIGNUP, 'POST', dataset);
            alert.show("We are currently reviewing your profile and will get back to you shortly!", {
                type: 'success'
            });
            setloader(false);
            navigate('/login')

        } catch (err) {
            alert.show("Some problem occured!", {
                type: 'error'
            });
            setloader(false);
        }
        // console.log(dataset)
        // return;
        // dispatch(__action.createData(dataset))

        // setTimeout(() => {
        //     alert.show("We are currently reviewing your profile and will get back to you shortly!", {
        //         type: 'success'
        //     });

        //     // navigate('/login')
        // }, 3000)


    }

    return <div className="w-full h-[100vh] bg-slate-100 px-4 lg:px-0">
        <div className="pt-20 lg:pt-[120px] lg:w-[500px] m-auto overflow-hidden">
            <div className="w-full flex flex-col items-center">
                <Logo />
                {event == 0 ? <SpecialistRegisterSection onRegister={data => {
                    setinitData(data);
                    setevent(1)
                }} /> : <SpecialistRegisterDetail loader={loader} onRegister={data => {
                    setexecData(data);
                    setevent(2)
                    handleRegister(data)
                }} />}
            </div>
        </div>
    </div>
}

export default SpecialistRegister;