import React, { useEffect, useState } from "react";
import { Logo } from "../assets/icons";
import { Input } from "../components";
import { Colors } from "../utils/_constant";
import { PrimaryButton } from "../components/_button";
import { Line } from "../assets/svgs";
import { SocialLogins, __initialLogin } from "../utils/_dataset";
import RegisterValidation from "../utils/validator/_auth";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from 'react-alert'
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import UserSlices from '../utils/slices/_user.slices'
import { _Unauthcall, _call } from "../utils/_service";
import { auth } from "../utils/_urlroutes";
import { fireAuth } from "../utils/_firebase.service";
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";

// abdul@mailinator.com
// usama.specialist@M

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const alert = useAlert();

    const [loader, setloader] = useState(false);
    const [socialAuth, setsocialAuth] = useState([]);
    const __action = UserSlices.action;

    const formik = useFormik({
        initialValues: __initialLogin,
        validationSchema: RegisterValidation.login,
        onSubmit: values => onLogin(values)
    })

    const googleProvider = new GoogleAuthProvider();
    const facebookProvider = new FacebookAuthProvider();
    const appleProvider = new OAuthProvider('apple.com');

    // provider.setCustomParameters({
    //     prompt: "select_account "
    // });


    const onLogin = async (values) => {

        try {
            setloader(true)
            const __login = await _Unauthcall(auth.LOGIN, 'POST', {
                username: values.email,
                password: values.password
            });

            const data = __login.data;
            setloader(false)

            if (data.message) {
                alert.show(data.message, {
                    type: 'info'
                })
                return;
            }

            localStorage.setItem('token', data?.token);
            localStorage?.setItem('role', data?.user?.clientprofile ? 'Customer' : 'Specialist')
            // console.log(data)
            // return;
            dispatch(__action.createdData(data?.user))
            navigate(data?.user?.clientprofile ? '/customer' : '/specialist')

        } catch (err) {
            setloader(false)
            alert.show('Please use valid credentials', {
                type: 'error'
            })
        }
    }

    useEffect(() => {
        // const unsubscribe = fireAuth.onAuthStateChanged((user) => {
        //     if (user) {
        //         const uid = user.uid;
        //         onSocialAuth(uid)
        //     } else {
        //     }
        // });

        // // Cleanup the listener when the component unmounts
        // return () => unsubscribe();
    }, []);

    const onSocialAuth = async (uid) => {
        try {
            const __getSocial = await handleSocialPromise(uid)
            const data = __getSocial.data;

            if (data?.message) {
                alert.show(data?.message, {
                    type: 'info'
                })
                return;
            }

            localStorage.setItem('token', data?.token);
            localStorage?.setItem('role', data?.user?.clientprofile ? 'Customer' : 'Specialist')

            dispatch(__action.createdData({ ...data?.user }))
            navigate(data?.user?.clientprofile ? '/customer' : '/specialist')
        } catch (err) {
        }
    }

    const handleSocialPromise = async (uid) => new Promise(async (resolve, reject) => {
        try {

            const _dataset = {
                user_uid: uid
            }

            const _request = await _call('api/v1/firebase-social/', 'POST', _dataset, {
                'Content-Type': 'application/json'
            });
            resolve(_request.data);
        } catch (err) {
            reject(err);
        }
    })

    const onSocialLogin = async (item) => {
        var __result = []
        try {
            if (item?.name == 'google') {
                __result = await signInWithPopup(fireAuth, googleProvider);
            } else if (item?.name == 'facebook') {
                __result = await signInWithPopup(fireAuth, facebookProvider);
            } else {
                __result = await signInWithPopup(fireAuth, appleProvider);
            }

            await onSocialAuth(__result?.user?.uid)
        } catch (err) {
        }
    }

    const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = formik;

    return <div className="w-full h-[100vh] bg-slate-100 px-4 lg:px-0">
        <div className="pt-20 lg:pt-[120px] lg:w-[500px] m-auto overflow-hidden">
            <div className="w-full flex flex-col items-center">
                <Logo />
                <div className="bg-white w-full min-h-[300px] mt-10 py-6 px-5 lg:px-16 flex flex-col items-center justify-center">
                    <h4 className="font-bold text-xl">Log Into Account</h4>
                    <div className="mt-2" />
                    <Input placeholder="Email" type="email" value={values?.email} onChange={e => setFieldValue('email', e.target.value)} error={(errors?.email && touched?.email) && errors?.email} />
                    <Input placeholder="Password" type="password" value={values?.password} onChange={e => setFieldValue('password', e.target.value)} error={(errors?.password && touched?.password) && errors?.password} />
                    <div className="flex flex-row justify-end w-full mt-2">
                        <button onClick={() => navigate('/forgot-password')} style={{ color: Colors.primary }} >Forgot Password?</button>
                    </div>
                    <PrimaryButton type="submit" loading={loader} onClick={handleSubmit} title="Log In" className="mt-4" />
                    <div className="my-6 flex flex-row items-center">
                        <Line />
                        <h4 className="font-thin text-slate-400 text-sm">Or you can use</h4>
                        <Line />
                    </div>
                    <div className="flex flex-row items-center justify-around w-full my-3">
                        {SocialLogins?.map(item => <button onClick={() => onSocialLogin(item)}>
                            <img className="w-20" src={item.Icon} />
                        </button>)}
                    </div>
                    <div onClick={() => navigate('/client-register')} className="cursor-pointer mt-4">
                        <h4>Don’t have an account? <span style={{ color: Colors.primary }}>Sign Up</span></h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Login;