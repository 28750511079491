import React, { useEffect, useState } from "react";
import { AlertDialogSlide, CategorySection, ContentCompSection, Input, Layout, LayoutHeader, ReminderComp } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { optionsData } from "../utils/_dataset";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty, orderBy } from "lodash";
import YouTube from 'react-youtube';
import { useLocation, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { Colors } from "../utils/_constant";
import { PrimaryButton } from "../components/_button";
import moment from "moment";
import UserSlices from "../utils/slices/_user.slices";
import { useDispatch, useSelector } from "react-redux";

const __action = UserSlices.action;

const DiagnosisPicture = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const userState = user?.shape;

    const [loader, setloader] = useState(false);

    const alert = useAlert()

    const data = location.state?.data || [];
    const informationsection = location.state?.informationsection || [];
    const diagnosisCheckId = location.state?.diagnosisCheckId || 0;

    const [dataset, setdataset] = useState([]);
    const [processSelection, setprocessSelection] = useState([]);
    const [selected, setselected] = useState([]);
    const [selectedPicture, setselectedPicture] = useState([]);
    const [imagesSet, setimagesSet] = useState([]);
    const [mode, setmode] = useState(false);
    const [renderNext, setrenderNext] = useState(false);


    useEffect(() => {
        handleData();
    }, []);

    const handleData = async () => {

        try {
            setloader(true);
            // https://tiny-band-34533.botics.co/
            const _getdata = await _call(`api/v1/situation/?pain_category=7`, 'GET');
            const _data = _getdata.data;
            setdataset(_data);
            setmode(false);
            setselectedPicture(false);
            setloader(false);

        } catch (err) {
            setloader(false);
        }


    }

    const handleSelectedOne = async (item) => {
        setselected(item)
        setrenderNext(true)
    }

    useEffect(() => {
        if (renderNext) {
            handleNext()
        }
    }, [renderNext]);

    const handleNext = async () => {
        try {
            if (selected.has_child) {
                const _getNext = await _call(`api/v1/situation/?pain_category=7&parent_situation=${selected.id}`, 'GET');
                setdataset(_getNext.data);
            } else {
                setmode(true);
                const _getPictures = await _call(`api/v1/picture-question/?pain_category=7&situation=${selected.id}`, 'GET');
                setimagesSet(_getPictures.data);
            }
            setrenderNext(false)
        } catch (err) {
        }
    }

    const handleSubmit = async (item) => {
        try {
            setloader(true);

            const _data = {
                first_name: informationsection.first_name,
                last_name: informationsection.last_name,
                dob: moment(informationsection.date_of_birth).format('YYYY-MM-DD'),
                weight: informationsection.weight,
                height: informationsection.height,
                gender: informationsection.gender != "Male" ? 0 : 1,
                diagnosis_for: diagnosisCheckId,
                pain_category: 7
            }

            const _userInfo = {
                first_name: informationsection.first_name,
                last_name: informationsection.last_name,
                clientprofile: {
                    dob: moment(informationsection.date_of_birth).format('YYYY-MM-DD'),
                    weight: informationsection.weight,
                    height: informationsection.height,
                    gender: informationsection.gender != "Male" ? 0 : 1,
                }
            }

            if (diagnosisCheckId == 0) {
                const _updateProfileInfo = await _call('rest-auth/user/', 'PATCH', _userInfo);
                dispatch(__action.onEdittedData(_updateProfileInfo.data))
            }
            const _creatediagnosis = await _call('api/v1/diagnose/', 'POST', _data);
            const _dataset = {
                "diagnose": _creatediagnosis.data.id,
                "picture_diagnose": item.id
            }
            const _sendRequest = await _call('api/v1/diagnose-condition/', 'POST', _dataset);
            navigate('/diagnosis-result', {
                state: {
                    condition: _sendRequest.data
                }
            })
            setloader(false);
        } catch (err) {
            setloader(false);
        }
    }



    return <Layout loading={loader}>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 px-4 lg:px-0">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                    <LayoutHeader title="Diagnosis Picture Based" none onBack={() => navigate(-1)} />

                    <div className="grid grid-cols-1 gap-4">
                        {!mode && <>
                            {dataset.map(item => {
                                return (
                                    <button
                                        className="w-full min-h-[50px] p-3 mt-4 rounded bg-white"
                                        style={{
                                            minHeight: 65, alignItems: 'flex-start', alignSelf: 'center',
                                            backgroundColor: selected === item ? '#029ED8' : '#fff'
                                        }}
                                        onClick={() => handleSelectedOne(item)}>
                                        <h4 style={{ color: selected === item ? '#fff' : '#000' }}>{item.name}</h4>
                                    </button>

                                );
                            })}
                        </>}
                        {mode && <>
                            {imagesSet.map(item => <button
                                onClick={() => handleSubmit(item)}
                                className="w-full min-h-[50px] p-3 rounded bg-white mt-4"
                                style={{ backgroundColor: '#fff', borderRadius: 10, borderWidth: 3, borderColor: selectedPicture == item ? '#00AEEF' : '#fff' }}>
                                <img className="rounded" src={item.image?.split('?')[0]} />
                            </button>)}
                        </>}
                    </div>


                </div>
            </div>
        </div>

    </Layout>
}

export default DiagnosisPicture;