import { createSlice } from "@reduxjs/toolkit";
import { _apiStatus, _initialState } from "./_initial";


const _clientRegistrationSlices = createSlice({
    name: "client_register",
    initialState: _initialState,
    reducers: {
        LoadData: state => {
            state.loadingStatus = _apiStatus.Loading;
            state.error = [];
        },
        LoadedData: (state, action) => {
            state.loadingStatus = _apiStatus.Loaded;
            state.shape = [...action.payload];
            state.error = [];
        },
        createData: (state, action) => {
            state.loadingStatus = _apiStatus.Loading;
            state.error = [];
        },
        createdData: (state, { payload }) => {
            state.loadingStatus = _apiStatus.Loaded;
            state.shape = [payload];
            state.error = [];
            state.current = payload
        },
        deleteData: (state, { payload }) => {
            state.loadingStatus = _apiStatus.Loading;
            state.error = [];
        },
        deletedData: (state, { payload }) => {
            state.loadingStatus = _apiStatus.Loaded;
            const id = payload.params.id;
            state.shape = state.shape.filter(x => x.id != id)
        },
        onEditData: (state, action) => {
            state.error = [];
            state.success = false;
        },
        onEdittedData: (state, action) => {
            state.error = [];
            const Index = state.shape?.findIndex(x => x.id == action.payload.id);
            state.shape[Index] = { ...state.shape[Index], ...action.payload };
            state.success = true;
        },
        onFailed: (state, action) => {
            state.loadingStatus = _apiStatus.Failed;
            state.error = action.payload;
            state.success = _apiStatus.Failed;
        },
        onClean: state => {
            state.error = [];
            state.shape = [];
        }
    }
});

const ClientSlices = {
    action: _clientRegistrationSlices.actions,
    reducer: _clientRegistrationSlices.reducer
}

export default ClientSlices;