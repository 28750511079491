import React, { useCallback, useEffect, useState } from "react";
import ReactPlayer from 'react-player'



const VideoSectionComp = ({ title, description, file, url, videos = false, customStyle, videoCustomStyle }) => {
    if (!url || url == '') return <></>

    return (
        <ReactPlayer url={url} />
    );
}

export default VideoSectionComp;