import React, { useEffect, useState } from "react";
import { AlertDialogSlide, CategorySection, ContentCompSection, Input, Layout, LayoutHeader, ReminderComp } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { customerMore, optionsData, specialistMore } from "../utils/_dataset";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from "moment";
import UserSlices from "../utils/slices/_user.slices";
import { useDispatch } from "react-redux";

const MoreSection = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const role = localStorage.getItem('role')
    const [dataset, setdataset] = useState(role == 'Specialist' ? specialistMore : customerMore)

    const __action = UserSlices.action;

    const alert = useAlert()

    const onEvent = async (item) => {
        if (item?.id == 8) {
            localStorage.removeItem('role')
            localStorage.removeItem('token')
            dispatch(__action.onEdittedData({}));
            navigate('/login')
        } else {
            confirmAlert({
                title: 'Confirm to delete',
                message: 'Are you sure to delete account?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => onDelete()
                    },
                    {
                        label: 'No',
                        onClick: () => console.log('Click No')
                    }
                ]
            });

        }
    }

    const onDelete = async () => {
        try {
            const _deleteAccount = await _call('api/v1/delete-account/', 'DELETE');
            localStorage.removeItem('role')
            localStorage.removeItem('token')
            navigate('/login')
        } catch (err) {
        }
    }

    return <Layout activeId={3}>
        <div className="xs:p-4 px-4 lg:px-0">
            <div className="grid grid-cols-12 gap-4 overflow-hidden">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 px-2 overflow-auto">
                    {dataset?.map(item => <div onClick={() => item?.route ? navigate(item?.route) : onEvent(item)} className="bg-white w-full h-12 mt-4 rounded-lg shadow flex flex-row items-center p-5">
                        <img src={item?.icon} className="w-6 h-6 object-contain mr-4" />
                        <h4>{item?.name}</h4>
                    </div>)}

                </div>
            </div>
        </div>

    </Layout>
}

export default MoreSection;