import React, { useState, useEffect } from 'react';
import { Toaster, toast } from 'react-hot-toast';
// import { onMessageListener, requestPermission } from './_function';
import { _call } from './_service';

function Notification() {
    const [notification, setNotification] = useState({ title: '', body: '' });
    // useEffect(() => {
    //     const _dataset = requestPermission();
    //     const fcmToken = localStorage.getItem('fcmToken');

    //     if (_dataset?.token != fcmToken && _dataset?.token) {
    //         updateToken(_dataset?.token)
    //     }

    //     const unsubscribe = onMessageListener().then((payload) => {
    //         setNotification({
    //             title: payload?.notification?.title,
    //             body: payload?.notification?.body,
    //         });
    //         toast.success(`${payload?.notification?.title}: ${payload?.notification?.body}`, {
    //             duration: 60000,
    //             position: 'top-right',
    //         });
    //     });
    //     return () => {
    //         unsubscribe.catch((err) => console.log('failed: ', err));
    //     };
    // }, []);

    // const updateToken = async (token) => {

    //     try {

    //         const _updateFCM = await _call('rest-auth/user/', 'PATCH', {
    //             device_id: token
    //         });

    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    return (
        <div>
            {/* <Toaster /> */}
        </div>
    );
}
export default Notification;