import React, { useEffect, useState } from "react";
import { AlertDialogSlide, CategorySection, ContentCompSection, Input, Layout, LayoutHeader, ReminderComp } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { optionsData } from "../utils/_dataset";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty, orderBy } from "lodash";
import YouTube from 'react-youtube';
import { useNavigate } from "react-router-dom";



const SpecialistTerms = (props) => {
    const [numPages, setNumPages] = useState()
    const [pageNumber, setPageNumber] = useState(1)

    const navigate = useNavigate();

    const file = "./../assets/files/terms.pdf";


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }


    return <Layout>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 overflow-hidden px-4 lg:px-0">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                    <LayoutHeader title="Specialist Terms and Conditions" onBack={() => navigate(-1)} none />
                    <p className="mt-4 text-slate-500">Last updated: March 10, 2023</p>
                    <p className="mt-4">
                        MyTMJ, LLC ("MyTMJ," "we," "us," or "our") offers online services
                        that pertain to the field of medicine. Our services encompass
                        various aspects, including but not limited to providing information
                        about specific medical conditions, facilitating electronic
                        prescribing, and offering online tools to assist the general public
                        ("users") in accessing health-related information. As specialists
                        and physicians ("you"), we grant you access to our website and
                        allow you to showcase your services and medical practice details
                        on MyTMJ in exchange for a monetary fee.
                    </p>
                    <p className="mt-4">
                        Before proceeding, we kindly request that you carefully read the
                        following terms. By clicking on the "ACCEPT" button below, you
                        acknowledge that you have read, understood, and agreed to be
                        bound by these terms without any limitations or exceptions. By
                        using MyTMJ, you explicitly affirm that you comprehend, consent
                        to, and fully accept all the terms and conditions outlined in these
                        Terms of Use, and you agree to abide by them without any
                        modifications
                    </p>
                    <h4 className="mt-4">
                        1. Content, Products, and Services; Intellectual Property
                    </h4>
                    <p className="mt-4">
                        All textual content, graphics, user interfaces, visual interfaces,
                        information, documents, materials, photographs, trademarks,
                        logos, sounds, music, artwork, and computer code found on or
                        derived from the Site or Mobile Application are either owned or
                        licensed by MyTMJ. These elements are protected by copyright,
                        patent, trademark, trade secret, trade dress laws, as well as
                        various other intellectual property rights and unfair competition
                        laws. The presence of such material on the Site/Mobile
                        Application or your utilization of the Site/Mobile Application, the
                        Content, or the Products and Services should not be interpreted
                        as granting any license or other rights to the intellectual property
                        or other proprietary rights of MyTMJ, its affiliates, or any third
                        party, whether explicitly or implicitly. Selling, reselling, licensing,
                        sublicensing, copying, downloading, reproducing, republishing, or
                        repurposing MyTMJ's Content is strictly prohibited. However, you
                        may use the information provided by MyTMJ (such as data
                        sheets, knowledge base articles, and similar materials) that are
                        intentionally made available for downloading from the Site, under
                        the following conditions: (a) not removing any proprietary notice
                        language in all copies of such documents, (b) utilizing such
                        information solely for personal, non-commercial informational
                        purposes and refraining from copying or posting it on any
                        networked computer or broadcasting it through any media, (c)
                        making no modifications to any such information, and (d) not
                        making any additional representations or warranties regarding
                        such documents. MyTMJ explicitly reserves all rights that are not
                        expressly granted to you under these Terms of Use. By using
                        MyTMJ, you agree not to: (a) violate any local, state, national, or
                        international laws; (b) access any MyTMJ account other than your
                        own; or (c) impersonate any individual or entity, or misrepresent
                        your affiliation with any person or entity. We value and encourage
                        your feedback. However, please note that by submitting
                        unsolicited ideas to MyTMJ, you automatically relinquish any
                        intellectual property rights associated with those ideas.
                        Additionally, unsolicited ideas submitted to MyTMJ, its employees,
                        or representatives become the property of MyTMJ.
                    </p>
                    <h4 className="mt-4">
                        2. Access to Services
                    </h4>
                    <p className="mt-4">
                        By registering as a specialist with MyTMJ ("Subscriber"), and as
                        long as these Terms are in effect, you will have access to specific
                        registration and account administration functions of MyTMJ.
                        These functions will allow you to list your services, update your
                        account information, and carry out related administrative tasks.
                        However, access to these services is contingent upon your
                        payment of the fees described below
                    </p>
                    <h4 className="mt-4">
                        3. Fees and Payments
                    </h4>
                    <p className="mt-4">
                        As a Subscriber, you are responsible for the payment of all
                        applicable fees and charges to MyTMJ in exchange for the
                        services provided under these Terms. As part of the registration
                        process, you may be required to provide valid credit card
                        information. By doing so, you authorize MyTMJ to charge your
                        credit card for all amounts that are due and payable by you under
                        these Terms.
                    </p>
                    <h4 className="mt-4">
                        4. Represent Yourself Honestly
                    </h4>
                    <p className="mt-4">
                        As a Subscriber, it is essential that you represent yourself
                        truthfully at all times. You acknowledge and warrant that all
                        information provided to us will be accurate, complete, current, and
                        true, and that you have the necessary rights to provide such
                        information to us in relation to your use of MyTMJ. By using
                        MyTMJ, you confirm that you are a licensed medical professional
                        with the authority to prescribe medication. It is required that you
                        maintain your status as a licensed medical professional for the
                        duration of your access to MyTMJ. If you no longer hold a valid
                        medical license or the authority to prescribe medication, you must
                        promptly notify us and discontinue using all services provided by
                        MyTMJ.
                    </p>
                    <h4 className="mt-4">
                        5. Ensure Accuracy of Information
                    </h4>
                    <p className="mt-4">
                        While using MyTMJ, you are responsible for maintaining complete
                        and accurate information. It is your duty to ensure that all
                        addresses and phone numbers used to contact patients are up to
                        date and correct. Additionally, you must obtain all necessary
                        consents or authorizations from patients to contact them on their
                        mobile phones, in accordance with relevant laws and regulations.
                        You acknowledge that you bear full responsibility for the accuracy
                        of the information provided and its compliance with applicable
                        legal requirements.
                    </p>
                    <h4 className="mt-4">
                        6. Confidentiality and Account Security
                    </h4>
                    <p className="mt-4">
                        MyTMJ may, under certain circumstances, share specific account
                        or other information with government organizations or third parties
                        if it believes in good faith that it is required by law or legal
                        proceedings, or when necessary to safeguard the rights or
                        property of MyTMJ or others. Upon registration, you will receive a
                        password and/or unique user ID, which you must keep
                        confidential. You agree to be responsible for all activities
                        associated with your password and/or user ID, including
                        unauthorized access or usage of MyTMJ. If your password and/or
                        user ID is lost or stolen, it is essential to promptly notify MyTMJ so
                        that appropriate measures can be taken, including the issuance of
                        a new password or user ID.
                    </p>
                    <h4 className="mt-4">
                        7. Duration of Terms
                    </h4>
                    <p className="mt-4">
                        By acknowledging your acceptance of all the terms and conditions
                        or by using MyTMJ, these Terms will become effective and
                        binding. Once in effect, these Terms will remain in operation until
                        terminated.
                    </p>
                    <h4 className="mt-4">
                        8. No Warranties
                    </h4>
                    <p className="mt-4">
                        MyTMJ and its information, content, materials, and services are
                        provided on an "as is" basis. To the fullest extent permitted by
                        applicable law, you expressly disclaim all warranties, whether
                        express or implied, including but not limited to warranties of
                        merchantability, fitness for a particular purpose, title, noninfringement, non-interference, system integration, and accuracy
                        of data. MyTMJ makes no promises or representations regarding
                        the number of users who will access your services, if any.
                        Furthermore, MyTMJ does not warrant that your use of the Site or
                        Mobile Application will be uninterrupted, error-free, or free from
                        viruses. The download of our application and its content is solely
                        at your own discretion and risk, and you will be solely responsible
                        for any damages to your electronic devices or loss of data that
                        may result from such activities. MyTMJ does not act as a provider
                        of, and does not provide warranties with respect to, any thirdparty software or third-party offerings. While information you
                        submit may be password-protected, MyTMJ does not guarantee
                        the security of any information transmitted to or from the Site/
                        Mobile Application. By using MyTMJ, you agree to assume the
                        security risk associated with any information you provide using
                        MyTMJ.
                    </p>
                    <h4 className="mt-4">
                        9. Limitation of Liability
                    </h4>
                    <p className="mt-4">
                        Your use of MyTMJ is undertaken at your own risk. Under no
                        circumstances shall MyTMJ or its affiliates be liable for any
                        indirect, incidental, consequential, or special damages, or for any
                        loss of profits or damages resulting from business interruption or
                        the loss or inaccuracy of information, regardless of whether such
                        damages were foreseeable and even if MyTMJ was informed of
                        the possibility of such damages. This limitation applies to any
                        claims arising in connection with these terms or your use or
                        inability to use MyTMJ. In no event will MyTMJ's total liability to
                        you for any and all claims arising in connection with these terms
                        exceed the total fees paid to MyTMJ by you during the preceding
                        12-month period. You acknowledge that this limitation of liability is
                        an essential term between you and MyTMJ, and that you would
                        not be granted access to MyTMJ without this limitation in place.
                    </p>
                    <h4 className="mt-4">
                        10. Indemnification
                    </h4>
                    <p className="mt-4">
                        You agree to indemnify, defend, and hold harmless MyTMJ
                        (including its affiliates, officers, directors, employees, agents,
                        licensors, suppliers, and any third-party information providers to
                        the site or service) from and against all losses, expenses,
                        damages, costs, and liabilities, including reasonable attorneys'
                        fees, arising from any violation of these terms or any activity
                        related to your account. This includes, but is not limited to, any
                        negligent or wrongful conduct and your failure to accurately
                        represent yourself or your services. This obligation extends to any
                        person accessing MyTMJ using your account.
                    </p>
                    <h4 className="mt-4">
                        11. Modifications to Terms
                    </h4>
                    <p className="mt-4">
                        These Terms of Use are subject to updates and changes without
                        prior notice to you. Additional terms or conditions may also apply
                        to specific portions of the Services or the MyTMJ website or
                        mobile application. By continuing to use the Services after any
                        modifications to the Terms of Use, you are deemed to accept and
                        agree to such changes. It is your responsibility to ensure that
                        anyone accessing the Services through your account complies
                        with the Terms of Use. The most current version of the Terms of
                        Use can always be viewed on our website. Any changes to the
                        Terms of Use will be effective upon posting on the Site. If you
                        object to the changes, your only recourse is to terminate these
                        Terms. Your continued use of MyTMJ indicates your
                        acknowledgment of the changes and agreement to be bound by
                        the revised Terms, including any modifications. Certain areas of
                        MyTMJ may have additional terms of use that apply, and by using
                        those areas, you agree to be bound by the respective additional
                        terms. In case of any conflict between the additional terms and
                        these Terms, the additional terms will prevail.
                    </p>
                    <h4 className="mt-4">
                        12. Modifications to Services
                    </h4>
                    <p className="mt-4">
                        We have the right to modify or discontinue MyTMJ, either with or
                        without prior notice to you. We will not be held liable to you or any
                        third party for any modifications or discontinuation of MyTMJ. If
                        you object to any changes, your only option is to terminate these
                        Terms. By continuing to use MyTMJ after being notified of such
                        changes, you acknowledge and accept the modifications made to
                        MyTMJ.
                    </p>
                    <h4 className="mt-4">
                        13. Termination
                    </h4>
                    <p className="mt-4">
                        You have the right to terminate these Terms at any time by
                        canceling your access to MyTMJ and its services. We may also
                        terminate these Terms without notice or temporarily suspend your
                        access to MyTMJ and/or the Site if you violate these Terms.
                        Additionally, we reserve the right to terminate these Terms by
                        providing notice to you in accordance with these Terms. Upon
                        termination, we will no longer have any obligations to you
                        regarding MyTMJ. All licenses and rights granted to you under
                        these Terms will cease, and you must promptly remove any
                        MyTMJ content from your electronic devices and destroy any
                        confidential information you possess. If we terminate these Terms
                        for reasons other than your breach and upon your request, we will
                        refund a prorated portion of any prepaid fees or charges, provided
                        they are otherwise non-refundable. You are responsible for paying
                        any outstanding amounts accrued as of the termination date.
                    </p>
                    <h4 className="mt-4">
                        14. Jurisdiction
                    </h4>
                    <p className="mt-4">
                        By accessing the Services, you agree that the laws of the State of
                        California, without regard to choice of laws principles, will govern
                        all matters relating to the use of the Services, regardless of the
                        state or country from which you access them. No waiver of any of
                        these Terms of Use shall be considered a continuing waiver of
                        such terms or any other term. We do not warrant that the
                        materials available through the Services are suitable or available
                        for use in locations outside the State of California, and accessing
                        them from territories where their contents are illegal is prohibited.
                        If you access the Services from outside the United States, you are
                        responsible for complying with the laws of your jurisdiction.
                    </p>
                    <h4 className="mt-4">
                        15. Complete Agreement
                    </h4>
                    <p className="mt-4">
                        Except for any specific "legal notice" provided on the website,
                        these Terms of Use constitute the entire agreement between you
                        and MyTMJ regarding your use (including prior use) of the
                        Services. In the event that a court of competent jurisdiction deems
                        any provision of these Terms of Use to be unenforceable as
                        written, the parties intend for such provision to be modified to the
                        extent necessary to make it enforceable. If modification is not
                        possible, the parties intend for the provision to be removed, while
                        the remaining provisions continue to be in full force and effect.
                    </p>
                    <h4 className="mt-4">
                        16. Statute of Limitations
                    </h4>
                    <p className="mt-4">
                        You acknowledge and agree that, notwithstanding any statute or
                        law to the contrary, any claim or cause of action arising from or
                        related to your use of MyTMJ or these Terms must be filed within
                        one (1) year after such claim or cause of action arose. Failure to
                        file within this time frame will result in the claim or cause of action
                        being forever barred.
                    </p>
                    <p className="mt-4">
                        © 2023 MyTMJ, LLC. All rights reserved.
                    </p>


                </div>
            </div>
        </div>

    </Layout>
}






export default SpecialistTerms;