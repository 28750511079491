import React, { useEffect, useState } from "react";
import { AlertDialogSlide, CategorySection, ContentCompSection, Input, Layout, LayoutHeader, ReminderComp } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { optionsData } from "../utils/_dataset";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty, orderBy } from "lodash";
import YouTube from 'react-youtube';
import { useNavigate } from "react-router-dom";



const Privacy = (props) => {
    const [numPages, setNumPages] = useState()
    const [pageNumber, setPageNumber] = useState(1)

    const navigate = useNavigate();

    const file = "./../assets/files/terms.pdf";


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }


    return <Layout>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 overflow-hidden px-4 lg:px-0">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                    <LayoutHeader title="Privacy Policy" onBack={() => navigate(-1)} none />
                    <p className="mt-4 text-slate-500">Last updated: March 10, 2023</p>
                    <p className="mt-4">
                        This Privacy Policy discloses the practices of myTMJ app and it’s parent company
                        MyTMJ, LLC, along with its predecessors, licensors, beneficiaries, and its
                        affiliated health care practices, including participating specialist, and other health
                        care companies and organizations who are listed or may contract in the future with
                        the myTMJ app, which welcome you to download our proprietary mobile
                        application software (the “myTMJ app”) and utilize the website and the services
                        provided through the myTMJ Application (the “Services”). For purposes of this
                        website and the myTMJ Application, MyTMJ, LLC and the Practices are
                        sometimes collectively referred to as “myTMJ” “we,” or “us.”
                    </p>

                    <p className="mt-4">myTMJ app is committed to respecting your privacy and recognizing your need for
                        appropriate protection and management of Personally Identifiable Information
                        (“PII”) and Protected Health Information (“PHI”) an you share with us. The
                        purpose of this Privacy Policy is to explain the types of information myTMJ app
                        obtains about users of the myTMJ Application and/or Services, how the
                        information is obtained, how it is used, how it is disclosed, how you can get access
                        to this information, and the choices you have regarding our use of, and your ability
                        to review and correct, the information. By using the myTMJ Application and/or the
                        Services, you are accepting and consenting to the use of your information as
                        described in this policy. We will not accept any PII or PHI from you prior to
                        providing you with this Privacy Policy. Please review this Policy carefully.</p>

                    <p className="mt-4">
                        Please note, MyTMJ, LLC is an administrative and technology service provider
                        that owns the myTMJ Application. MyTMJ, LLC has entered into contracts with
                        the Practices, whose independent health care providers provide mobile, nonemergency general adult and pediatric health services. Among other things,
                        MyTMJ, LLC. contracts with the Practices, licenses the myTMJ Application to the
                        Practices to provide the Practices with the capability to receive, respond to and
                        schedule requested visits. MyTMJ, LLC. does not provide any health care services,
                        nor does it refer or recommend any physician or medical practice or any other
                        health care provider or personnel.
                    </p>

                    <p className="mt-4">
                        This Privacy Policy specifically applies to how MyTMJ, LLC collects, uses, and
                        discloses the information you provide or that we obtain with your consent as a
                        result of your use of the myTMJ Application or your enrollment as a registered
                        user with myTMJ application. This Privacy Policy does not apply to protected
                        health information provided from you to the doctors, dentists and Practices
                    </p>
                    <h4 className="mt-4 text-xl font-bold">
                        Information That You Give Us
                    </h4>

                    <p className="mt-4">
                        When you create an account on the myTMJ Application or enroll as a myTMJ
                        registered user (“Enroll”), or login to the myTMJ Application or myTMJapp.com
                        website to create a profile, comment on articles, or complete surveys, we collect
                        personal information that can identify you (“PII”), such as your full name, email
                        address, mailing address, telephone number, and credit card information for
                        payment. In addition to PII, myTMJ app may also collect your PHI or PHI of an
                        individual for whom you are requesting health services. For example, when you
                        use the myTMJ Application to transmit a request for health services to be provided
                        at your specified location (a request for a “Visit”), you will need to provide
                        additional health information about the individual for whom the visit is requested
                        so that the Practices can assess the request and determine whether or not one of the
                        Practices has an appropriate and available healthcare provider (a “Provider”) to
                        respond to your request. Another example of how myTMJ may obtain your PHI
                        and/or PII is when you grant permission for other third-parties to share your
                        information directly with myTMJ. This would include, without limitation, the
                        myTMJ Application’s access and synchronization of your PHI and/or PII with the
                        Apple Health application and/or Apple Health Records.
                    </p>

                    <p className="mt-4">
                        Ultimately, the information that myTMJ Application collects varies depending
                        upon how you use our Services and what permissions you give to us and other
                        third-parties that also collect your PII and/or PHI. We will not use any PHI for any
                        other purpose without your explicit authorization, or unless otherwise permitted or
                        required by law. You may revoke, in writing, any such authorization at any time,
                        except to the extent we have taken action in reliance thereon.
                    </p>

                    <p className="mt-4">
                        PHI and/or PII Obtained from Apple Clinical Health Records API or through
                        synchronization with the Apple Health application: myTMJ Application does not
                        use data obtained through Apple Clinical Health Records API or through
                        synchronization with the Apple Health application for advertising, marketing, or
                        other use-based data mining purposes. MyTMJ Application does not disclose any
                        data obtained through Apple Clinical Health Records API or through
                        synchronization with the Apple Health application to any third-party for
                        advertising, marketing, or other use-based data mining purposes.
                    </p>

                    <p className="mt-4">
                        MyTMJ, LLC. does not have any independent access to the Practices’ detailed
                        medical records that may be created as a result of a Visit. To the extent the
                        Practices integrate any of the information you provided into the Practice’s medical
                        record that is created as a result of a Visit, MyTMJ, LLC. has no authority to
                        access, delete or modify any portion of the Practice’s medical records of its
                        patients except as an agent of the Practices and in compliance with applicable law.
                        <br />
                        WE DO NOT KNOWINGLY ENROLL OR COLLECT INFORMATION
                        DIRECTLY FROM CHILDREN UNDER THE AGE OF EIGHTEEN.
                    </p>

                    <p className="mt-4">
                        Please keep in mind that certain features on the myTMJ Application or website
                        may give you an opportunity to interact with us and others. These may include
                        forums, message boards, chats, creating community profiles, and rating, tagging
                        and commenting on articles. When you use these features you should be aware that
                        any information you submit, including your name, location, health issues, and
                        email address, may be publicly available to others. We do not protect the privacy of
                        and are not responsible for your disclosure of any information through these
                        interactive features, including, but not limited to information that you might post
                        related to a minor.
                    </p>

                    <p className="mt-4">
                        Also, whenever you voluntarily disclose anyone’s personal information on
                        publicly-viewable web pages, that information can be collected and used by others.
                        For example, if you post your email address, you may receive unsolicited
                        messages. We cannot control who reads your posting or what other users may do
                        with the information that you voluntarily post, so we encourage you to exercise
                        discretion and caution with respect to information you choose to disclose through
                        these interactive features. When an individual chooses to post information that will
                        be publicly disclosed, he or she is responsible for all legal consequences. We are
                        not responsible under any data protection laws for information that you voluntarily
                        post on a site that can be accessed by others.
                    </p>
                    <p className="mt-4">
                        If you believe that we have violated your privacy rights, you should contact us at
                        the mailing address or e-mail address provided below. You may also file a
                        complaint with the government. See <a href="http://oag.ca.gov/contact/consumer-complaintagainst-business-or-company">http://oag.ca.gov/contact/consumer-complaintagainst-business-or-company</a> for more information.
                    </p>

                    <h4 className="mt-4 text-xl font-bold">
                        Other Information We Collect
                    </h4>

                    <p className="mt-4">
                        Cookies. Our myTMJ Application or website may use cookies and other
                        technologies to collect information. Cookies are small bits of information that our
                        websites place on the hard drive of your computer. We may use cookies to facilitate
                        your login processes; allow you to personalize and store your settings; collect
                        usage information; determine our total audience size and traffic; and help us
                        improve our sites by measuring which areas are of greatest interest to users.
                    </p>

                    <p className="mt-4">
                        Tracking and/or Analytics Services. We may use mobile application tracking and/
                        or analytics services. These services may record unique mobile gestures such as
                        tap, double-tap, zoom, pinch, scroll, swipe and tilt but do not collect personally
                        identifiable information that you do not voluntarily enter in the myTMJ
                        Application. These services do not track your browsing habits across mobile
                        applications that do not use the same services. We are using the information
                        collected by these services to understand user behavior and optimize site
                        performance.
                    </p>

                    <p className="mt-4">
                        Web Beacons. We may also use web beacons (invisible images often referred to as
                        pixel tags or clear GIFs) in order to recognize users and assess traffic patterns, and
                        we may include web beacons and cookies in our email messages in order to count
                        how many e-mail messages have been opened.
                    </p>

                    <p className="mt-4">
                        Non-Personally Identifiable Information. We also collect Non-Personally
                        Identifiable Information that is not health information in the form of statistics and
                        information regarding the myTMJ Application user’s statistics and metrics
                        obtained from third party devices (for example, steps, distance, calories burned,
                        GPS coordinates, bat speed, hand speed, swing time, etc.), which may be combined
                        with personal information you submit through the myTMJ Application and/or
                        Services so that you can fully enjoy the benefits of the myTMJ Application’s
                        tracking, monitoring, and diagnostic tools. We may also request the following optional information as part of your profile so that you can fully enjoy the features
                        and functions of our Services: your weight, height, and gender.
                    </p>

                    <p className="mt-4">
                        Mobile Device Information. Your use of the myTMJ Application may also include
                        collection of information from your mobile device. For example, the myTMJ
                        Application may request your permission to collect location data and/or may
                        request access to multimedia (photos or videos) stored on your mobile device. You
                        have the option of declining collection of geolocation data, but this may limit your
                        ability to participate in certain activities through the myTMJ Application. If you do
                        not wish for your location data to be shared with myTMJ, please respond
                        accordingly when prompted on your mobile device, or visit your mobile device
                        settings. Multimedia will only be collected from your device if you affirmatively
                        select it to upload to the application (i.e. you choose an image or video to store
                        within the myTMJ Application). Multimedia will not be shared with other myTMJ
                        Application users (with the exception of your profile photo, to the extent such
                        feature is offered, which will appear in your user profile).
                    </p>

                    <h4 className="mt-4 text-xl font-bold">
                        How We Use Your Information
                    </h4>

                    <p className="mt-4">
                        Subject to this Privacy Policy, the Terms of Service, and applicable terms and
                        conditions of third-party applications, all data transmitted through the myTMJ
                        Application or website is owned by myTMJ; provided, however, that PHI that you
                        provide for the purpose of scheduling a Visit with any of the Practices will be
                        solely owned by the Practices. To the extent myTMJ is precluded from owning
                        data transmitted through the myTMJ Application, you grant MyTMJ a perpetual,
                        worldwide, royalty-free license to use such data (with the exception of PHI) to the
                        extent necessary to enable use of the myTMJ Application, the website, and
                        Services. Generally, we may use information in the following ways:
                        For the purposes for which you specifically provided it including, without
                        limitation, to enable us to process your registration, provide the Services or other
                        requests.
                        To transmit a request for a Visit per your request.
                        To send you information about your relationship or transactions with us.
                        To notify you about our products, services, and special offers, except that we will
                        not use PHI for marketing purposes without your prior written consent for yourself
                        or your minor child.
                        To otherwise contact you with information that we believe will be of interest to
                        you.
                        To enhance or develop features, products and services. To allow us to personalize
                        the content that you and others see on the myTMJ Application.
                    </p>

                    <p className="mt-4">
                        To provide advertisers and other third parties with aggregate information about
                        myTMJ Application users and myTMJ Application usage patterns.
                        To allow other select companies to send you promotional materials about their
                        products and services, provided that no PHI will be used for this purpose without
                        your prior written consent.
                    </p>

                    <p className="mt-4">
                        We use non-Personally Identifiable Information for purposes such as measuring the
                        number of users of various features of the myTMJ Application, making the myTMJ
                        Application more useful to users and delivering targeted advertising and nonadvertising content. We may also use Non-Personally Identifiable Information (for
                        example, statistics regarding use and metrics) for research purposes, for marketing
                        and promotional purposes, and to develop new learning tools and solutions and we
                        may share such information with third parties, including researchers and/or
                        advertisers, on an aggregate and anonymous basis. We use IP addresses to analyze
                        trends, administer the myTMJ Application, track a visitor’s movement, and gather
                        demographic information for aggregate, non-personally identifiable use.
                    </p>

                    <p className="mt-4">
                        You acknowledge that unless you request in writing otherwise, we, in our sole
                        discretion, have the right but not the obligation to store any information, whether
                        PII or otherwise, perpetually, to the extent permitted by law. If you wish for any
                        information about you to be removed from our database, please contact us through
                        the contact information provided below in the “How to Contact Us” section of this
                        Privacy Policy.
                    </p>

                    <h4 className="mt-4 text-xl font-bold">
                        Sharing Your Information
                    </h4>
                    <p className="mt-4">
                        Except as otherwise described in this Privacy Policy, or if we inform you otherwise
                        at the time of data collection and receive your consent where required, we will not
                        sell, trade, or share your information with third parties.
                        We may share your information as follows:
                        Visits: We will share your information, at your direction, to transmit a request for a
                        Visit with a Practice. The Provider and/or other representative of a Practice may
                        contact you through the myTMJ Application to ask for additional information or to
                        treat you virtually. The Practices’ treatment of your information is subject to the
                        Practices’ own policies and procedures. Any PHI that we collect and save from you
                        will be kept private and secure, as required by law. With Affiliates: We may share
                        your information with affiliated companies and businesses, provided that your PHI
                        will not be shared for any marketing purposes without your prior written consent,
                        in accordance with applicable law.
                    </p>

                    <p className="mt-4">
                        With Service Providers: We may use other companies to perform services
                        including, without limitation, facilitating some aspects of our myTMJ Application
                        such as processing credit card transactions sending emails, fulfilling purchase
                        requests, and data analysis on our behalf. These other companies may be supplied
                        with or have access to your information solely for the purpose of providing these
                        services to you on our behalf. Such service providers shall be bound by appropriate
                        confidentiality and security obligations, which may include, as applicable, business
                        associate contract obligations.
                    </p>
                    <p className="mt-4">
                        With Business Partners: When you make purchases or engage in promotions
                        offered through our myTMJ Application, we may share PII, but not your PHI, with
                        the businesses with which we partner to offer you those products, services, and
                        promotions. When you accept a particular business partner’s offer, you authorize us
                        to provide your information to that business partner.
                        Special Circumstances:
                        We also may disclose your information:
                    </p>

                    <p className="mt-4">
                        In response to a subpoena or similar investigative demand, a court order, or other
                        request from a law enforcement or government agency where required by
                        applicable law.
                        When disclosure is required or allowed by law in connection with efforts to
                        investigate, prevent, or take other action regarding illegal activity, suspected fraud
                        or other wrongdoing; to protect and defend the rights, property or safety of
                        myTMJ, our users, our employees, or others; to comply with applicable law or
                        cooperate with law enforcement; or to enforce the myTMJ Application’s terms and
                        conditions or other agreements or policies.
                    </p>

                    <p className="mt-4">
                        In connection with a corporate transaction, such as the sale of all or a portion of
                        our business, a divestiture, merger, consolidation, or asset sale, or in the event of
                        bankruptcy, as required or allowed by law.
                        <br />
                        SPECIAL NOTICE FOR USERS OF THE APPLICATION: IF
                        YOU ELECT TO MAKE YOUR PROFILE (OR THAT OF A
                        MINOR OR FAMILY MEMBER) VIEWABLE BY
                        PROVIDERS, ALL INFORMATION THAT YOU INCLUDE
                        IN THAT PROFILE MAY BE VIEWED BY PROVIDERS.
                        YOU SHOULD NOT ENTER ANY INFORMATION IN THE
                        PROFILE THAT YOU (OR ANOTHER PERSON) WISH TO
                        REMAIN CONFIDENTIAL. A PROVIDER WILL NOT BE
                        ABLE TO CONTACT YOU EXCEPT THROUGH THE
                        PERSONAL CONTACT INFORMATION YOU PROVIDE
                        THROUGH THE APPLICATION. myTMJ IS NOT
                        RESPONSIBLE FOR THE RETENTION, USE OR PRIVACY
                        PRACTICES OF THE PRACTICES AFTER THEY HAVE
                        RECEIVED YOUR INFORMATION.
                    </p>

                    <h4 className="mt-4 text-xl font-bold">
                        Confidentiality of Health Information
                    </h4>

                    <p className="mt-4">
                        The Practices and Providers may be subject to laws and regulations governing the
                        use and disclosure of health information they create or receive. Included among
                        them is the Health Insurance Portability and Accountability Act of 1996
                        (“HIPAA”), the Health Information Technology for Economic and Clinical Health
                        of 2009 (“HITECH”), and the regulations adopted thereunder, and applicable state
                        laws. MyTMJ, LLC. stores, processes, or transmits “individually identifiable health
                        information” (as such term is defined by HIPAA) on behalf of such a health care
                        provider, MyTMJ, LLC does so as its “business associate” (as also defined by
                        HIPAA). MyTMJ, LLC is prohibited from, among other things, using individually
                        identifiable health information in a manner that the health care provider itself may
                        not. MyTMJ, LLC is also required to, among other things, apply reasonable and
                        appropriate measures to safeguard the confidentiality, integrity and availability of
                        individually identifiable health information we store and process on behalf of such
                        health care providers. For more information regarding our treatment of health
                        information, please see our <span className="text-red-500">Notice of Privacy Practices.</span>
                    </p>
                    <h4 className="mt-4 text-xl font-bold">
                        How to Access or Update Your Information
                    </h4>
                    <p className="mt-4">
                        You have the ability to review and update your personal information by visiting the
                        account management section on the myTMJ Application. You can also review and
                        update your personal information by contacting us at the email address provided
                        below in the “How to contact us” section of this Privacy Policy.
                    </p>
                    <h4 className="mt-4 text-xl font-bold">
                        How We Protect Your Information
                    </h4>
                    <p className="mt-4">
                        We use commercially reasonable administrative, technical, and physical measures
                        to safeguard your information in our possession against loss, theft and
                        unauthorized use, disclosure or modification. We follow generally accepted
                        industry standards to protect the information submitted to us, both during
                        transmission and once we receive it. No method of transmission over the internet is
                        100% secure, however. Therefore, while we strive to make all reasonable efforts to
                        use commercially acceptable means to protect your personal information, we
                        cannot guarantee its absolute security. In the unlikely event of a data breach, you
                        will be notified as soon as reasonably possible, in accordance with applicable law.
                        Furthermore, we are not responsible for any breach of security or for any actions of
                        any third parties that receive the information.
                    </p>
                    <p className="mt-4">
                        Certain of the administrative, technical, and physical measures in place are as
                        follows. myTMJ’s infrastructure is kept in a secured data center that protects from
                        unauthorized access to the physical servers, backups and any element used to store
                        and/or process personal data. Only authorized personnel can access the data center.
                        myTMJ systems and databases are backed up regularly to help protect the data in
                        case of an uncontrollable catastrophe. The data center that stores myTMJ servers
                        has policies and procedures in place designed to safeguard the equipment that our
                        data is stored on. myTMJ regularly upgrades its system software to include the
                        latest security features. myTMJ servers are protected by a firewall system, which is
                        designed to keep unwanted traffic or access out of our computer network. myTMJ
                        also employs an intrusion prevention service (IPS) provided by a secured data
                        center operated by a professional company, and uses security methods to determine
                        the identity of each registered user, so that appropriate rights and restrictions can
                        be enforced for that user. Reliable verification of user identity is called
                        “authentication.” All communication between myTMJ’s Web server, your browser
                        and the myTMJ Application is encrypted with SSL (Secure Sockets Layer) to
                        guard against network eavesdroppers. Your password is internally encrypted in
                        myTMJ’s system to prevent unauthorized access to the system. Validations are
                        built throughout the application to capture the most reliable information. Only the
                        last 4 digits of your credit card number is stored on our servers.
                    </p>

                    <h4 className="mt-4 text-xl font-bold">
                        Changes To Our Privacy Policy
                    </h4>
                    <p className="mt-4">
                        From time to time we may revise this Privacy Policy. If we make revisions that
                        change the way we collect or use your information, those changes will be posted in
                        this Privacy Policy and the effective date will be noted at the end of the Privacy
                        Policy. Therefore, you should review it periodically so that you are up to date on
                        our most current policies and practices. If we make material changes to our
                        practices regarding use of your information, your information will continue to be
                        governed by the Privacy Policy under which it was subject prior to those changes,
                        unless you have been provided notice of, and have not objected to, the changes.
                    </p>
                    <h4 className="mt-4 text-xl font-bold">
                        Children’s Online Privacy Protection Act
                    </h4>
                    <p className="mt-4">
                        We support and comply with the Children’s Online Privacy Protection Act
                        (COPPA) and we do not knowingly collect information from children under the
                        age of 18, nor do we share such information with third parties. Children under the
                        age of 18 may not use the myTMJ Application. If you seek a Visit for a minor, you
                        will be responsible for providing information related to the minor and for paying
                        for the Visit requested for the minor.
                    </p>
                    <h4 className="mt-4 text-xl font-bold">
                        Links to Third Party Sites
                    </h4>
                    <p className="mt-4">
                        Our myTMJ Application or website may contain links to websites or applications
                        operated and maintained by third-parties, over which we have no control. Privacy
                        policies for these third-party sites and applications may be different from our
                        Privacy Policy. You access these third- party sites and applications at your own
                        risk. You should always read the privacy policy of a linked site or applications
                        before disclosing any personal information on such site and/or through such
                        applications. myTMJ is not responsible for information you submit to third-p
                    </p>
                    <h4 className="mt-4 text-xl font-bold">
                        For California Residents
                    </h4>
                    <p className="mt-4">
                        The California Consumer Privacy Act (CCPA) and California Online Privacy
                        Practices Act (CalOPPA) provide some California residents with the additional
                        rights listed below. To exercise any of these rights, please follow the instructions
                        listed in this section.
                    </p>
                    <p className="mt-4">
                        Personal Information Collected. We collect information that identifies, relates to,
                        describes, references, is reasonably capable of being associated with, or could
                        reasonably be linked, directly or indirectly, with a particular consumer, household,
                        or device (“personal information”). For purposes of CCPA, “personal information”
                        does not include publicly available information from government records,
                        deidentified or aggregated information, or information excluded from CCPA’s
                        scope (including health information covered by HIPAA or the California
                        Confidentiality of Medical Information Act).
                    </p>
                    <p className="mt-4">
                        More information about the categories of personal information we collect, the
                        sources from which it is collected, the purposes of the collection, and the
                        categories of third parties with whom we share personal information, are described
                        elsewhere in this Privacy Policy. See Sections 1 & 2 (Information That You Give
                        Us, Other Information We Collect), Section 3 (How We Use Your Information),
                        and Section 4 (Sharing Your Information).
                        In particular, we will collect the following categories of personal information:
                    </p>
                    <p className="mt-4">
                        Identifiers (e.g. name, postal address, online identifier, Internet Protocol address,
                        email address, account name, passport number, or other similar identifiers)
                        Personal information categories listed in the California Customer Records statute,
                        Cal. Civ. Code 1798.80(e) (e.g., Social Security number, physical characteristics or
                        description, education, employment, employment history, financial information)
                        Protected classification characteristics under California or federal law (e.g. age,
                        race, color, ancestry, national origin, citizenship, religion or creed, marital status,
                        health condition, physical or mental disability, sex, sexual orientation, veteran or
                        military status, genetic information)
                        Commercial information (e.g. records of personal property, products or services
                        purchased, obtained, or considered, or other purchasing or consuming histories or
                        tendencies)
                    </p>
                    <p className="mt-4">
                        Biometric information (e.g., genetic, behavioral, and biological characteristics or
                        activity patterns, such as fingerprints or retina scans) Internet or other similar
                        network activity (e.g., browsing history, search history)
                        Geolocation data (e.g., physical location or movements)
                        Sensory data (e.g., audio, electronic, visual, thermal, or similar information)
                    </p>
                    <p className="mt-4">
                        Professional or employment-related information (e.g., current or past job history)
                        Non-public education information pursuant to FERPA (e.g., educational records
                        maintained by an educational institution) Inferences drawn from other personal
                        information (e.g., profile reflecting a person’s preferences, characteristics, trends,
                        behavior)
                    </p>
                    <p className="mt-4">
                        <b>Right to Know.</b> You have the right to know and see what personal information we
                        have collected about you over the past 12 months, including:
                    </p>
                    <p className="mt-4">
                        The categories of personal information we have collected about you;
                        The categories of sources from which the personal information is collected;
                        The business or commercial purpose for collecting your personal information;
                        The categories of third parties with whom we have shared your personal
                        information; and The specific pieces of personal information we have collected
                        about you.
                    </p>

                    <p className="mt-4">
                        Right to Delete. You have the right to request that we delete the personal
                        information we have collected from you (and direct our service providers to do the
                        same). There are a number of exceptions, however, that include, but are not limited
                        to, when the information is necessary for us or a third party to do any of the
                        following:
                    </p>

                    <p className="mt-4">
                        Complete your transaction;
                        Provide you with the Services;
                        Perform a contract between us and you;
                        Detect security incidents, protect against malicious, deceptive, fraudulent or illegal
                        activity, and prosecute those responsible for such activities;
                        Fix our system in the case of a bug;
                        Protect the free speech rights, including the free speech rights of you or other
                        users, or exercise another right provided by law;
                        Comply with the California Electronic Communications Privacy Act (Cal. Penal
                        Code § 1546 et seq.);
                        Engage in public or peer-reviewed scientific, historical, or statistical research in the
                        public interests that adheres to all other applicable ethics and privacy laws;
                        Comply with a legal obligation; or
                        Make other internal and lawful uses of the information that are compatible with the
                        context in which you provided it.
                    </p>

                    <p className="mt-4">
                        Right to Opt-Out of the Sale of Your Personal Information. We do not sell any of
                        your personal information.
                    </p>

                    <p className="mt-4">
                        Right to Non-Discrimination. We will not discriminate against those who exercise
                        their rights under this section. If you exercise your rights, we will not deny you
                        goods or services, charge you different prices or rates for goods or services, or
                        provide you with a different level or quality of goods or services.
                    </p>

                    <p className="mt-4">
                        Other Rights. California Civil Code § 1798.83 permits users of our Services that
                        are California residents to request certain information regarding our disclosure of
                        personal information to third parties for their direct marketing purposes. We do not
                        disclose your personal information to third parties for their direct marketing
                        purposes. However, you are free to make an inquiry with any questions by
                        contacting us in accordance with the “Contact Us” section in this Privacy Policy.
                    </p>

                    <p className="mt-4">
                        Exercising these Rights. To request access to or deletion of your personal
                        information, or to exercise any other data rights under California law, please
                        contact us in accordance with the “Contact Us” section in this Privacy Policy.
                        Please include your full name, email address, along with why you are emailing us,
                        so that we can verify and process your request in an efficient manner. If we cannot
                        verify your identity from the initial information you provide, we may request
                        additional information from you, which will be used only for the purposes of
                        verifying your identity to assist you with exercising your rights. You may also
                        designate an authorized agent to make a request under this section on your behalf.
                        We may request additional information from you to confirm that the authorized
                        agent has in fact been authorized by you to act on your behalf.
                    </p>

                    <p className="mt-4">
                        Accessing in Alternative Formats. For individuals with disabilities, please contact
                        us in accordance with the “Contact Us” section regarding how to access the
                        information in this Privacy Policy in an alternative format.
                    </p>

                    <p className="mt-4">
                        Response Time. We aim to respond to a consumer request for access or deletion
                        within 45 days of receiving a verifiable request. If we require more time, we will
                        inform you of the reason and extension period in writing.
                    </p>

                    <p className="mt-4">
                        Do Not Track Symbols. We do not have the capability to respond to “Do Not
                        Track” signals received from various web browsers at this time.
                    </p>
                    <h4 className="mt-4 text-xl font-bold">
                        How to Contact Us
                    </h4>
                    <p className="mt-4">
                        If you have any questions, comments or concerns about our Privacy Policy, you
                        may contact us at <span className="text-red-500">support@myTMJapp.com. </span>
                        <br />
                        If you have a CCPA request, you may contact us at <span className="text-red-500">Support@myTMJapp.com. </span>
                    </p>

                    <h4 className="mt-4 text-xl font-bold">
                        Effective Date
                    </h4>

                    <p className="mt-4">
                        This Privacy Policy is effective as of the date first listed above.
                    </p>

                </div>
            </div>
        </div>

    </Layout>
}






export default Privacy;