import React, { useState } from "react";
import { Colors } from "../utils/_constant";

const Tabs = ({ data, setCurrentTab, currentTab = 0, center = false }) => {
    const [active, setactive] = useState(currentTab);

    return <div className="flex flex-row items-center my-4" style={{ justifyContent: center ? 'center' : 'flex-start' }}>
        {data?.map((item, index) => <button onClick={() => {
            setactive(item?.id)
            setCurrentTab(item?.id)
        }} key={index} className={`h-14 w-48 flex flex-col rounded mr-4 justify-center items-center ${active == item?.id ? `bg-[${Colors.primary}]` : 'bg-white'}`}>
            <h4 className={active == item?.id ? 'text-white' : 'tet-slate-400'}>{item?.name}</h4>
        </button>)}
    </div>
}

export default Tabs;