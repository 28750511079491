import * as yup from 'yup';

const basicValidationSchema = yup.object().shape({
    first_name: yup
        .string()
        .required('First Name is Required'),
    last_name: yup
        .string()
        .required('Last Name is Required'),
    gender: yup
        .string()
        .required('Gender is Required'),
    dob: yup
        .string()
        .required('Date of Birth is Required'),
    weight: yup
        .string()
        .required('Weight is Required'),
    height: yup
        .string()
        .required('Height is Required'),
});

const meetingValidationSchema = yup.object().shape({
    date: yup
        .string()
        .required('Date is Required'),
    time: yup
        .string()
        .required('Time is Required'),
    hours: yup
        .string()
        .required('Hours is Required'),
    price: yup
        .string()
        .required('Price is Required'),
    method: yup
        .string()
        .required('Meeting Method is Required'),
    message: yup
        .string()
        .required('Message is Required'),
});


const DiagnosisValidation = {
    basic: basicValidationSchema,
    meeting: meetingValidationSchema
}

export default DiagnosisValidation;