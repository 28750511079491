import React, { useState } from "react";
import { Colors } from "../utils/_constant";
import moment from "moment";
import { handleDays } from "../utils/_function";
import { PrimaryButton } from "./_button";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios'

const DiagnosisComp = ({ data, index }) => {
    const [loader, setloader] = useState(false);
    const navigate = useNavigate();

    const onDownloadPDF = async () => {
        const token = await localStorage.getItem('token');
        const pdfURL = `https://tiny-band-34533.botics.co/api/v1/diagnose/${data.id}/download-pdf/`;
        setloader(true);
        axios({
            url: pdfURL,
            method: 'POST',
            responseType: 'blob',
            headers: {
                Authorization: `Token ` + token
            }
        }).then(response => {
            setloader(false);
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `Diagnosis Report - ${data.first_name} ${data.last_name}.pdf`;
            document.body.appendChild(link);
            link.click();

            // Clean up resources
            document.body.removeChild(link);
        }).catch(err => {
            setloader(false);
        });
    }



    const onViewSummary = () => {

        navigate('/diagnosis-result', {
            state: {
                condition: { conditions: data.condition_details },
                result: data,
                data: []
            }
        })

    }
    return <div className="mt-4 bg-white rounded p-3">
        <div onClick={onViewSummary} className="cursor-pointer">
            <div className="flex flex-row items-center justify-between">
                <h4 className="text-sm">Diagnosis No. <span className={`text-[${Colors.primary}]`}>{index + 1}</span></h4>
                <h4 className="text-sm">DOB. <span className={`text-[${Colors.primary}]`}>{moment(data.dob).format('DD-MM-YYYY')}</span></h4>
            </div>

            <div className="border-t mt-2 py-2">
                <h4 className="text-xs">Patient Name:</h4>
                <h4 className="font-bold">{`${data.first_name} ${data.last_name}`}</h4>
            </div>

            <div className="border-t mt-2 py-2">
                <h4 className="text-xs">Conditions:</h4>
                <h4>{data?.condition_details?.map(item => item.name)?.toString()}</h4>
            </div>

            <div className="border-t mt-2 py-2">
                <h4 className="text-xs">Created:</h4>
                <h4>{handleDays(data.created)}</h4>
            </div>
        </div>

        <PrimaryButton loading={loader} onClick={onDownloadPDF} title="Download PDF" className="mt-4" />

    </div>
}

export default DiagnosisComp;