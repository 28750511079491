import React, { useEffect, useState } from "react";
import { ContentCompSection, DiagnosisComp, Input, Layout, LayoutHeader } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ImagePicker } from 'react-file-picker'
import { _call, _multipartCall } from "../utils/_service";
import { Colors, googleAPIKey } from "../utils/_constant";
import SpecialistAreaSlices from "../utils/slices/_specialistarea.slices";
import { useAlert } from "react-alert";
import { isEmpty } from "lodash";
import Geocode from 'react-geocode';
import UserSlices from "../utils/slices/_user.slices";
import { getAddressInfo } from "../utils/_function";

const SpecialistProfile = (props) => {


    const dispatch = useDispatch();
    const location = useLocation();

    const [dataset, setdataset] = useState([])
    const [loader, setloader] = useState(false);
    const [toggle, settoggle] = useState(false);
    const [image, setimage] = useState();

    const [userInfo, setuserInfo] = useState([]);

    const fileInputRef = React.createRef();

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
            setimage(file);
        }
    };

    const userState = useSelector(state => state.user);

    const specialistArea = useSelector(state => state.specialist_area);
    const __specialistAction = SpecialistAreaSlices.action;

    const alert = useAlert()

    useEffect(() => {
        // dispatch(UserSlices.action.LoadData())
        if (isEmpty(specialistArea?.shape)) {
            dispatch(__specialistAction.LoadData())
        }
    }, [])

    const profileActions = UserSlices.action;
    useEffect(() => {
        const user = userState?.shape;
        setuserInfo(user);
    }, [userState])




    const handleChange = (value, field, type) => {
        var __userInfo = { ...userInfo };

        if (type == 1) {
            __userInfo[field] = value
        } else {
            // __userInfo.specialistprofile[field] = value
            __userInfo = { ...__userInfo, specialistprofile: { ...__userInfo.specialistprofile, [field]: value } }
        }

        setuserInfo(__userInfo)
    }



    const handleEdit = async () => {

        try {

            var _dataMultipart = [
                { key: 'first_name', value: userInfo.first_name },
                { key: 'last_name', value: userInfo.last_name },
                { key: 'latitude', value: userInfo?.latitude },
                { key: 'longitude', value: userInfo?.longitude },
                { key: 'specialistprofile.npi', value: userInfo?.specialistprofile.npi },
                { key: 'specialistprofile.license_number', value: userInfo?.specialistprofile.license_number },
                { key: 'specialistprofile.practice_email', value: userInfo?.specialistprofile.practice_email },
                { key: 'specialistprofile.phone_number', value: userInfo?.specialistprofile.phone_number },
                { key: 'specialistprofile.practice_name', value: userInfo?.specialistprofile.practice_name },
                { key: 'specialistprofile.state', value: userInfo?.specialistprofile.state },
                { key: 'specialistprofile.website_url', value: userInfo?.specialistprofile.website_url },
                { key: 'specialistprofile.street_number', value: userInfo?.specialistprofile.street_number },
                { key: 'specialistprofile.town', value: userInfo?.specialistprofile.town },
                { key: 'specialistprofile.zip_code', value: userInfo?.specialistprofile.zip_code },


                // { key: 'first_name', value: userInfo.first_name },
                // { key: 'last_name', value: userInfo.last_name },
                // { key: 'clientprofile.dob', value: moment(userInfo.dob).format('YYYY-MM-DD') },
                // { key: 'clientprofile.weight', value: userInfo?.clientprofile.weight },
                // { key: 'clientprofile.height', value: userInfo?.clientprofile.height },
                // { key: 'clientprofile.gender', value: userInfo?.clientprofile.gender === 'Male' ? 1 : 0 },
                // { key: 'clientprofile.location', value: userInfo?.clientprofile?.location },
                // { key: 'clientprofile.timezone', value: (new Date()).toString().match(/\(([^\)]+)\)$/)[1] },

                // { key: 'latitude', value: userInfo?.latitude },
                // { key: 'longitude', value: userInfo?.longitude },
            ];

            if (image) {
                _dataMultipart.push({
                    key: 'profile_picture', value: image
                });
            }


            const _updateProfileInfo = await _multipartCall('rest-auth/user/', 'PATCH', _dataMultipart);
            dispatch(profileActions?.onEdittedData(_updateProfileInfo.data))
            setloader(false);
            alert.show('Profile updated successfully!', {
                type: 'success'
            });

        } catch (err) {
            setloader(false)
            alert.show('Some problem occured!', {
                type: 'error'
            });
        }

        settoggle(false)
    }



    Geocode.setApiKey(googleAPIKey);

    const handleGeoCode = async (destination) => new Promise(async (resolve, reject) => {
        Geocode.fromAddress(destination).then((response) => {
            const { lat, lng } = response.results[0].geometry.location;
            resolve({ lat, lng })
        },
            (error) => {
                reject('false')
            }
        );
    });

    // console.log(userInfo)

    const onGoogleAutoComplete = async (detail) => {

        try {
            const __get = await getAddressInfo(detail?.formatted_address);
            const __getLatLng = await handleGeoCode(detail?.formatted_address);

            var __userInfo = { ...userInfo };

            const { state, country, city, zip_code } = __get;
            __userInfo = { ...__userInfo, address: detail?.formatted_address, latitude: __getLatLng.lat, longitude: __getLatLng.lng }
            __userInfo = { ...__userInfo, specialistprofile: { ...__userInfo.specialistprofile, state, country, town: city, zip_code } }

            setuserInfo(__userInfo)

            // setFieldValue('address', detail?.formatted_address);
            // setFieldValue('state', __get.state);
            // setFieldValue('city', __get.city);
            // setFieldValue('country', __get.country);
            // setFieldValue('zip_code', __get.zip_code);

            // setFieldValue('latitude', __getLatLng?.lat);
            // setFieldValue('longitude', __getLatLng?.lng);

        } catch (err) {
        }
    }


    const navigate = useNavigate();
    return <Layout>
        <div className="grid grid-cols-12 gap-4 overflow-hidden px-4 lg:px-0">
            <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto ">
                <LayoutHeader title="Profile" edit onEdit={() => {
                    if (!toggle) {
                        settoggle(!toggle)
                    } else {
                        handleEdit()
                    }
                }}
                    onBack={() => navigate(-1)} />


                <div className="mt-4 bg-white w-full min-h-[70vh] p-2 lg:p-10">
                    {/* <ImagePicker
                        extensions={['jpg', 'jpeg', 'png']}
                        dims={{ minWidth: 100, maxWidth: 3500, minHeight: 100, maxHeight: 3500 }}
                        onChange={base64 => console.log(base64)}
                        onError={errMsg => console.log(errMsg)}
                    >
                        <button className="w-44 h-44 rounded-full m-auto block bg-slate-50">
                            <img src={userInfo?.profile_picture?.split('?')[0]} className="w-full rounded-full" />
                        </button>
                    </ImagePicker> */}
                    <button onClick={() => fileInputRef.current.click()} className="w-44 h-44 rounded-full m-auto block bg-slate-50">
                        <img src={image ? URL?.createObjectURL(image) : userInfo?.profile_picture?.split('?')[0]} className="w-44 h-44 rounded-full" />
                    </button>
                    <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={handleFileSelect}
                        style={{ display: 'none' }}
                    />
                    <div className="border-b p-4 flex flex-row items-center justify-start mt-10">
                        <h4 className="flex-1">First Name</h4>
                        {toggle ? <div className="flex-1">
                            <Input value={userInfo?.first_name} onChange={e => handleChange(e.target.value, 'first_name', 1)} />
                        </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.first_name}</h4>}

                    </div>
                    <div className="border-b p-4 flex flex-row items-center justify-start">
                        <h4 className="flex-1">Last Name</h4>
                        {toggle ? <div className="flex-1">
                            <Input value={userInfo?.last_name} onChange={e => handleChange(e.target.value, 'last_name', 1)} />
                        </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.last_name}</h4>}

                    </div>
                    <div className="border-b p-4 flex flex-row items-center justify-start">
                        <h4 className="flex-1">License Number</h4>
                        {toggle ? <div className="flex-1">
                            <Input value={userInfo?.specialistprofile?.license_number} onChange={e => handleChange(e.target.value, 'license_number', 2)} />
                        </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.specialistprofile?.license_number}</h4>}

                    </div>
                    <div className="border-b p-4 flex flex-row items-center justify-start">
                        <h4 className="flex-1">NPI</h4>
                        {toggle ? <div className="flex-1">
                            <Input value={userInfo?.specialistprofile?.npi} onChange={e => handleChange(e.target.value, 'npi', 2)} />
                        </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.specialistprofile?.npi}</h4>}

                    </div>
                    <div className="border-b p-4 flex flex-row items-center justify-start">
                        <h4 className="flex-1">Address</h4>
                        {toggle ? <div className="flex-1">
                            <Input placeholder="Address" type="google" onChange={onGoogleAutoComplete} />
                        </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.specialistprofile?.address}</h4>}
                    </div>
                    <div className="border-b p-4 flex flex-row items-center justify-start">
                        <h4 className="flex-1">Website URL</h4>
                        {toggle ? <div className="flex-1">
                            <Input value={userInfo?.specialistprofile?.website_url} onChange={e => handleChange(e.target.value, 'website_url', 2)} />
                        </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.specialistprofile?.website_url}</h4>}
                    </div>
                    <div className="border-b p-4 flex flex-row items-center justify-start">
                        <h4 className="flex-1">Practice Name</h4>
                        {toggle ? <div className="flex-1">
                            <Input value={userInfo?.specialistprofile?.practice_name} onChange={e => handleChange(e.target.value, 'practice_name', 2)} />
                        </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.specialistprofile?.practice_name}</h4>}
                    </div>
                    <div className="border-b p-4 flex flex-row items-center justify-start">
                        <h4 className="flex-1">Practice Email</h4>
                        {toggle ? <div className="flex-1">
                            <Input value={userInfo?.specialistprofile?.practice_email} onChange={e => handleChange(e.target.value, 'practice_email', 2)} />
                        </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.specialistprofile?.practice_email}</h4>}

                    </div>
                    <div className="border-b p-4 flex flex-row items-center justify-start">
                        <h4 className="flex-1">Street Number</h4>
                        {toggle ? <div className="flex-1">
                            <Input value={userInfo?.specialistprofile?.street_number} onChange={e => handleChange(e.target.value, 'street_number', 2)} />
                        </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.specialistprofile?.street_number}</h4>}
                    </div>
                    <div className="border-b p-4 flex flex-row items-center justify-start">
                        <h4 className="flex-1">Town</h4>
                        {toggle ? <div className="flex-1">
                            <Input value={userInfo?.specialistprofile?.town} onChange={e => handleChange(e.target.value, 'town', 2)} />
                        </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.specialistprofile?.town}</h4>}
                    </div>
                    <div className="border-b p-4 flex flex-row items-center justify-start">
                        <h4 className="flex-1">State</h4>
                        {toggle ? <div className="flex-1">
                            <Input value={userInfo?.specialistprofile?.state} onChange={e => handleChange(e.target.value, 'state', 2)} />
                        </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.specialistprofile?.state}</h4>}
                    </div>
                    <div className="border-b p-4 flex flex-row items-center justify-start">
                        <h4 className="flex-1">Zip Code</h4>
                        {toggle ? <div className="flex-1">
                            <Input value={userInfo?.specialistprofile?.zip_code} onChange={e => handleChange(e.target.value, 'zip_code', 2)} />
                        </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.specialistprofile?.zip_code}</h4>}
                    </div>
                    <div className="border-b p-4 flex flex-row items-center justify-start">
                        <h4 className="flex-1">Speciality Area</h4>

                        {toggle ? <div className="flex-1">
                            <Input type="multiselect" placeholder="Specialist Areas"
                                defaultValue={userInfo?.specialistprofile?.speciality_area_details?.map(item => {
                                    return { label: item?.speciality_name, value: item?.speciality_name, id: item?.id }
                                })}
                                onChange={e => handleChange(e, 'speciality', 3)}
                                options={specialistArea?.shape?.map(item => {
                                    return { label: item?.speciality_name, value: item?.speciality_name, id: item?.id }
                                })} />
                        </div> : <h4 className={`flex-1 text-[${Colors.primary}]`}>{userInfo?.specialistprofile?.speciality_area_details?.map(item => item?.speciality_name)?.toString()}</h4>}

                    </div>
                </div>


            </div>
        </div>
    </Layout >
}

export default SpecialistProfile;