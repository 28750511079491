import React, { useEffect, useState } from "react";
import { AlertDialogSlide, CategorySection, ContentCompSection, Input, Layout, LayoutHeader, ReminderComp } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { _contactData, optionsData } from "../utils/_dataset";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty, orderBy } from "lodash";
import YouTube from 'react-youtube';
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { Colors } from "../utils/_constant";
import { PrimaryButton } from "../components/_button";
import moment from "moment";
import { useSelector } from "react-redux";

const ContactSection = (props) => {

    const navigate = useNavigate();

    const onEvent = (item) => {
        if (item?.id == 0) {
            window.open('mailto:support@mytmjapp.com')
        }

        if (item?.id == 2) {
            window.open('https://mytmjapp.com')
        }

    }

    return <Layout>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 overflow-hidden px-4 lg:px-0">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                    <LayoutHeader title="Contact" onBack={() => navigate(-1)} />

                    <div className="grid grid-cols-1 px-2">
                        {_contactData?.map(item => <div onClick={() => onEvent(item)} className="bg-white cursor-pointer rounded-lg shadow-lg p-4 min-h-[14vh] w-full mt-4">
                            <div className={`bg-[${Colors.primary}] w-10 h-10 rounded flex flex-row items-center justify-center`}>
                                <img src={item?.icon} className="object-contain w-8 h-8" />
                            </div>
                            <h4 className="my-2 font-bold">{item?.title}</h4>
                            <h4 className="text-slate-400">{item?.description}</h4>
                            <h4 className="my-2">{item?.footer}</h4>
                        </div>)}
                    </div>


                </div>
            </div>
        </div>

    </Layout>
}





export default ContactSection;