import React, { useState } from "react";
import { Dots } from "../assets/svgs";
import moment from "moment";
import AlertDialogSlide from "./_modal";
import { PrimaryButton } from "./_button";
import { useNavigate } from "react-router-dom";

const CurrentRequest = ({ data, painInfo }) => {
    const navigate = useNavigate();
    const [onViewModal, setonViewModal] = useState(false);
    const { clientprofile, email, first_name, last_name, profile_picture } = data.client_details;

    return <div className="bg-white rounded w-full min-h-[200px]">
        <div className="flex flex-row items-center p-4 border-b justify-between cursor-pointer" onClick={() => setonViewModal(true)}>
            <div className="flex flex-row items-center">
                <img src={profile_picture?.split('?')[0]} className="w-10 h-10 rounded-full bg-slate-50" />
                <div className="ml-3">
                    <h4 className="font-bold text-md">{first_name} {last_name}</h4>
                </div>
            </div>
            <Dots className="w-4 h-4" />
        </div>

        <div onClick={() => navigate('/request-individual', {
            state: {
                data, painInfo
            }
        })} className="cursor-pointer">
            <div className="border-b p-2 px-4 flex flex-col">
                <div className="flex flex-row w-full">

                    <div className="ml-2">
                        <h4 className="text-xs">Possible Conditions</h4>
                        <p className="text-slate-500 text-md">{painInfo?.find(x => x.id === data?.pain_category)?.category_name}</p>
                    </div>
                </div>
            </div>


            <div className="border-b p-2 px-4 flex flex-col">
                <div className="flex flex-row w-full">

                    <div className="ml-2">
                        <h4 className="text-xs">Request Date</h4>
                        <p className="text-slate-500 text-md">{data?.date}</p>
                    </div>
                </div>
            </div>

            <div className="border-b p-2 px-4 flex flex-col">
                <div className="flex flex-row w-full">

                    <div className="ml-2">
                        <h4 className="text-xs">Created Date</h4>
                        <p className="text-slate-500 text-md">{moment(data?.created_date).format('MM-DD-YYYY')}</p>
                    </div>
                </div>
            </div>

        </div>
        {data.meeting_status == 1 && <>
            <div className="border-b p-2 px-4 flex flex-col">
                <div className="flex flex-row w-full">

                    <div className="ml-2">
                        <h4 className="text-xs">Request Time</h4>
                        <p className="text-slate-500 text-md">{data?.time}</p>
                    </div>
                </div>
            </div>

            <div className="border-b p-2 px-4 flex flex-col">
                <div className="flex flex-row w-full">

                    <div className="ml-2">
                        <h4 className="text-xs">Request Price</h4>
                        <p className="text-slate-500 text-md">${data?.price}</p>
                    </div>
                </div>
            </div>

            <div className="border-b p-2 px-4 flex flex-col">
                <div className="flex flex-row w-full">

                    <div className="ml-2">
                        <h4 className="text-xs">Request Hours</h4>
                        <p className="text-slate-500 text-md">{data?.hours} Hours</p>
                    </div>
                </div>
            </div>
        </>}

        <h4 className="text-xs text-center my-5">{data.meeting_status == 1 ? 'Approved' : data.meeting_status == 2 && 'Declined'}</h4>

        <AlertDialogSlide open={onViewModal} handleClose={() => setonViewModal(false)}
            children={<UserProfileComp data={data} />}
            title="Client Profile" />
    </div>
}

const UserProfileComp = ({ data }) => {
    const { clientprofile, email, first_name, last_name, profile_picture } = data.client_details;
    return <div className="w-[100vh]">
        <div className="flex flex-row items-center p-4 border-b justify-between cursor-pointer">
            <div className="flex flex-row items-center">
                <img src={profile_picture?.split('?')[0]} className="w-10 h-10 rounded-full bg-slate-50" />
                <div className="ml-3">
                    <h4 className="font-bold text-md">{first_name} {last_name}</h4>
                </div>
            </div>
        </div>

        <div className="border-b p-2 px-4 flex flex-col w-full">
            <div className="flex flex-row items-center w-full">

                <div className="ml-0 w-full">
                    <h4 className="text-xs">Address</h4>
                    <p className="text-slate-500 text-md">{clientprofile?.location} Hours</p>
                </div>
            </div>
        </div>

        <div className="border-b p-2 px-4 flex flex-col w-full">
            <div className="flex flex-row items-center w-full">

                <div className="ml-0 w-full">
                    <h4 className="text-xs">Time Zone</h4>
                    <p className="text-slate-500 text-md">{clientprofile?.time_zone} Hours</p>
                </div>
            </div>
        </div>
    </div>
}


export default CurrentRequest;