import React from "react";
import { Arrow } from "../assets/svgs";


const CategorySection = ({ category, onClick }) => category?.map((item, index) => <div key={index} onClick={() => onClick(item)} className="bg-white cursor-pointer px-6 flex flex-row items-center justify-between w-full min-h-[8vh] mt-5">
    <div className="flex flex-row items-center">
        <div className="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 w-10 h-10 rounded-full flex flex-col items-center">
            <h4 className="text-white m-auto block font-bold text-xl">{item?.category_name ? item?.category_name[0] : item?.title[0]}</h4>
        </div>
        <h4 className="font-bold flex-1 text-lg lg:text-xl ml-4">{item?.category_name || item?.title}</h4>
    </div>
    <Arrow />
</div>)

export default CategorySection;