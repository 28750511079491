import React, { useEffect, useState } from "react";
import { CategorySection, ContentCompSection, FilterSpecialistElement, Layout, LayoutHeader, SpecialistBox, Tabs } from "../components";
import { D1, Guide, Logo } from "../assets/icons";
import { CustomerHeadText, MainLogo } from "../assets/images";
import { __specialistData, optionsData } from "../utils/_dataset";
import { _call } from "../utils/_service";
import { Arrow } from "../assets/svgs";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SpecialistAreaSlices from "../utils/slices/_specialistarea.slices";
import { useAlert } from "react-alert";

const FindSpecialist = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const specialistArea = useSelector(state => state.specialist_area);
    const user = useSelector(state => state.user);

    const __specialistAction = SpecialistAreaSlices.action;

    const alert = useAlert()
    const [loader, setloader] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [dataset, setdataset] = useState([]);
    const [search, setsearch] = useState('');
    const [chats, setchats] = useState([]);
    const [stateToggle, setstateToggle] = useState(true);
    const [record, setrecord] = useState([]);
    const [myspecialist, setmyspecialist] = useState([]);
    const [specialistAreaType, setspecialistAreaType] = useState([])
    const [urlParameters, seturlParameters] = useState({
        specialist_type: '',
        lat_lng: ''
    })
    const [renderevent, setrenderevent] = useState(0);
    const [painInfo, setpainInfo] = useState([]);

    useEffect(() => {
        if (isEmpty(specialistArea?.shape)) {
            dispatch(__specialistAction.LoadData())
        }
        handleGetPainInfo();
    }, [])

    const handleGetPainInfo = async () => {
        try {
            const _getpainInfo = await _call('api/v1/pain-category/', 'GET');
            const painInfo = _getpainInfo.data;
            setpainInfo(painInfo);

            const _getmainUserChatInfo = await _call(`api/v1/chat-token/?main_user=${user?.shape?.id}`, 'GET');
            setchats(_getmainUserChatInfo.data);

        } catch (err) { }
    }

    useEffect(() => {
        handleData(currentTab)

    }, [currentTab])



    const handleData = async (type = currentTab) => {

        try {
            var result = [];
            setloader(true);

            const generatedUrlParameters = generatedUrlParams();

            if (type == 0) {
                const _getdata = await _call(`api/v1/specialist-list/${generatedUrlParameters}`, 'GET');
                result = _getdata.data?.specialists;
                setdataset(_getdata.data?.specialists);
            } else {
                const _getmyspecialist = await _call('api/v1/meeting-request/my-specialist/', 'GET');
                const myspecialistdata = _getmyspecialist.data;
                result = myspecialistdata;
                setmyspecialist([...myspecialistdata]);
            }
            setrecord(result)
            setrenderevent(0);
            setloader(false);
        } catch (err) {
            setloader(false);
        }

    }


    const generatedUrlParams = () => {

        const { specialist_type = '', lat_lng = '' } = urlParameters;
        var str = '';

        if (specialist_type == '' && lat_lng != '') {
            str = '?lat_lng=' + lat_lng
            return str;
        }

        if (specialist_type != '' && lat_lng == '') {
            str = '?specialist_type=' + specialist_type
            return str;
        }

        if (specialist_type != '' && lat_lng != '') {
            str = '?specialist_type=' + specialist_type + '&lat_lng=' + lat_lng;
            return str;
        }


        return str;
    }

    const handleSetLocation = () => {
        var currentLocationState = !stateToggle;

        if (!currentLocationState) {
            seturlParameters({ ...urlParameters, lat_lng: `${user?.shape?.latitude},${user?.shape?.longitude}` })
        } else {
            seturlParameters({ ...urlParameters, lat_lng: '' })
        }

        setstateToggle(!stateToggle);
        setrenderevent(1)

    }

    const onSelection = async (item, painData) => {
        try {
            const _data = {
                pain_category: item.id,
                specialist: painData.id
            }

            const _createMeeting = await _call('api/v1/meeting-request/', 'POST', _data);

            alert.show('Request Sent Successfully', {
                type: 'success'
            });

        } catch (err) {
            alert.show('Something went wrong', {
                type: 'error'
            })
        }

    }

    const handleFilter = (item) => {
        setspecialistAreaType(item);

        if (item?.name == 'All') {
            seturlParameters({ ...urlParameters, specialist_type: '' })
            return;
        }

        seturlParameters({ ...urlParameters, specialist_type: item.id })

        setrenderevent(1)

    }

    useEffect(() => {
        if (renderevent == 1) {
            setrecord([]);
            setTimeout(() => handleData(), 1000);
        }
    }, [renderevent]);


    const handleChat = async (item) => {
        // return;
        try {


            const _getmainUserChatInfo = await _call(`api/v1/chat-token/?main_user=${user?.shape.id}`, 'GET');
            const finddata = _getmainUserChatInfo?.data.filter(x => x.chat_user == item.id);

            var _data = [];
            if (!isEmpty(finddata)) {
                // _data = {
                //     chatId: finddata[0].id,
                //     mainUser: {
                //         id: finddata[0].main_user_details.id,
                //         name: finddata[0].main_user_details.first_name,
                //         image: finddata[0].main_user_details.profile_picture,
                //     },
                //     chatUser: {
                //         id: finddata[0].chat_user_details.id,
                //         name: finddata[0].chat_user_details.first_name,
                //         image: finddata[0].chat_user_details.profile_picture,
                //     },
                //     user: {
                //         id: finddata[0].chat_user_details.id,
                //         name: finddata[0].chat_user_details.first_name,
                //         image: finddata[0].chat_user_details.profile_picture,
                //     }
                // }
                setTimeout(() => {
                    navigate('/inbox-customer')
                }, 1000)

            } else {
                var params = {
                    main_user: user?.shape?.id,
                    chat_user: item.id
                }
                const _createChat = await _call('api/v1/chat-token/', 'POST', params);
                const _result = _createChat.data;

                alert.show("Chat is initiated", {
                    type: "success"
                });

                setTimeout(() => {
                    navigate('/inbox-customer')
                }, 1000)

                // const _getmainUserChatInfo = await _call(`api/v1/chat-token/?main_user=${user.id}`, 'GET');

                // setchats(_getmainUserChatInfo.data);

                // _data = {
                //     chatId: _result.id,
                //     type: 'client',
                //     mainUser: {
                //         id: _result.main_user_details.id,
                //         name: `${_result.main_user_details.first_name} ${_result.main_user_details.last_name}`,
                //         image: _result.main_user_details.profile_picture,
                //     },
                //     chatUser: {
                //         id: _result.chat_user_details.id,
                //         name: `${_result.chat_user_details.first_name} ${_result.chat_user_details.last_name}`,
                //         image: _result.chat_user_details.profile_picture,
                //     },
                //     user: {
                //         id: _result.chat_user_details.id,
                //         name: `${_result.chat_user_details.first_name} ${_result.chat_user_details.last_name}`,
                //         image: _result.chat_user_details.profile_picture,
                //     }
                // }

            }


            // navigation.navigate('ChatScreen', {
            //     data: _data, user: {
            //         email: _data.mainUser.email,
            //         id: _data.mainUser.id,
            //         name: _data.mainUser.name,
            //         image: _data.mainUser.profile_picture,
            //     }
            // })

        } catch (err) {

        }
    }


    const onSearch = (val) => {


        // var __record = [...record]
        // __record = __record.filter(x => x.name?.toLowerCase()?.includes(val?.toLowerCase()))

        // console.log(val, __record)
        // if (currentTab == 0) {
        //     setdataset(__record)
        // } else {
        //     setmyspecialist(__record)
        // }


    }


    return <Layout loading={loader}>
        <div className="xs:p-4">
            <div className="grid grid-cols-12 gap-4 px-4 lg:px-0">
                <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto ">
                    <LayoutHeader title="Find Specialist" onBack={() => navigate(-1)} onSearching={e => setsearch(e)} />
                    <Tabs data={__specialistData} currentTab={currentTab} setCurrentTab={setCurrentTab} />
                    <FilterSpecialistElement onChange={handleFilter} locationState={stateToggle} onLocationClick={() => {
                        handleSetLocation()
                        setstateToggle(!stateToggle)
                    }} options={specialistArea?.shape?.map(item => {
                        return { ...item, name: item?.speciality_name }
                    })} />

                    {currentTab == 0 && <div className="grid lg:grid-cols-3 gap-4 my-5">
                        {dataset?.filter(x => (`${x?.first_name} ${x?.last_name}`)?.toString()?.toLowerCase()?.includes(search?.toLowerCase()))?.map(item => <SpecialistBox onSelection={e => onSelection(e, item)} painInfo={painInfo} data={item} />)}
                    </div>}

                    {currentTab == 1 && <div className="grid lg:grid-cols-3 gap-4 my-5">
                        {myspecialist?.filter(x => (`${x?.first_name} ${x?.last_name}`)?.toString()?.toLowerCase()?.includes(search?.toLowerCase()))?.map(item => <SpecialistBox onClick={() => handleChat(item)} mode={currentTab} onSelection={e => onSelection(e, item)} painInfo={painInfo} data={item} />)}
                    </div>}

                </div>
            </div>
        </div>

    </Layout>
}

export default FindSpecialist;