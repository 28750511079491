import React, { useState } from "react";
import { Logo } from "../assets/icons";
import { __customerHeader, __specialistHeader } from "../utils/_dataset";
import { Home, HomeInActive } from "../assets/svgs";
import { useNavigate } from "react-router-dom";

const Header = ({ activeId = 0 }) => {
    const role = localStorage.getItem('role');
    const navigate = useNavigate();

    const [dataset, setdataset] = useState(role == 'Specialist' ? __specialistHeader : __customerHeader)


    return <div className="bg-white w-full h-16">
        <div className="grid grid-cols-12 gap-4 overflow-hidden">
            <div className="lg:col-start-4 col-span-12 lg:col-span-6 py-5 overflow-auto">
                <div className="flex flex-row items-center justify-around">
                    {dataset?.map(item => <button onClick={() => navigate(item?.route)} className="flex flex-row items-center">
                        <img className="w-6 h-6 mr-2" src={item?.id == activeId ? item?.active : item?.icon} />
                        <h4>{item?.name}</h4>
                    </button>)}
                </div>
            </div>
        </div>
    </div>
}

export default Header;