import React, { useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import _, { isEmpty } from 'lodash'
import { IconCross, IconFileInvoice, IconTrash } from '@tabler/icons-react';
import { TramSharp } from '@mui/icons-material';
import { GridFilterAltIcon } from '@mui/x-data-grid';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    paddingTop: '50px',
    height: 200,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fff',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function getExtension(filename) {
    return filename.split('.').pop()
}


const FileUploader = (props) => {
    const { sendFiles, numberOfFiles, initFiles, customStyles = {}, textStyle = {}, noIcon = false, text = "Drop or choose file (png, jpg, pdf)" } = props;
    const [allfiles, setallfiles] = useState([]);

    useEffect(() => {
        if (!isEmpty(initFiles)) {
            setallfiles(initFiles)
        }
    }, [initFiles])

    const onDrop = React.useCallback(acceptedFiles => {
        setallfiles(acceptedFiles?.filter(x => ['jpg', 'jpeg', 'png'].includes(getExtension(x['name'].toLowerCase()))))
    }, []);
    const { getRootProps, getInputProps, isDragActive,
        isDragAccept,
        isDragReject } = useDropzone({ onDrop, maxFiles: numberOfFiles });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const files = allfiles.map(file => (
        <div className='bg-white border mt-2 p-3 flex flex-row items-center justify-between rounded'>
            <h4 style={{ flex: 1 }} className='text-slate-700'>{file.path}</h4>
            <IconTrash className='text-red-500' onClick={() => handleRemoveFile(file)} />
        </div>
    ));


    const handleRemoveFile = (_file) => {
        setallfiles(allfiles.filter(x => x != _file))
    }

    useEffect(() => {
        sendFiles(allfiles)
    }, [allfiles]);



    return (
        <section className="container p-0">
            <div {...getRootProps({ style })} className='mt-1 bg-white h-36 rounded-md items-center justify-center flex flex-col border' style={{ ...customStyles }}>
                <input {...getInputProps()} />
                {!noIcon && <IconFileInvoice className='text-slate-500' />}
                <h4 className="bold mt-2 text-slate-500" style={{ ...textStyle }}>{text}</h4>

            </div>
            <aside className='mt-2'>
                {files}
                {/* {!_.isEmpty(files) && } */}

            </aside>
        </section>
    );
}

export default FileUploader;