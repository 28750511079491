import moment from "moment";
import { googleAPIKey } from "./_constant";
import axios from 'axios'

export const getAddressInfo = (address) => new Promise(async (resolve, reject) => {
    try {
        const getzipcode = await axios.get('https://maps.googleapis.com/maps/api/geocode/json?key=' + googleAPIKey + '&address=' + address);
        const addresscomp = getzipcode.data?.results[0]?.address_components;
        var obj = {};
        addresscomp.map((item, index) => {
            if (item.types[0] === 'postal_code') {
                obj['zip_code'] = item.long_name;
            } else if (item.types[0] === 'administrative_area_level_1') {
                obj['state'] = item.long_name;
            } else if (item.types[0] === 'administrative_area_level_2') {
                obj['city'] = item.long_name;
            } else if (item.types[0] === 'country') {
                obj['country'] = item.long_name;
            }
        });

        resolve(obj);

    } catch (err) {
        reject(err);
    }
});

// export const requestPermission = () => {

// Notification.requestPermission().then((permission) => {
//     if (permission === "granted") {

//         console.log("Notification User Permission Granted.");
//         return getToken(fireMessaging, { vapidKey: `355581269242` })
//             .then((currentToken) => {
//                 if (currentToken) {
//                     return {
//                         token: currentToken
//                     }
//                 } else {
//                     return {
//                         message: 'Failed to generate the app registration token.'
//                     }
//                 }
//             })
//             .catch((err) => {
//                 return {
//                     message: 'An error occurred when requesting to receive the token.', err
//                 }
//             });
//     } else {
//         return {
//             message: "User Permission Denied."
//         }
//     }
// });

// }

// export const onMessageListener = () => { }
// new Promise((resolve) => {
//     onMessage(fireMessaging, (payload) => {
//         resolve(payload);
//     });
// });

export const handleDays = (date) => {
    return moment(date).fromNow() == 'a few seconds ago' ? 'Just Now' : moment(date).fromNow();
}

export const getCurrentUser = () => {
    let user = null;
    try {
        user = localStorage.getItem('token')

        if (user != null) {
            return {
                token: localStorage.getItem('token'),
                role: localStorage.getItem('role'),
            }
        } else {
            return null;
        }

    } catch (error) {
        user = null;
    }
    return user;
};
