import { combineEpics, Epic } from "redux-observable";
import { switchMap, map, startWith, catchError, filter, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { auth } from "../_urlroutes";
import { _call, _retaincall, _Unauthcall } from "../_service";
import SpecialistAreaSlices from "../slices/_specialistarea.slices";

const AuthUrl = auth.SPECIALIST_AREA;
const Action = SpecialistAreaSlices.action;


const GetEvent = (action$, state$) =>
    action$.pipe(filter(Action.LoadData.match),
        mergeMap(({ payload }) => from(_Unauthcall(AuthUrl, 'GET')).pipe(
            map(response => {
                return Action.LoadedData(response.data)
            }),
            startWith(Action.LoadData),
            catchError(e => {

                return of(Action.onFailed({ error: "Some problem occured" }))
            })
        )));

export default combineEpics(GetEvent);
