import React, { useState } from "react";
import { Logo } from "../assets/icons";
import { ColoredButton, CustomButton, PrimaryButton } from "../components/_button";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import { useAlert } from "react-alert";


const DownloadPDFLink = () => {
    const location = useLocation();
    const [loader, setloader] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const accessParam = queryParams.get('access');
    const idParam = queryParams.get('id');

    const alert = useAlert();

    const onDownloadFile = async () => {

        if (!idParam || !accessParam) {
            alert.show('Some Problem Occured', {
                type: 'error'
            })
        }

        const pdfURL = `https://api.mytmjapp.com/api/v1/diagnose/${idParam}/download-pdf/`;
        setloader(true);
        axios({
            url: pdfURL,
            method: 'POST',
            responseType: 'blob',
            headers: {
                Authorization: `Token ` + accessParam
            }
        }).then(response => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `Diagnosis Report.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setloader(false);
            alert.show('File Downloaded Successfully', {
                type: 'success'
            })
        }).catch(err => {
            alert.show('Some Problem Occured', {
                type: 'error'
            })
            setloader(false);
        });
    }

    return <div className="w-full h-[100vh] bg-image">
        <div className="grid lg:grid-cols-12 gap-4 px-4 lg:px-0">
            <div className="w-full xs:col-span-12 lg:col-span-3 lg:col-start-5 flex flex-col items-center mt-20">
                <Logo />
                <p className="w-[350px] text-center mt-3">Striving to Prevent Obesity, Diabetes and by
                    Promoting Healthy Lifestyles</p>
                <div className="mt-10 bg-gray-100 w-full flex flex-col items-center justify-center rounded p-20 py-10 shadow-md">
                    <p className="text-center">
                        {loader && "Your File is Downloading"}
                    </p>
                    <ColoredButton loading={loader} onClick={onDownloadFile} title="Download Again?" className="mt-5 bg-[#009DD8] text-white" />
                </div>
            </div>
        </div>
    </div>
}

export default DownloadPDFLink;