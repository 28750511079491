import React, { useState } from "react";
import { useSelector } from "react-redux";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import TabOutlinedIcon from '@mui/icons-material/TabOutlined';
import { PrimaryButton } from "./_button";
import AlertDialogSlide from "./_modal";
import Input from "./_input";

const SpecialistBox = ({ data, painInfo, onSelection, mode = 0, onClick }) => {
    const [onViewModal, setonViewModal] = useState(false);
    const specialistArea = useSelector(state => state.specialist_area);

    return <div className="bg-white rounded w-full min-h-[200px]">
        <div className="flex flex-row items-center p-4 border-b h-[80px]">
            <img src={data?.profile_picture?.split('?')[0]} className="w-10 h-10 rounded-full bg-slate-50" />
            <div className="ml-3  w-full">
                <h4 className="font-bold text-md">{data.first_name} {data.last_name}</h4>
                <p className="text-slate-500 text-xs">{data?.specialistprofile?.speciality_area_details?.map(item => item?.speciality_name)?.toString()}</p>
            </div>
        </div>
        <div className="border-b p-2 px-4 flex flex-col  w-full">
            <div className="flex flex-row items-center  w-full">
                <MapOutlinedIcon className="w-6 ml-1" />
                <div className="ml-5  w-full">
                    <h4 className="text-md">Address</h4>
                    <p className="text-slate-500 text-xs">{`${data?.specialistprofile?.state} ${data?.specialistprofile?.town}`}</p>
                </div>
            </div>
        </div>

        <div className="border-b p-2 px-4 flex flex-col  w-full">
            <div className="flex flex-row items-center  w-full">
                <AlternateEmailOutlinedIcon className="w-6 ml-2" />
                <div className="ml-5">
                    <h4 className="text-md">Contact</h4>
                    <p className="text-slate-500 text-xs">{data?.specialistprofile?.phone_number}</p>
                </div>
            </div>
        </div>

        <div className="border-b p-2 px-4 flex flex-col  w-full">
            <div className="flex flex-row items-center  w-full">
                <TabOutlinedIcon className="w-6 ml-2" />
                <div className="ml-5">
                    <h4 className="text-md">Website</h4>
                    <p className="text-slate-500 text-xs">{data?.specialistprofile?.website_url}</p>
                </div>
            </div>
        </div>
        <div className="border-b p-4 flex flex-col">
            <PrimaryButton onClick={() => {
                if (mode == 0) {
                    setonViewModal(!onViewModal)
                } else {
                    onClick()
                }
            }} title={mode == 0 ? "Submit a Request" : "Chat"} />
        </div>

        <AlertDialogSlide open={onViewModal} handleClose={() => setonViewModal(false)}
            children={<RenderPainInfo onSelect={e => {
                onSelection(e)
                setonViewModal(false)
            }} data={painInfo} />}
            title="Submit Request" />

    </div>
}

const RenderPainInfo = ({ data, onSelect }) => {
    const [selectedInfo, setselectedInfo] = useState([]);
    return <>
        <h4>Select your possible condition</h4>
        <Input type="select" options={data?.map(item => item?.category_name)} onChange={e => setselectedInfo(e.target.value)} />
        <div className="border-b my-10" />
        <PrimaryButton onClick={() => onSelect(data?.find(x => x.category_name == selectedInfo))} title="Submit a Request" />
    </>
}

export default SpecialistBox;